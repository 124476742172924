import { i18n } from 'i18n';
import BooleanField from 'modules/shared/fields/booleanField.ts';
import DateTimeField from 'modules/shared/fields/dateTimeField.ts';
import DateTimeRangeField from 'modules/shared/fields/dateTimeRangeField.ts';
import IdField from 'modules/shared/fields/idField.ts';
import RelationToManyField from 'modules/shared/fields/relationToManyField.ts';
import RelationToOneField from 'modules/shared/fields/relationToOneField.ts';
import StringField from 'modules/shared/fields/stringField.ts';

function label(name) {
  return i18n(`entities.customerAudience.fields.${name}`);
}

const fields = {
  id: new IdField('id', label('id')),
  slug: new StringField('slug', label('slug'), {}),
  name: new StringField('name', label('name'), {}),
  demographic: new StringField('demographic', label('demographic'), {}),
  income: new StringField('income', label('income'), {}),
  active: new BooleanField('active', label('active')),
  tags: new RelationToManyField('tags', label('tags'), {}),
  sector: new RelationToOneField('sector', label('sector'), {}),
  createdAt: new DateTimeField('createdAt', label('createdAt')),
  updatedAt: new DateTimeField('updatedAt', label('updatedAt')),
  createdAtRange: new DateTimeRangeField('createdAtRange', label('createdAtRange')),
};

export default {
  fields,
};
