import { BasicRef } from '.';

export interface VendorContactModel {
  id: string;
  createdAt: string;
  email: string;
  isPreferred: boolean;
  name: string;
  phone: string;
  updatedAt: string;
  vendorPartner: BasicRef<string>;
}

export class VendorContact {
  public static create(model: VendorContactModel): VendorContact {
    const { id, email, isPreferred, name, phone, vendorPartner } = model;
    return new VendorContact(id, email, isPreferred, name, phone, vendorPartner);
  }
  public constructor(
    public id: string,
    public email: string,
    public isPreferred: boolean,
    public name: string,
    public phone: string,
    public vendorPartner: BasicRef<string>,
  ) {}
}
