import { FC, forwardRef, PropsWithChildren } from 'react';

import { generateUtilityClasses, SxProps, Theme } from '@mui/material';
import clsx from 'clsx';
import { BaseAlert, BaseAlertClasses, BaseAlertProps } from '~/view/shared/OSAIAlert/BaseAlert';

const classes = {
  ...generateUtilityClasses('WarnAlert', ['root']),
};

const styles = {
  [`&.${classes.root}`]: {
    background: (theme) => theme.palette.warning.background,
    color: (theme) => theme.palette.warning.dark,
    [`& svg`]: {
      fill: (theme) => theme.palette.warning.dark,
    },
  },
  [`.${BaseAlertClasses.close}`]: {
    [`& svg`]: {
      fill: (theme) => theme.palette.warning.dark,
    },
  },
};

export interface WarningAlertProps extends BaseAlertProps {
  sx?: SxProps<Theme>;
  className?: string;
}

const WarningAlert: FC<WarningAlertProps> = forwardRef<HTMLDivElement, WarningAlertProps>(
  ({ className, sx, children, ...props }: PropsWithChildren<WarningAlertProps>, ref) => {
    return (
      <BaseAlert
        ref={ref}
        {...props}
        variant="outlined"
        severity="warning"
        className={clsx(className, classes.root)}
        sx={Array.isArray(sx) ? sx.concat([styles]) : { ...sx, ...styles }}
      >
        {children}
      </BaseAlert>
    );
  },
);

WarningAlert.displayName = 'WarningAlert';
export { WarningAlert };

export { classes as WarningAlertClasses };
