import { colors } from '@mui/material';
import { PaletteOptions } from '@mui/material/styles/createPalette';

const white = '#FFFFFF';

const palette: PaletteOptions = {
  alert: {
    success: {
      content: '#14B8A6',
    },
    info: {
      content: '#284963',
    },
    error: {
      content: '#541B1B',
    },
    warning: {
      content: '#FFB020',
    },
  },
  primary: {
    main: '#961DFF',
    light: '#F7EEFF',
    dark: '#951DFF',
    contrast: white,
  },
  secondary: {
    main: '#23262F',
    light: '#9E9E9E',
    dark: '#000005',
    contrast: white,
  },
  tertiary: {
    main: '#DFE2E2',
    dark: '#797C7F',
    light: '#F7F7F8',
  },
  error: {
    main: '#F44336',
    light: '#DA6868',
    dark: '#922E2E',
    contrast: white,
    background: '#FAECEC',
  },
  info: {
    main: '#4E6FFF',
    light: '#8B9DFF',
    dark: '#0045CB',
    contrast: white,
    background: '#EEF7FD',
  },
  warning: {
    main: '#F0932B',
    light: '#FFBF4C',
    dark: '#845B10',
    contrast: white,
    background: '#FEF6E7',
  },
  success: {
    main: '#00C684',
    light: '#43C6B7',
    dark: '#0E8074',
    contrast: white,
    background: '#E6F5F4',
  },
  neutral: {
    main: '#D1D5DB',
    dark: '#111827',
    contrast: white,
    background: '#E5E7EB',
  },
  text: {
    primary: '#2C2C2D',
    secondary: '#4C4C4D', // todo change, %
    disabled: '#9FA4AC',
    link: colors.blue[600], // todo change, ?
    hint: 'rgba(0, 0, 0, 0.38)', // todo change, ?
  },
  action: {
    active: '#6B7280',
    hover: '#F0F0F0', // todo change, %
    selected: '#E0E0E0', // todo change, %
    disabled: '#808080', // todo change, %
    disabledBackground: '#D0D0D0', // todo change, %
    focus: '#D0D0D0', // todo change, %
  },
  other: {
    divider: '#E6E8F0',
    outlineBorder: white,
    standardInputLine: white,
    backgroundOverlay: '#8F8F8F', // todo change, %
    ratingActive: '#FFB400',
    snackbarBackground: '#323232',
    lightPurpleBackground: '#FAF9FF', // todo change, '#FAEEFF', 'rgba(78, 111, 255, 0.04)'
  },
  button: { disabled: '#E7E8EA', hover: { primary: '#7717CC', secondary: '#F8ECFE' } },
  link: colors.blue[800],
  icon: colors.blueGrey[600],
  background: {
    default: '#F9FAFC',
    paper: white,
    whiteOpacity: '#FFFFFFAA',
    fontFamily: 'proxima-nova, helvetica, arial',
    hover: '#EEEEFF30',
    error: '#FAECEC',
  },
  divider: colors.grey[200],
  promo: {
    filled: 'linear-gradient(90deg, #951DFF 100%, #B900EB 100%)',
  },
};

export default palette;
