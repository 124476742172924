import authAxios from 'modules/shared/axios/authAxios';
import config from '~/config';

export default class QuickbookService {
  static async connectQuickbookAccount() {
    await window.location.replace(`${config.backendUrl}/quickbook/auth`);
  }

  static async confirmQuickbookToken(tokenId: string) {
    try {
      await authAxios.post<void>(
        `/quickbook/auth/confirm`,
        {
          tokenId,
        },
        {
          withCredentials: true,
        },
      );
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  static async disconnectQuickbookAccount() {
    try {
      await authAxios.get<void>(`/quickbook/auth/disconnect`, {
        withCredentials: true,
      });
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  static async checkQuickbookAccount() {
    const res = await authAxios.get<boolean>(`/quickbook/auth/account`, {
      withCredentials: true,
    });

    return res.data;
  }
}
