import { getListReducer } from 'modules/shared/reducers';
import { combineReducers } from 'redux';

import actions from './budgetRequirementActions';

const INITIAL_PAGE_SIZE = 100,
  list = getListReducer(actions.listActions, INITIAL_PAGE_SIZE),
  reducers = combineReducers({
    list,
  });

export default reducers;
