/* eslint-disable import/no-anonymous-default-export */
import _get from 'lodash/get';
import statuses from 'modules/shared/importer/importerStatuses';
import { createSelector } from 'reselect';

export default (rawPath) => {
  const selectRaw = (state) => _get(state, rawPath),
    selectUnassignedRows = createSelector([selectRaw], (raw) => raw.unassignedRows),
    selectHasUnassignedRows = createSelector([selectUnassignedRows], (unassignedRows) =>
      Boolean(unassignedRows.length),
    ),
    selectRows = createSelector([selectRaw], (raw) => raw.rows || []),
    selectHasRows = createSelector([selectRows], (rows) => Boolean(rows.length)),
    selectFieldMap = createSelector([selectRaw], (raw) => raw.fieldMap),
    selectHasFieldMap = createSelector([selectFieldMap], (fieldMap) => Boolean(fieldMap.length)),
    selectFieldMapHasDestinationFields = createSelector(
      [selectFieldMap, selectHasFieldMap],
      (fieldMap, hasFieldMap) => {
        if (!hasFieldMap) {
          return false;
        }
        const fieldMapWithDestinationFields = fieldMap.filter((fieldMapItem) =>
          Boolean(fieldMapItem.correspondingField),
        );
        return Boolean(fieldMapWithDestinationFields.length);
      },
    ),
    selectSubmittedFields = createSelector([selectRaw], (raw) => raw.submittedFields),
    selectHasSubmittedFields = createSelector([selectSubmittedFields], (submittedFields) =>
      Boolean(submittedFields.length),
    ),
    selectErrorMessage = createSelector([selectRaw], (raw) => raw.errorMessage),
    selectPendingRows = createSelector([selectRows], (rows) =>
      rows.filter((row) => row._status === statuses.PENDING),
    ),
    selectPendingRowsCount = createSelector(
      [selectPendingRows],
      (pendingRows) => pendingRows.length,
    ),
    selectRowsCount = createSelector([selectRows], (rows) => rows.length),
    selectImportedRowsCount = createSelector(
      [selectRows],
      (rows) => rows.filter((row) => row._status === statuses.IMPORTED).length,
    ),
    selectNonPendingRowsCount = createSelector(
      [selectRowsCount, selectPendingRowsCount],
      (allCount, pendingCount) => allCount - pendingCount,
    ),
    selectErrorRowsCount = createSelector(
      [selectRows],
      (rows) => rows.filter((row) => row._status === statuses.ERROR).length,
    ),
    selectImporting = createSelector([selectRaw], (raw) => raw.importing),
    selectLoading = createSelector([selectRaw], (raw) => raw.loading),
    selectCompleted = createSelector([selectRaw], (raw) => raw.completed),
    selectPercent = createSelector(
      [selectNonPendingRowsCount, selectRowsCount],
      (nonPendingRowsCount, rowsCount) => (nonPendingRowsCount * 100) / rowsCount,
    ),
    selectCount = createSelector([selectRaw], (raw) => raw.rows.length),
    selectSorter = createSelector([selectRaw], (raw) => raw.sorter || {}),
    selectLimit = createSelector([selectRaw], (raw) => {
      const { pagination } = raw;
      return pagination.pageSize;
    }),
    selectOffset = createSelector([selectRaw], (raw) => {
      const { pagination } = raw;

      if (!pagination || !pagination.pageSize) {
        return 0;
      }

      const current = pagination.current || 1;

      return (current - 1) * pagination.pageSize;
    }),
    selectPagination = createSelector([selectRaw, selectCount], (raw, count) => ({
      ...raw.pagination,
      total: count,
    })),
    selectCurrentPageRows = createSelector(
      [selectRows, selectOffset, selectLimit],
      (rows, offset, limit) => rows.slice(offset).slice(0, limit),
    ),
    selectors = {
      selectRaw,
      selectRows,

      selectUnassignedRows,
      selectHasUnassignedRows,

      selectFieldMap,
      selectHasFieldMap,
      selectFieldMapHasDestinationFields,

      selectSubmittedFields,
      selectHasSubmittedFields,

      selectRowsCount,
      selectHasRows,
      selectErrorMessage,
      selectPendingRows,
      selectPendingRowsCount,
      selectImportedRowsCount,
      selectErrorRowsCount,
      selectNonPendingRowsCount,
      selectImporting,
      selectCompleted,
      selectPercent,
      selectPagination,
      selectSorter,
      selectLoading,
      selectLimit,
      selectOffset,
      selectCurrentPageRows,
    };

  return selectors;
};
