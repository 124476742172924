import { i18n } from 'i18n';
import DecimalRangeField from 'modules/shared/fields//decimalRangeField.ts';
import DateTimeField from 'modules/shared/fields/dateTimeField.ts';
import DateTimeRangeField from 'modules/shared/fields/dateTimeRangeField.ts';
import DecimalField from 'modules/shared/fields/decimalField.ts';
import IdField from 'modules/shared/fields/idField.ts';
import RelationToManyField from 'modules/shared/fields/relationToManyField.ts';
import RelationToOneField from 'modules/shared/fields/relationToOneField.ts';

function label(name) {
  return i18n(`entities.transaction.fields.${name}`);
}

const fields = {
  id: new IdField('id', label('id')),
  amount: new DecimalField('amount', label('amount'), {}),
  when: new DateTimeField('when', label('when'), {}),
  content: new RelationToOneField('content', label('content'), {}),
  organization: new RelationToOneField('organization', label('organization'), {}),
  storefront: new RelationToOneField('storefront', label('storefront'), {}),
  device: new RelationToOneField('device', label('device'), {}),
  market: new RelationToManyField('market', label('market'), {}),
  category: new RelationToOneField('category', label('category'), {}),
  createdAt: new DateTimeField('createdAt', label('createdAt')),
  updatedAt: new DateTimeField('updatedAt', label('updatedAt')),
  createdAtRange: new DateTimeRangeField('createdAtRange', label('createdAtRange')),
  amountRange: new DecimalRangeField('amountRange', label('amountRange')),
  whenRange: new DateTimeRangeField('whenRange', label('whenRange')),
};

export default {
  fields,
};
