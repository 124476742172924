import Notifications from '@mui/icons-material/Notifications';
import Badge from '@mui/material/Badge';
import IconButton from '@mui/material/IconButton';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import withStyles from '@mui/styles/withStyles';
import notificationSound from 'assets/ringtone/short_notification.mp3';
import useAudio from 'components/useAudio';
import React, { useState } from 'react';
// Import { initiateSocket, disconnectSocket, getNotification, subscribeToNotification, readNotifications } from 'Socket';

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
  },
})((props) => (
  <Menu
    elevation={0}
    anchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

export default function Notification() {
  const room = 'notification',
    [anchorEl, setAnchorEl] = React.useState(null),
    [notifications, setNotifications] = useState([]),
    [notificationCount, setNotificationCount] = useState(0),
    [playing, toggle] = useAudio(notificationSound),
    /*
     *UseEffect(() => {
     *  if (room) initiateSocket(room);
     *  subscribeToNotification(localStorage.getItem('jwt'), room)
     *  getNotification((err, data) => {
     *    if(err) return;
     *    if ((!data && !Array.isArray(data)) || !data.length) return
     *    if (notifications.length !== data.length) {
     *      toggle()
     *    }
     *    setNotificationCount(data.length)
     *    setNotifications((prevState) => ([
     *      ...data,
     *      prevState,
     *    ]));
     *  });
     *  return () => {
     *    disconnectSocket();
     *  }
     *}, [room])
     */

    handleClick = (event) => {
      setAnchorEl(event.currentTarget);
      if (!notifications.length) {
        return;
      }

      /*
       *ReadNotifications({
       *    token: localStorage.getItem('jwt'),
       *    ids: notifications.map(message => message.userId)
       *  }, room)
       */
      setNotificationCount(0);
    },
    handleClose = () => {
      setAnchorEl(null);
    };

  return (
    <div>
      <MenuItem onClick={handleClick}>
        <IconButton aria-label="Notifications" size="large">
          <Badge badgeContent={notificationCount} color="secondary">
            <Notifications />
          </Badge>
        </IconButton>
      </MenuItem>
      <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {setNotificationCount &&
          notifications.map((notification) => (
            <ListItem
              style={{ minWidth: '150px', maxWidth: '300px' }}
              key={notification.id}
              divider
              role="listitem"
            >
              <ListItemText primary={notification.body} secondary={notification.title} />
            </ListItem>
          ))}
        {(!notifications || !notifications.length) && (
          <ListItem style={{ minWidth: '150px' }} divider>
            <ListItemText primary="No notifications" secondary="" />
          </ListItem>
        )}
      </StyledMenu>
    </div>
  );
}
