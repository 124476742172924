import model from 'modules/organization/organizationModel';

const { fields } = model;

export default [
  fields.id,
  fields.name,
  fields.url,
  fields.balance,
  fields.users,
  fields.owner,
  fields.organizationCategory,
  fields.organizationType,
  fields.status,
  fields.active,
  fields.contentOwned,
  fields.transactions,
  //  Fields.analyticsEvents,
  fields.organizationSector,
  fields.createdAt,
  fields.updatedAt,
];
