import { i18n } from 'i18n';
import ProfileService from 'modules/profile/profileService';
import Errors from 'modules/shared/error/errors';
import Message from '~/view/shared/message';

const prefix = 'PROFILE_NOTIFICATION',
  actions = {
    RESET: `${prefix}_RESET`,

    FIND_STARTED: `${prefix}_FIND_STARTED`,
    FIND_SUCCESS: `${prefix}_FIND_SUCCESS`,
    FIND_ERROR: `${prefix}_FIND_ERROR`,

    UPDATE_STARTED: `${prefix}_UPDATE_STARTED`,
    UPDATE_SUCCESS: `${prefix}_UPDATE_SUCCESS`,
    UPDATE_ERROR: `${prefix}_UPDATE_ERROR`,

    doFind: (id) => async (dispatch) => {
      try {
        dispatch({
          type: actions.FIND_STARTED,
        });

        const record = await ProfileService.find(id);

        dispatch({
          type: actions.FIND_SUCCESS,
          payload: record,
        });
      } catch (error) {
        Errors.handle(error);

        dispatch({
          type: actions.FIND_ERROR,
        });
      }
    },

    doUpdate: (id, data) => async (dispatch, getState) => {
      try {
        dispatch({
          type: actions.UPDATE_STARTED,
        });

        const record = await ProfileService.update(id, data);

        dispatch({
          type: actions.UPDATE_SUCCESS,
          payload: record,
        });

        Message.success(i18n('entities.notification.update.success'));
      } catch (error) {
        Errors.handle(error);

        dispatch({
          type: actions.UPDATE_ERROR,
        });
      }
    },
  };

export default actions;
