import React from 'react';
import { useLocation } from 'react-router';

const useQueryParams = <T>(): T => {
  const { search } = useLocation();
  const queryParams = {} as T;

  const querySearchParamsAPI = React.useMemo(() => new URLSearchParams(search), [search]);

  for (const [key, value] of querySearchParamsAPI.entries()) {
    queryParams[key] = value;
  }
  return queryParams;
};

export { useQueryParams };
