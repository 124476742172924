import yup from 'yup';
import { postcodeValidator } from 'postcode-validator';

import { i18n } from '../../i18n';
import {
  IdField,
  DateTimeField,
  BooleanField,
  StringField,
  RelationToManyField,
  ObjectArrayField,
  ObjectField,
  EnumeratorField,
} from '../../modules/shared/fields/index';
import country_data from '../../constants/contry_data';
import { phoneModelOptions, urlModelOptions, emailModelOptions } from '../../utils/validators';

const label = (name: string) => i18n(`entities.ooh.fields.${name}`);
const enumeratorLabel = (prefix: string, name: string) =>
  i18n(`entities.ooh.enumerators.${prefix}.${name}`);

const partnerContactFields = {
  name: new StringField('partnerContact.name', label('contactName'), {}),
  email: new StringField('partnerContact.email', label('contactEmail'), emailModelOptions),
  title: new StringField('partnerContact.title', label('contactTitle'), {}),
  phone: new StringField('partnerContact.phone', label('contactPhone'), phoneModelOptions),
};

const partnerAddressFields = {
  street: new StringField('partnerAddress.street', label('street'), {}),
  city: new StringField('partnerAddress.city', label('city'), {}),
  state: new StringField('partnerAddress.state', label('state'), {}),
  zip: new StringField('partnerAddress.zip', label('zip'), {
    // Tests zip within spec validator, once negative throws an error
    tester: function (zip: string) {
      let isValid = true;
      if (!zip) {
        return isValid;
      }

      const {
        parent: { country },
      } = this;
      if (!country) {
        return isValid;
      }
      isValid = postcodeValidator(zip, country);

      return isValid;
    },
    errorMessage: 'Zip is not correct for current country!',
  }),
  country: new EnumeratorField(
    'partnerAddress.country',
    label('country'),
    country_data.map((country) => ({
      id: country.ISO,
      label: country.Country,
    })),
    {
      dependentFieldSettings: [
        {
          zip: (zip: any, schema: yup.StringSchema) =>
            zip ? schema.required('Please, define country too!') : schema,
        },
      ],
    },
  ),
};

const partnerSocialFields = {
  facebook: new StringField('partnerSocial.facebook', label('facebook'), urlModelOptions),
  twitter: new StringField('partnerSocial.twitter', label('twitter'), urlModelOptions),
  linkedin: new StringField('partnerSocial.linkedin', label('linkedin'), {}),
  instagram: new StringField('partnerSocial.instagram', label('instagram'), urlModelOptions),
  youtube: new StringField('partnerSocial.youtube', label('youtube'), urlModelOptions),
  other: new StringField('partnerSocial.other', label('other'), urlModelOptions),
  businessPhone: new StringField(
    'partnerSocial.businessPhone',
    label('businessPhone'),
    phoneModelOptions,
  ),
};

const fields = {
  id: new IdField('id', label('id')),
  facebook: new StringField('facebook', label('facebook'), urlModelOptions),
  twitter: new StringField('twitter', label('twitter'), urlModelOptions),
  linkedin: new StringField('linkedin', label('linkedin'), {}),
  instagram: new StringField('instagram', label('instagram'), urlModelOptions),
  youtube: new StringField('youtube', label('youtube'), urlModelOptions),
  other: new StringField('other', label('other'), urlModelOptions),
  businessPhone: new StringField('businessPhone', label('businessPhone'), phoneModelOptions),
  street: new StringField('street', label('street'), {}),
  city: new StringField('city', label('city'), {}),
  state: new StringField('state', label('state'), {}),
  zip: new StringField('zip', label('zip'), {
    // Tests zip within spec validator, once negative throws an error
    tester: function (zip: string) {
      let isValid = true;
      if (!zip) {
        return isValid;
      }

      const {
        parent: { country },
      } = this;
      if (!country) {
        return isValid;
      }
      isValid = postcodeValidator(zip, country);

      return isValid;
    },
    errorMessage: 'Zip is not correct for current country!',
  }),
  country: new EnumeratorField(
    'country',
    label('country'),
    country_data.map((country) => ({
      id: country.ISO,
      label: country.Country,
    })),
    {
      dependentFieldSettings: [
        {
          zip: (zip: any, schema: yup.StringSchema) =>
            zip ? schema.required('Please, define country too!') : schema,
        },
      ],
    },
  ),
  contactName: new StringField('contactName', label('contactName'), {}),
  email: new StringField('email', label('contactEmail'), emailModelOptions),
  title: new StringField('title', label('contactTitle'), {}),
  phone: new StringField('phone', label('contactPhone'), phoneModelOptions),
  name: new StringField('name', label('name'), { required: true }),
  logo: new StringField('logo', label('logo'), {}),
  url: new StringField('url', label('url'), urlModelOptions),
  slug: new StringField('slug', label('slug'), {}),
  description: new StringField('description', label('description'), {}),
  storefront: new RelationToManyField('storefront', label('locations'), {}),
  partnerInventoryTypes: new RelationToManyField(
    'partnerInventoryTypes',
    label('inventoryType'),
    {},
  ),
  partnerServiceTypes: new RelationToManyField('partnerServiceTypes', label('serviceType'), {}),
  tier: new StringField('tier', 'Tier', {}),
  capabilities: new StringField('capabilities', 'Capabilities', {}),
  cons: new StringField('cons', 'Cons', {}),
  pros: new StringField('pros', 'pros', {}),
  socialId: new StringField('socialId', 'socialId', {}),
  staticImageAd: new BooleanField('staticImageAd', label('staticImageAd')),
  motionGraphicAd: new BooleanField('motionGraphicAd', label('motionGraphicAd')),
  audioAd: new BooleanField('audioAd', label('audioAd')),
  creativeRequirements: new StringField('creativeRequirements', label('creativeRequirements'), {}),
  benefits: new StringField('pros', label('benefits'), {}), // rename pros to benefits TODO
  createdAt: new DateTimeField('createdAt', label('createdAt')),
  updatedAt: new DateTimeField('updatedAt', label('updatedAt')),
  budgetRequirements: new ObjectArrayField('budgetRequirements', label('budgetRequirements'), {
    valueName: 'id',
    labelName: 'name',
  }),
  campaignTypes: new ObjectArrayField('campaignTypes', label('campaignTypes'), {
    valueName: 'id',
    labelName: 'name',
  }),
  inventoryTypes: new ObjectArrayField('inventoryTypes', label('inventoryTypes'), {
    valueName: 'id',
    labelName: 'name',
  }),
  serviceTypes: new ObjectArrayField('serviceTypes', label('serviceTypes'), {
    valueName: 'id',
    labelName: 'name',
  }),
  partnerRegionLocationDMAs: new ObjectArrayField(
    'partnerRegionLocationDMAs',
    label('partnerRegionLocationDMAs'),
    {
      valueName: 'id',
      labelName: 'dma',
    },
  ),
  tags: new ObjectArrayField('tags', label('tags'), {
    valueName: 'id',
    labelName: 'name',
  }),
  partnerRegionLocationStates: new ObjectArrayField(
    'partnerRegionLocationStates',
    label('partnerRegionLocationStates'),
    {
      valueName: 'id',
      labelName: 'state',
    },
  ),
  partnerContact: new ObjectField('partnerContact', label('partnerContact'), partnerContactFields),
  partnerAddress: new ObjectField('partnerAddress', label('partnerAddress'), partnerAddressFields),
  partnerSocial: new ObjectField('partnerSocial', label('partnerSocial'), partnerSocialFields),
  timeToLaunchDetails: new StringField('timeToLaunchDetails', label('timeToLaunchDetails'), {}),
  internalNotes: new StringField('internalNotes', label('internalNotes'), {}),
  timeToLaunch: new EnumeratorField(
    'timeToLaunch',
    label('timeToLaunch'),
    [
      {
        id: 'lessThanWeek',
        label: enumeratorLabel('timeToLaunch', 'lessThanWeek'),
      },
      {
        id: 'moreThanWeek',
        label: enumeratorLabel('timeToLaunch', 'moreThanWeek'),
      },
    ],
    {},
  ),
  cc: new StringField('cc', label('cc'), {
    // Tests whole comma-separated string within emails to check if all (mandatory) of them are correct, once negative throws an error
    tester: (cc: string) => {
      let isValid = true;
      if (!cc) return isValid;

      const emailsStringRegex =
        // eslint-disable-next-line no-useless-escape
        // I don't know if this is a legit complaint or not so I'm just disableing the warning
        /(?:[a-zA-Z0-9.!#$%&*+\/=?^_{|}~-]+@[a-zA-Z0-9]?(?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?))/g;

      const validEmailsArr = cc.match(emailsStringRegex);

      if (!validEmailsArr || !validEmailsArr.length) {
        isValid = false;
        return isValid;
      }

      const allCCEmailsArr = cc.split(',').map((email: string) => email.trim());

      const invalidEmailsArr = allCCEmailsArr.filter((email) => !validEmailsArr.includes(email));

      if (invalidEmailsArr.length) {
        isValid = false;
        return isValid;
      }

      return isValid;
    },
    errorMessage: 'Provide correct comma-separated emails!',
  }),
};

export default {
  fields,
  partnerContactFields,
  partnerAddressFields,
  partnerSocialFields,
  label,
  enumeratorLabel,
};
