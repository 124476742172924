import { i18n } from 'i18n';
import fields from 'modules/market/importer/marketImporterFields';
import selectors from 'modules/market/importer/marketImporterSelectors';
import MarketService from 'modules/market/marketService';
import importerActions from 'modules/shared/importer/importerActions';

export default importerActions(
  'MARKET_IMPORTER',
  selectors,
  MarketService.import,
  fields,
  i18n('entities.market.importer.fileName'),
);
