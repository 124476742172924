import { i18n } from 'i18n';
import getStore, { getHistory } from 'modules/store';
import Message from '~/view/shared/message';
import FlightConflictErrorHandler from './flightConflictErrorHandler';
import authActions from 'modules/auth/authActions';

const DEFAULT_ERROR_MESSAGE = i18n('errors.defaultErrorMessage');
const DEFAULT_ERROR_CODE = 'UnknownErrorCode';

export function selectErrorMessage(error, defaultErrorMessage) {
  const errorMessage =
    error?.response?.data?.errorMessage || error?.response?.data?.message || error?.response?.data;

  return errorMessage || defaultErrorMessage || DEFAULT_ERROR_MESSAGE;
}

export function selectErrorCode(error) {
  if (error && error.response && error.response.data.errorCode) {
    return error.response.data.errorCode;
  }

  return DEFAULT_ERROR_CODE;
}

export function selectErrorFields(error) {
  if (error && error.response && error.response.data.errorFields) {
    return error.response.data.errorFields;
  }

  return null;
}

export function getErrorFieldMessage(errorFields = [], fieldName) {
  if (!fieldName || !errorFields) {
    return '';
  }

  const fieldError = errorFields.find((field) => field.field === fieldName);

  return fieldError ? fieldError.message || DEFAULT_ERROR_MESSAGE : '';
}

export function selectErrorStatus(error) {
  if (error && error.response && error.response.status) {
    return error.response.status;
  }

  return 500;
}

export default class Errors {
  static handle(error) {
    if (process.env.NODE_ENV !== 'test') {
      console.error(selectErrorMessage(error));
      console.error(error);
    }

    // Login session ended redirect to login page
    if (selectErrorStatus(error) === 440) {
      getStore().dispatch(authActions.doSignout());
      window.location =
        window.location.origin +
        '/auth/signin?error=' +
        encodeURIComponent(selectErrorMessage(error));
      return;
    }

    if (selectErrorStatus(error) === 409) {
      FlightConflictErrorHandler.showConflictToast(error);
      return;
    }

    if (selectErrorStatus(error) === 403) {
      getHistory().push('/403');
      return;
    }

    if (selectErrorStatus(error) === 400) {
      const errorsList = selectErrorFields(error);
      if (errorsList) {
        for (let i = 0; i < errorsList.length; i++) {
          Message.error(errorsList[i].message);
        }
        return;
      }

      Message.error(selectErrorMessage(error));
      return;
    }

    getHistory().push('/500');
  }

  static getErrorData(error, defaultOptions) {
    const { defaultErrorMessage, defaultErrorCode } = defaultOptions || {};
    const status = selectErrorStatus(error);
    const errorMessage = selectErrorMessage(error, defaultErrorMessage);
    const errorCode = selectErrorCode(error, defaultErrorCode);
    const errorFields = selectErrorFields(error);

    return { status, errorMessage, errorCode, errorFields };
  }

  static errorCode(error) {
    return selectErrorCode(error);
  }

  static selectMessage(error) {
    return selectErrorMessage(error);
  }

  static showMessage(error) {
    Message.error(selectErrorMessage(error));
  }
}
