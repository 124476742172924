import { createSelector } from 'reselect';

const selectRaw = (state) => state.auth;
const selectAuthenticationUser = createSelector([selectRaw], (auth) => auth.authenticationUser);
const selectCurrentUser = createSelector([selectRaw], (auth) =>
  auth && auth?.currentUser ? auth?.currentUser : null,
);
const selectCurrentUserEmail = createSelector([selectCurrentUser], (currentUser) =>
  currentUser ? currentUser.email : null,
);
const selectCurrentUserFullName = createSelector([selectCurrentUser], (currentUser) =>
  currentUser ? currentUser.fullName : '',
);
const selectSignedIn = createSelector(
  [selectCurrentUser],
  (currentUser) => Boolean(currentUser) && Boolean(currentUser.id),
);
const selectAccountType = createSelector([selectCurrentUser], (currentUser) =>
  currentUser ? currentUser.accountType : '',
);
const selectRoles = createSelector([selectCurrentUser], (currentUser) =>
  currentUser ? currentUser.roles || [] : [],
);
const selectEmptyPermissions = createSelector([selectRoles], (roles) => !roles || !roles.length);
const selectLoading = createSelector([selectRaw], (auth) => Boolean(auth.loading));
const selectLoadingInit = createSelector([selectRaw], (auth) => Boolean(auth.loadingInit));
const selectLoadingEmailConfirmation = createSelector([selectRaw], (auth) =>
  Boolean(auth.loadingEmailConfirmation),
);
const selectLoadingPasswordResetEmail = createSelector([selectRaw], (auth) =>
  Boolean(auth.loadingPasswordResetEmail),
);
const selectLoadingPasswordReset = createSelector([selectRaw], (auth) =>
  Boolean(auth.loadingPasswordReset),
);
const selectLoadingVerifyEmail = createSelector([selectRaw], (auth) =>
  Boolean(auth.loadingVerifyEmail),
);
const selectLoadingUpdateProfile = createSelector([selectRaw], (auth) =>
  Boolean(auth.loadingUpdateProfile),
);
const selectErrorMessage = createSelector([selectRaw], (auth) => auth.errorMessage);
const selectCurrentUserNameOrEmailPrefix = createSelector(
  [selectCurrentUser, selectCurrentUserFullName],
  (currentUser, fullName) => {
    if (!currentUser) {
      return '';
    }

    if (fullName?.length > 0) {
      return fullName;
    }

    return currentUser.email.split('@')[0];
  },
);
const selectCurrentUserAvatar = createSelector([selectCurrentUser], (currentUser) => {
  if (
    !currentUser ||
    !currentUser.avatars ||
    !currentUser.avatars.length ||
    !currentUser.avatars[0].publicUrl
  ) {
    return null;
  }

  return currentUser.avatars[0].publicUrl;
});
const selectCurrentUserId = createSelector([selectCurrentUser], (currentUser) => currentUser.id);
const selectors = {
  selectLoadingPasswordResetEmail,
  selectLoadingEmailConfirmation,
  selectLoadingInit,
  selectLoadingUpdateProfile,
  selectLoading,
  selectEmptyPermissions,
  selectRoles,
  selectSignedIn,
  selectAccountType,
  selectCurrentUserFullName,
  selectCurrentUserEmail,
  selectCurrentUser,
  selectAuthenticationUser,
  selectErrorMessage,
  selectRaw,
  selectCurrentUserNameOrEmailPrefix,
  selectCurrentUserAvatar,
  selectCurrentUserId,
  selectLoadingPasswordReset,
  selectLoadingVerifyEmail,
};

export default selectors;
