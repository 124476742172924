import { parseDateFromString } from '~/utils/dateUtil';
import { BasicRef, BasicRefWithSlug } from '.';

export interface StorefrontModel {
  id: string;
  importHash: string;
  createdAt: string;
  updatedAt: string;
  deletedAt: string;
  createdById: string;
  updatedById: string;
  geopathId: number;
  unitNumber: string;
  name: string;
  latitude: number;
  longitude: number;
  physicalAddress: string;
  city: string;
  state: string;
  zip: string;
  timezone: string;
  timezoneName: string;
  notes: string;
  description: string;
  facing: string;
  position: string;
  weeklyAdultImpressionsCount: string;
  uniqueAdultAdReachedCount: string;
  averageAdultFrequencyReach: string;
  grp: string;
  spotLength: string;
  loopLength: string;
  sov: string;
  panelSize: string;
  vinylSize: string;
  shippingAddress: string;
  illuminated: string;
  staticImageAd: string;
  motionGraphicAd: string;
  audioAd: string;
  lengthLimit: string;
  fileSizeLimitMb: string;
  categoryRestrictions: string;
  fourWeekRateNegotiatedCost: string;
  fourWeekRateCardCost: string;
  advertisersCount: string;
  productionCost: string;
  installCost: string;
  country: string;
  inventoryTypeId: string;
  dimensions: string;
  mediaFiles: unknown[];
  dmaId: string;
  market: BasicRef<string>;
  inventoryTypes: BasicRef<string>[];
  partners: BasicRefWithSlug[];
  syncedAt: string;
  h3p9: string;
  h3p5: string;
  h3p3: string;
  nextAvailableDate: string;
}

export class Storefront {
  public static create(model: StorefrontModel): Storefront {
    const {
      id,
      city,
      createdAt: createdAtString,
      updatedAt: updatedAtString,
      deletedAt: deletedAtString,
      unitNumber,
      geopathId,
      fourWeekRateCardCost,
      fourWeekRateNegotiatedCost,
      installCost,
      inventoryTypes,
      market,
      panelSize,
      partners,
      productionCost,
      state,
      weeklyAdultImpressionsCount,
    } = model;

    return new Storefront(
      id,
      city,
      createdAtString,
      deletedAtString,
      fourWeekRateCardCost,
      fourWeekRateNegotiatedCost,
      geopathId ? +geopathId : -1,
      installCost,
      inventoryTypes,
      market,
      panelSize,
      partners,
      productionCost,
      state,
      updatedAtString,
      unitNumber,
      weeklyAdultImpressionsCount,
    );
  }

  public constructor(
    public id: string,
    public city: string,
    public createdAtString: string,
    public deletedAtString: string,
    public fourWeekRateCardCost: string,
    public fourWeekRateNegotiatedCost: string,
    public geopathId: number,
    public installCost: string,
    public inventoryTypes: BasicRef<string>[],
    public market: BasicRef<string>,
    public panelSize: string,
    public partners: BasicRefWithSlug[],
    public productionCost: string,
    public state: string,
    public updatedAtString: string,
    public unitNumber: string,
    public weeklyAdultImpressionsCount: string,
  ) {}

  public get createdAt(): Date {
    return parseDateFromString(this.createdAtString);
  }
  public get updatedAt(): Date {
    return parseDateFromString(this.updatedAtString);
  }
  public get deletedAt(): Date {
    return parseDateFromString(this.deletedAtString);
  }
}
