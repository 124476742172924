import { FormActionTypes } from '~/types/modules/campaign/form/actions.d';
import { CampaignFormReducerState } from '~/types/modules/campaign/form/reducers.d';
import { ViewActionTypes } from '~/types/modules/campaign/view/actions.d';

const initialState: CampaignFormReducerState = {
  record: {
    id: '',
    endingWithinDays: -1,
    name: '',
    status: null,
    onHoldDays: null,
    ownerId: '',
    startDate: '',
    endDate: '',
    expirationDate: null,
    deletedAt: null,
    createdById: '',
    updatedById: '',
    createdAt: '',
    updatedAt: '',
    company: {
      id: '',
      name: '',
    },
    partner: {
      id: '',
      name: '',
    },
    flights: [],
  },
  loading: true,
  error: '',
};

const reducer = (state = initialState, { type, payload }): CampaignFormReducerState => {
  switch (type) {
    case FormActionTypes.INIT:
      return {
        ...state,
        loading: false,
      };
    case FormActionTypes.CREATE_START:
      return {
        ...state,
        loading: true,
      };
    case FormActionTypes.CREATE_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case FormActionTypes.CREATE_ERROR:
      return {
        ...state,
        loading: false,
        error: payload,
      };
    case FormActionTypes.UPDATE_START:
      return {
        ...state,
        loading: true,
      };
    case FormActionTypes.UPDATE_SUCCESS:
      return {
        ...state,
        record: payload,
        loading: false,
      };
    case FormActionTypes.UPDATE_ERROR:
      return {
        ...state,
        error: payload,
        loading: false,
      };
    case ViewActionTypes.FIND_START:
      return {
        ...state,
      };
    case ViewActionTypes.FIND_SUCCESS:
      return {
        ...state,
        record: payload,
        loading: false,
      };
    case ViewActionTypes.FIND_ERROR:
      return {
        ...state,
        error: payload,
        loading: false,
      };
    case FormActionTypes.RESET_ERROR_STATE:
      return {
        ...state,
        error: '',
      };
    default:
      return {
        ...state,
      };
  }
};

export default reducer;
