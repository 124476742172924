import { add } from 'date-fns';

export class DateRangeIterator {
  constructor(startDate, endDate, step = 1) {
    this.startDate = startDate;
    this.endDate = endDate;
    this.step = step;
  }

  [Symbol.iterator]() {
    let nextIndex = this.startDate;
    let endDate = this.endDate;
    let step = this.step;
    let iterationCount = 0;

    return {
      next() {
        let result;
        if (nextIndex <= endDate) {
          result = { value: nextIndex, done: false };
          nextIndex = add(nextIndex, { days: step });
          iterationCount++;
          return result;
        }
        return { value: iterationCount, done: true };
      },
    };
  }
}
