import * as yup from 'yup';

import GenericField from '../../../modules/shared/fields/genericField';

export default class DecimalField extends GenericField {
  required: boolean;

  min?: number;

  max?: number;

  scale?: number;

  constructor(
    name: string,
    label: string,
    { required = false, min = undefined, max = undefined, scale = undefined } = {},
  ) {
    super(name, label);

    this.required = required;
    this.min = min;
    this.max = max;
    this.scale = scale;
  }

  forView(value: any) {
    if (!value) {
      return value;
    }

    if (this.scale === undefined || this.scale === null) {
      return value;
    }

    return Number(value).toFixed(this.scale);
  }

  forFormInitialValue(value: any) {
    return value;
  }

  forInput: undefined;

  forFilter() {
    return yup.number().label(this.label);
  }

  forForm() {
    let yupChain = yup.number().nullable(true).label(this.label);

    if (this.required) {
      yupChain = yupChain.required();
    }

    if (this.min || this.min === 0) {
      yupChain = yupChain.min(this.min);
    }

    if (this.max) {
      yupChain = yupChain.max(this.max);
    }

    return yupChain;
  }

  forExport() {
    return yup
      .mixed()
      .label(this.label)
      .transform((value) => this.forView(value));
  }

  forImport() {
    let yupChain = yup.number().nullable(true).label(this.label);

    if (this.required) {
      yupChain = yupChain.required();
    }

    if (this.min || this.min === 0) {
      yupChain = yupChain.min(this.min);
    }

    if (this.max) {
      yupChain = yupChain.max(this.max);
    }

    return yupChain;
  }

  literalMapToValue: undefined;
}
