import { getListActions } from 'modules/shared/actions';

import selectors from './locationStateSelectors';
import service from './locationStateService';

export const listActions = getListActions({
  service,
  selectors: selectors.list,
  prefix: 'LOCATION_STATE_LIST',
});

export default { listActions };
