import { connectRouter } from 'connected-react-router';
import { combineReducers } from 'redux';

import auditLog from '~/modules/auditLog/auditLogReducers';
import auth from '~/modules/auth/authReducers';
import budgetRequirement from '~/modules/budgetRequirement/budgetRequirementReducers';
import callToAction from '~/modules/callToAction/callToActionReducers';
import campaign from '~/modules/campaign/reducers';
import campaignType from '~/modules/campaignType/campaignTypeReducers';
import category from '~/modules/category/categoryReducers';
import channel from '~/modules/channel/channelReducers';
import companyUser from '~/modules/companyUser/companyUserReducers';
import content from '~/modules/content/contentReducers';
import customerAudience from '~/modules/customerAudience/customerAudienceReducers';
import customerProfile from '~/modules/customerProfile/customerProfileReducers';
import cart from '~/modules/cart/reducers';
import device from '~/modules/device/deviceReducers';
import deviceCapability from '~/modules/deviceCapability/deviceCapabilityReducers';
import deviceNetwork from '~/modules/deviceNetwork/deviceNetworkReducers';
import deviceProfile from '~/modules/deviceProfile/deviceProfileReducers';
import iam from '~/modules/iam/iamReducers';
import inventoryType from '~/modules/inventoryType/inventoryTypeReducers';
import layout from '~/modules/layout/layoutReducers';
import locationDMA from '~/modules/locationDMA/locationDMAReducers';
import locationGroup from '~/modules/locationGroup/locationGroupReducers';
import locationState from '~/modules/locationState/locationStateReducers';
import market from '~/modules/market/marketReducers';
import directory from '~/modules/ooh/oohReducers';
import organization from '~/modules/organization/organizationReducers';
import organizationType from '~/modules/organizationType/organizationTypeReducers';
import permission from '~/modules/permission/permissionReducers';
import playlist from '~/modules/playlist/playlistReducers';
import priceRequest from '~/modules/priceRequest/priceRequestReducers';
import profile from '~/modules/profile/profileReducers';
import sector from '~/modules/sector/sectorReducers';
import serviceType from '~/modules/serviceType/serviceTypeReducers';
import settings from '~/modules/settings/settingsReducers';
import status from '~/modules/status/statusReducers';
import storefront from '~/modules/storefront/storefrontReducers';
import storefrontAvailability from '~/modules/storefrontAvailability/storefrontAvailabilityReducers';
import tag from '~/modules/tag/tagReducers';
import transaction from '~/modules/transaction/transactionReducers';
import quickbook from '~/modules/quickbook/reducers.ts';
import rfpCart from '~/modules/cart/rfp/reducers';

export default (history) =>
  combineReducers({
    router: connectRouter(history),
    layout,
    auth,
    auditLog,
    playlist,
    budgetRequirement,
    callToAction,
    campaign,
    campaignType,
    category,
    channel,
    companyUser,
    content,
    customerAudience,
    customerProfile,
    cart,
    device,
    deviceCapability,
    deviceNetwork,
    deviceProfile,
    directory,
    iam,
    inventoryType,
    locationDMA,
    locationGroup,
    locationState,
    market,
    organization,
    organizationType,
    permission,
    priceRequest,
    profile,
    sector,
    serviceType,
    settings,
    status,
    storefront,
    storefrontAvailability,
    tag,
    transaction,
    quickbook,
    rfpCart,
  });
