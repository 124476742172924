import { i18n } from 'i18n';
import fields from 'modules/organization/importer/organizationImporterFields';
import selectors from 'modules/organization/importer/organizationImporterSelectors';
import OrganizationService from 'modules/organization/organizationService';
import importerActions from 'modules/shared/importer/importerActions';

export default importerActions(
  'ORGANIZATION_IMPORTER',
  selectors,
  OrganizationService.import,
  fields,
  i18n('entities.organization.importer.fileName'),
);
