import { getListActions } from 'modules/shared/actions';

import selectors from './inventoryTypeSelectors';
import service from './inventoryTypeService';

export const listActions = getListActions({
  service,
  selectors: selectors.list,
  prefix: 'INVENTORY_TYPE_LIST',
});

export default { listActions };
