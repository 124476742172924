import IamService from 'modules/iam/iamService';
import Errors from 'modules/shared/error/errors';

const prefix = 'IAM_INVITE',
  actions = {
    SEND_STARTED: `${prefix}_FIND_STARTED`,
    SEND_SUCCESS: `${prefix}_FIND_SUCCESS`,
    SEND_ERROR: `${prefix}_FIND_ERROR`,

    sendInvite: (data) => async (dispatch) => {
      try {
        dispatch({
          type: actions.SEND_STARTED,
        });

        const response = await IamService.sendInvite(data);

        dispatch({
          type: actions.SEND_SUCCESS,
          payload: response,
        });
      } catch (error) {
        Errors.handle(error);

        dispatch({
          type: actions.SEND_ERROR,
        });
      }
    },
  };

export default actions;
