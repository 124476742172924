import * as yup from 'yup';

import { ACCOUNT_TYPE, USER_FIELDS } from '../../constants/data';
import { i18n } from '../../i18n';
import BooleanField from '../../modules/shared/fields/booleanField';
import DateTimeField from '../../modules/shared/fields/dateTimeField';
import DateTimeRangeField from '../../modules/shared/fields/dateTimeRangeField';
import EnumeratorField from '../../modules/shared/fields/enumeratorField';
import IdField from '../../modules/shared/fields/idField';
import ImagesField from '../../modules/shared/fields/imagesField';
import StringArrayField from '../../modules/shared/fields/stringArrayField';
import StringField from '../../modules/shared/fields/stringField';
import Roles from '../../security/roles';
import { emailRegExp } from '../../utils/validators';

class RolesField extends StringArrayField {
  values: any;

  constructor(name: string, label: string, config: object = {}) {
    super(name, label, config);

    this.values = Roles.selectOptions;
  }

  forExport() {
    return yup
      .mixed()
      .label(this.label)
      .transform((values) =>
        values ? values.map((value: any) => Roles.labelOf(value)).join(' ') : null,
      );
  }
}

class EmailsField extends StringArrayField {
  forForm() {
    let yupChain = yup
      .array()
      .label(this.label)
      .of(
        yup
          .string()
          .email(i18n('user.validations.email'))
          .label(i18n('user.fields.email'))
          .max(255)
          .required(),
      );

    if (this.required) {
      yupChain = yupChain.required();
    }

    return yupChain;
  }
}

export function label(name: string) {
  return i18n(`user.fields.${name}`);
}

const fields = {
  [USER_FIELDS.id]: new IdField('id', label('id')),
  [USER_FIELDS.companyId]: new IdField('companyId', label('companyId')),
  authenticationUid: new StringField('authenticationUid', label('authenticationUid')),
  firstName: new StringField('firstName', label('firstName'), {
    max: 80,
    required: true,
  }),
  lastName: new StringField('lastName', label('lastName'), {
    max: 175,
    required: true,
  }),
  fullName: new StringField('fullName', label('fullName')),
  [USER_FIELDS.name]: new StringField(USER_FIELDS.name, label('name'), { required: true }),
  [USER_FIELDS.phone]: new StringField(USER_FIELDS.phone, 'Phone'),
  [USER_FIELDS.url]: new StringField(USER_FIELDS.url, 'Url', { type: 'url' }),
  [USER_FIELDS.state]: new StringField(USER_FIELDS.state, 'State'),
  [USER_FIELDS.city]: new StringField(USER_FIELDS.city, 'City'),
  [USER_FIELDS.street]: new StringField(USER_FIELDS.street, 'Street'),
  [USER_FIELDS.zip]: new StringField(USER_FIELDS.zip, 'ZIP'),
  [USER_FIELDS.active]: new BooleanField(USER_FIELDS.active, 'Active'),
  [USER_FIELDS.email]: new StringField(USER_FIELDS.email, label('email'), {
    required: true,
    max: 255,
    matches: emailRegExp,
    errorMessage: 'Provide a valid email, please!',
  }),
  [USER_FIELDS.password]: new StringField(USER_FIELDS.password, label('password'), {
    required: true,
  }),
  currentPassword: new StringField('currentPassword', label('currentPassword'), {
    required: true,
  }),
  newPassword: new StringField('newPassword', label('newPassword'), {
    required: true,
  }),
  confirmPassword: new StringField('confirmPassword', label('confirmPassword'), {
    required: true,
  }),
  companyName: new StringField('companyName', 'Company Name'),
  companyAddress: new StringField('companyAddress', 'Company Address'),
  locationName: new StringField('locationName', 'Location Name'),
  locationAddress: new StringField('locationAddress', 'Location Address'),
  [USER_FIELDS.tosAgreed]: new StringField(USER_FIELDS.tosAgreed, 'Terms of Service Agreed'),
  invited: new StringField('invited', 'Invited'),
  [USER_FIELDS.groupName]: new StringField(USER_FIELDS.groupName, label('groupName'), {
    required: true,
  }),
  role: new EnumeratorField('role', label('role'), []),
  rememberMe: new BooleanField('rememberMe', label('rememberMe')),
  disabledAsStatus: new BooleanField('disabled', label('status'), {
    noLabel: i18n('user.enabled'),
    yesLabel: i18n('user.disabled'),
  }),
  disabled: new BooleanField('disabled', label('disabled'), {
    noLabel: i18n('user.enabled'),
    yesLabel: i18n('user.disabled'),
  }),
  [USER_FIELDS.phoneNumber]: new StringField(USER_FIELDS.phoneNumber, label('phoneNumber'), {
    matches: /^[0-9]/,
    max: 24,
    prefix: '+',
    type: 'tel',
  }),
  [USER_FIELDS.avatarsIam]: new ImagesField(
    USER_FIELDS.avatarsIam,
    label('avatars'),
    'user/avatars/iam',
    { max: 1 },
  ),
  [USER_FIELDS.partnerName]: new StringField('partnerName', 'Partner Page'),
  avatarsProfile: new ImagesField(
    'avatars',
    label('avatars'),
    (id) => `user/avatars/profile/${id}`,
    { max: 1 },
  ),
  emails: new EmailsField('emails', label('emails'), {
    required: true,
  }),
  [USER_FIELDS.rolesRequired]: new RolesField('roles', label('roles'), {
    required: true,
  }),
  roles: new RolesField('roles', label('roles')),
  createdAt: new DateTimeField('createdAt', label('createdAt')),
  lastActivity: new DateTimeField('lastActivity', label('lastActivity')),
  updatedAt: new DateTimeField('updatedAt', label('updatedAt')),
  createdAtRange: new DateTimeRangeField('createdAtRange', label('createdAtRange')),
  status: new EnumeratorField('status', label('status'), [
    {
      id: 'enabled',
      label: i18n('user.enabled'),
    },
    {
      id: 'disabled',
      label: i18n('user.disabled'),
    },
  ]),
  [USER_FIELDS.accountType]: new EnumeratorField(
    USER_FIELDS.accountType,
    label('accountType'),
    [
      {
        id: ACCOUNT_TYPE.HOST, // TODO remove
        label: i18n('user.fields.host'),
      },
      {
        id: ACCOUNT_TYPE.ADMIN,
        label: i18n('user.fields.admin'),
      },
      {
        id: ACCOUNT_TYPE.ADVERTISER,
        label: i18n('user.fields.advertiser'),
      },
      {
        id: ACCOUNT_TYPE.PARTNER,
        label: i18n('user.fields.partner'),
      },
      {
        id: ACCOUNT_TYPE.ANONYMOUS,
        label: i18n('user.fields.anonymous'),
      },
    ],
    {
      required: true,
    },
  ),
};

export default {
  fields,
};
