import * as yup from 'yup';

import GenericField from '../../../modules/shared/fields/genericField';

export default class IntegerRangeField extends GenericField {
  forFilter() {
    return yup.mixed().label(this.label);
  }

  forFormInitialValue(value: any) {
    return value || [];
  }

  forExport: undefined;

  forForm: undefined;

  forImport: undefined;

  forInput: undefined;

  forView: undefined;

  literalMapToValue: undefined;
}
