import selectors from 'modules/storefront/list/storefrontListSelectors';
import service from 'modules/storefront/storefrontService';

import { getListActions } from '../../../modules/shared/actions';

export default getListActions({
  service,
  selectors,
  prefix: 'STOREFRONT_LIST',
});
