import XLSX from 'xlsx';

import { i18n } from '../../../i18n';
import { Excel } from '../excel/excel';
import { GenericField } from '../fields';
import ImporterSchema from './importerSchema';

interface IJsonDataElement {
  [key: string]: string | undefined;
}

type JsonDataType = IJsonDataElement[] | undefined;

export default class Importer {
  schema;

  constructor(fields: GenericField[]) {
    this.schema = new ImporterSchema(fields);
  }

  downloadTemplate(templateFileName: any) {
    return Excel.exportAsExcelFile([], this.schema.labels, templateFileName);
  }

  async castForDisplay(row: any, index: number) {
    return this.schema.castForDisplay(row, index);
  }

  async castForImport(row: any) {
    return this.schema.castForImport(row);
  }

  castFieldsForMap() {
    return this.schema.castFieldsForMap();
  }

  async convertExcelFileToJson(file: File): Promise<JsonDataType> {
    const aoa = await this._xlsx_to_aoa(file);

    if (!aoa) {
      return;
    }

    const json = this._aoa_to_json(aoa);

    return json;
  }

  async convertCSVFileToJson(file: File): Promise<JsonDataType> {
    const aoa = await this._csv_to_aoa(file);

    if (!aoa) {
      return;
    }

    const json = this._aoa_to_json(aoa);

    return json;
  }

  async _convertFileToWorkbook(file: File) {
    try {
      const data = await this._readFile(file);
      return XLSX.read(data, {
        type: 'array',
      });
    } catch (error) {
      throw new Error(i18n('importer.errors.invalidFileUpload'));
    }
  }

  async _readFile(file: File) {
    if (!file) {
      return null;
    }

    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = (e: ProgressEvent<FileReader>) => {
        try {
          if (e.target) {
            const data = new Uint8Array(e.target.result as Buffer);
            resolve(data);
          }
        } catch (error) {
          reject(error);
        }
      };

      reader.onerror = (e) => {
        reject();
      };

      reader.readAsArrayBuffer(file);
    });
  }

  async _xlsx_to_aoa(xlsx: File): Promise<any[][] | undefined> {
    const workbook: XLSX.WorkBook = await this._convertFileToWorkbook(xlsx),
      aoa: any[][] = XLSX.utils.sheet_to_json(workbook.Sheets[workbook.SheetNames[0]], {
        header: 1,
        blankrows: true,
        defval: null,
        raw: true,
      });

    if (!Array.isArray(aoa) || aoa.length < 2) {
      return undefined;
    }

    return aoa;
  }

  async _csv_to_aoa(csv: File): Promise<any[][] | undefined> {
    const workbook: XLSX.WorkBook = await this._convertFileToWorkbook(csv),
      csvString: string = XLSX.utils.sheet_to_csv(workbook.Sheets[workbook.SheetNames[0]], {
        blankrows: true,
      }),
      lines: string[] = csvString.split(/\r\n|\n/);

    if (lines.length < 2) {
      return;
    }

    const [headerString, ...contentStringArr] = lines.filter((line: any) => Boolean(line.length)),
      rows: any[][] = contentStringArr.map((contentString: string) =>
        this._split_csvString(contentString).map((v: string) => {
          try {
            return JSON.parse(v);
          } catch (e) {
            return v;
          }
        }),
      ),
      headers: string[] = this._split_csvString(headerString),
      aoa: any[][] = [headers, ...rows];

    return aoa;
  }

  _split_csvString(str: string): string[] {
    let delimitedStrArr;

    // try {
    //   // More details on how this regex works https://regex101.com/r/AvSDHy/1
    //   delimitedStrArr = str.match(/(?<=,|\n|^)("(?:(?:"")*[^"]*)*"|[^",\n]*|(?:\n|$))/g) || [];
    // }
    // catch (e) { // fallback until safari upgrades regexp engine
    delimitedStrArr = str
      .replace(/".*?"/g, (str) => str.replace(/,/g, '~|||~'))
      .split(',')
      .map((str) => str.replace(/~\|\|\|~/g, ','));
    //}

    return delimitedStrArr;
  }

  _aoa_to_json([headers, ...rows]: any[][]): JsonDataType {
    const res: JsonDataType = [];

    for (const row of rows) {
      const obj: IJsonDataElement = {};
      row.forEach((value: any, index: number) => {
        if (!headers[index]) {
          return;
        }
        obj[headers[index]] = this._castValueAsNullable(value);

        if (headers[index] === 'Address Country') {
          obj[headers[index]] = obj[headers[index]] || 'US';
        }
      });
      res.push(obj);
    }

    return res;
  }

  _castValueAsNullable = (value: any) => (!value ? null : value);
}
