import model from 'modules/auth/userModel.ts';

const { fields } = model;

export default [
  fields.id,
  fields.email,
  fields.fullName,
  fields.phoneNumber,
  fields.roles,
  fields.disabled,
  fields.createdAt,
];
