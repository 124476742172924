import { i18n } from 'i18n';
import BooleanField from 'modules/shared/fields/booleanField.ts';
import DateTimeField from 'modules/shared/fields/dateTimeField.ts';
import DateTimeRangeField from 'modules/shared/fields/dateTimeRangeField.ts';
import IdField from 'modules/shared/fields/idField.ts';
import RelationToManyField from 'modules/shared/fields/relationToManyField.ts';
import RelationToOneField from 'modules/shared/fields/relationToOneField.ts';
import StringField from 'modules/shared/fields/stringField.ts';

function label(name) {
  return i18n(`entities.device.fields.${name}`);
}

const fields = {
  id: new IdField('id', label('id')),
  name: new StringField('name', label('name'), {
    required: true,
    min: 1,
  }),
  exclusionaryCategories: new RelationToManyField(
    'deviceExclusionaryCategory',
    label('exclusionaryCategories'),
    {},
  ),
  inclusionaryCategories: new RelationToManyField(
    'deviceInclusionaryCategory',
    label('inclusionaryCategories'),
    {},
  ),
  channels: new RelationToManyField('channels', label('channels'), {}),
  assigned: new BooleanField('assigned', label('assigned')),
  status: new RelationToOneField('status', label('status'), {
    required: true,
  }),
  lastSeen: new DateTimeField('lastSeen', label('lastSeen'), {}),
  ip: new StringField('ip', label('ip'), {}),
  adContentRatio: new StringField('adContentRatio', label('adContentRatio'), {}),
  gps: new StringField('gps', label('gps'), {}),
  hdmiActive: new StringField('hdmiActive', 'HDMI Active', {}),
  currentImpressions: new StringField('currentImpressions', 'Current Impressions', {}),
  deviceProfile: new RelationToOneField('deviceProfile', label('deviceProfile'), {}),
  deviceNetwork: new RelationToOneField('deviceNetwork', label('deviceNetwork'), {}),
  createdAt: new DateTimeField('createdAt', label('createdAt')),
  updatedAt: new DateTimeField('updatedAt', label('updatedAt')),
  createdAtRange: new DateTimeRangeField('createdAtRange', label('createdAtRange')),
  lastSeenRange: new DateTimeRangeField('lastSeenRange', label('lastSeenRange')),
};

export default {
  fields,
};
