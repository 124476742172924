import * as yup from 'yup';

import { i18n } from '../../../i18n';
import GenericField from '../../../modules/shared/fields/genericField';
import { IBooleanFieldOptions, Options } from './fieldTypes';

export default class BooleanField extends GenericField {
  yesLabel: string;

  noLabel: string;

  literalMapping;

  type: boolean;

  required: boolean;

  constructor(
    name: string,
    label: string,
    {
      required = false,
      yesLabel = undefined,
      noLabel = undefined,
      type = 'boolean',
      literalMapping = {
        '0': ['false', 'f', 'no', 'n', '0'],
        '1': ['true', 't', 'yes', 'y', '1'],
      },
      dependentFieldSettings,
    }: Options<IBooleanFieldOptions> = {},
  ) {
    super(name, label);
    this.yesLabel = yesLabel || i18n('common.yes');
    this.noLabel = noLabel || i18n('common.no');
    this.literalMapping = literalMapping;
    this.required = required;
    this.dependentFieldSettings = dependentFieldSettings;
    this.type = type;
  }

  forView(value: any) {
    return value ? this.yesLabel : this.noLabel;
  }

  forFormInitialValue(value: any) {
    return value;
  }

  forForm() {
    let yupChain = yup.bool().nullable(!this.required).default(false).label(this.label);

    if (this.dependentFieldSettings) {
      for (const dependentFieldSetting of this.dependentFieldSettings) {
        const [fieldName, settings] = Object.entries(dependentFieldSetting)[0];
        yupChain = yupChain.when(fieldName, settings);
      }
    }

    return yupChain;
  }

  forFilter() {
    const yupChain = yup.bool().label(this.label);
    return yupChain;
  }

  forExport() {
    return yup.bool().nullable(!this.required).default(false).label(this.label);
  }

  forImport() {
    let yupChain = yup.bool().nullable(!this.required).default(false).label(this.label);

    if (this.dependentFieldSettings) {
      for (const dependentFieldSetting of this.dependentFieldSettings) {
        const [fieldName, settings] = Object.entries(dependentFieldSetting)[0];
        yupChain = yupChain.when(fieldName, settings);
      }
    }

    return yupChain;
  }

  forInput: undefined;

  literalMapToValue(literal: any) {
    if (!literal) {
      return null;
    }

    const _stringifiedNormalizedLiteral = String(literal).trim().toLowerCase();

    for (const key in this.literalMapping) {
      const matches = this.literalMapping[key].includes(_stringifiedNormalizedLiteral);
      if (matches) {
        return key;
      }
    }
    return literal;
  }
}
