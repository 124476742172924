import model from 'modules/callToAction/callToActionModel';

const { fields } = model;

export default [
  fields.id,
  fields.type,
  fields.label,
  fields.details,
  fields.url,
  fields.action,
  fields.tags,
  //  Fields.analyticsEvents,
  fields.createdAt,
  fields.updatedAt,
];
