import form from 'modules/ooh/form/oohFormReducers';
import importer from 'modules/ooh/importer/oohImporterReducers';
import list from 'modules/ooh/list/oohListReducers';
import view from 'modules/ooh/view/oohViewReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  view,
  form,
  importer,
});
