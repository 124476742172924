import { ThunkAction } from 'redux-thunk';
import { RootState } from '~/types/models/rootState';
import { BasicAction, BasicErrorAction } from '../../common';
import { Campaign } from '~/types/models/campaign';

const path = `CAMPAIGN_LIST`;

// String action labels
export const ListActionTypes = {
  FETCH_START: `${path}_FETCH_START`,
  FETCH_SUCCESS: `${path}_FETCH_SUCCESS`,
  FETCH_ERROR: `${path}_FETCH_ERROR`,
  DELETE_START: `${path}_DELETE_START`,
  DELETE_SUCCESS: `${path}_DELETE_SUCCESS`,
  DELETE_ERROR: `${path}_DELETE_ERROR`,
  SELECT_START: `${path}_DELETE_START`,
  SELECT_SUCCESS: `${path}_DELETE_SUCCESS`,
  SELECT_ERROR: `${path}_DELETE_ERROR`,
} as const;

type ListActionTypeLiterals = typeof ListActionTypes;

type BasicCampaignListActions =
  | BasicAction<ListActionTypeLiterals, Campaign>
  | BasicErrorAction<ListActionTypeLiterals, unknown>;

export interface CampaignListActions {
  doFetch: () => ThunkAction<void, RootState, unknown, BasicCampaignListActions>; // TODO Create types for single actions better
  selectRows: (selectRows) => ThunkAction<void, RootState, unknown, BasicCampaignListActions>; // TODO Create types for single actions better
  doDelete: (id: string) => ThunkAction<void, RootState, unknown, BasicCampaignListActions>; // TODO Create types for single actions better
}
