import model from 'modules/transaction/transactionModel';

const { fields } = model;

export default [
  fields.amount,
  fields.when,
  fields.content,
  fields.organization,
  fields.storefront,
  fields.device,
  //  Fields.analyticsEvent,
  fields.category,
];
