import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { CartFormReducerState } from '~/types/modules/cart/form/reducers';
import actions from '../form/actions';

const initialData: CartFormReducerState = {
  record: {
    cart: {
      billTo: {
        companyName: '',
        companyAddressStreet: '',
        companyAddressCity: '',
        companyAddressState: '',
        companyAddressZip: '',
        companyAddressCountry: '',
        companyAddressPhone: '',
        companyEmail: '',
      },
      vendor: {
        name: '',
        email: '',
        address: {
          street: '',
          city: '',
          state: '',
          zip: '',
          country: '',
          phone: '',
        },
      },
      id: '',
      paymentMethod: [],
      userId: '',
      quoteId: '',
      invoiceId: '',
      orderId: '',
      step: '',
      platform_fee: 0,
      subtotal: 0,
      discount: 0,
      tax: 0,
      final_price: 0,
      createdAt: '',
      updatedAt: '',
      dueDate: '',
      invoiceDescription: '',
    },
    invoice: {
      button: 'Invoice Me & Reserve Inventory',
      buttonDisabled: false,
      url: '',
      pdf: '',
      number: 0,
      due_date: '',
      paymentMethod: [],
    },
    items: [],
    inventory: [],
  },
  lastSyncAt: '',
  loading: false,
  syncing: false,
  error: '',
};

const reducer = (state = initialData, { type, payload }): CartFormReducerState => {
  switch (type) {
    case actions.FIND_STARTED:
      return {
        ...state,
        loading: true,
        error: '',
      };
    case actions.FIND_SUCCESS:
      return {
        ...state,
        record: {
          ...state.record,
          ...payload,
        },
        loading: false,
      };
    case actions.FIND_ERROR:
      return {
        ...state,
        loading: false,
        error: payload,
      };
    case actions.CREATE_STRIPE_INVOICE_STARTED:
      return {
        ...state,
        loading: true,
        error: '',
      };
    case actions.CREATE_STRIPE_INVOICE_SUCCESS:
      return {
        ...state,
        record: {
          ...initialData.record,
        },
        loading: false,
      };
    case actions.CREATE_STRIPE_INVOICE_ERROR:
      return {
        ...state,
        loading: false,
        error: payload,
      };
    default:
      return { ...state };
  }
};

const config = {
  key: 'form',
  storage: storage,
  whitelist: ['record'],
};

const persistedReducer = persistReducer(config, reducer);

export default persistedReducer;
