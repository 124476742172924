import { getListReducer } from 'modules/shared/reducers';
import { combineReducers } from 'redux';

import actions from './serviceTypeActions';

const INITIAL_PAGE_SIZE = 100;

export const list = getListReducer(actions.listActions, INITIAL_PAGE_SIZE);

const reducers = combineReducers({
  list,
});

export default reducers;
