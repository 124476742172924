import { BasicRefWithSlug, ResponseFile } from '.';
import { AgencyCampaign, AgencyCampaignModel } from './AgencyCampaign';

export interface RequestForProposalModel {
  id: string;
  agencyCampaign: AgencyCampaignModel;
  supplyPartner: BasicRefWithSlug;
  events: Event[];
  hasMediaFiles: boolean;
  responseFile?: ResponseFile;
  mediaFilesLink?: string;
}

export class RequestForProposal {
  static create(model: RequestForProposalModel): RequestForProposal {
    const { id, agencyCampaign: agencyCampaignModel, supplyPartner } = model;

    return new RequestForProposal(id, AgencyCampaign.create(agencyCampaignModel), supplyPartner);
  }

  protected constructor(
    public id: string,
    public agencyCampaign: AgencyCampaign,
    public supplyPartner: BasicRefWithSlug,
  ) {}
}
