import destroy from '~/modules/storefrontAvailability/destroy/storefrontAvailabilityDestroyReducers';
import form from '~/modules/storefrontAvailability/form/storefrontAvailabilityFormReducers';
import list from '~/modules/storefrontAvailability/list/storefrontAvailabilityListReducers';
// import view from 'modules/storefrontAvailability/view/storefrontAvailabilityViewReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  // view,
  destroy,
});
