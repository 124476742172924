import destroy from 'modules/deviceProfile/destroy/deviceProfileDestroyReducers';
import form from 'modules/deviceProfile/form/deviceProfileFormReducers';
import importerReducer from 'modules/deviceProfile/importer/deviceProfileImporterReducers';
import list from 'modules/deviceProfile/list/deviceProfileListReducers';
import view from 'modules/deviceProfile/view/deviceProfileViewReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
});
