import { parseDateFromString } from '~/utils/dateUtil';

export interface AgencyCampaignModel {
  id: string;
  name: string;
  dueDate: string;
  endDate: string;
  startDate: string;
  mediaFilesZipLink: string;
}

export class AgencyCampaign {
  static create(model: AgencyCampaignModel): AgencyCampaign {
    const { id, dueDate, endDate, startDate, name, mediaFilesZipLink } = model;

    return new AgencyCampaign(id, name, dueDate, endDate, startDate, mediaFilesZipLink);
  }

  public constructor(
    public id: string,
    public name: string,
    public dueDateString: string,
    public endDateString: string,
    public startDateString: string,
    public mediaFilesZipLink: string,
  ) {}

  public get dueDate(): Date {
    return parseDateFromString(this.dueDateString);
  }

  public get endDate(): Date {
    return parseDateFromString(this.endDateString);
  }

  public get createdAt(): Date {
    return parseDateFromString(this.startDateString);
  }
}
