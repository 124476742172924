import moment from 'moment';
import * as yup from 'yup';

import { i18n } from '../../../i18n';
import GenericField from '../../../modules/shared/fields/genericField';

export default class DateRangeField extends GenericField {
  forExport: undefined;

  forForm: undefined;

  forImport: undefined;

  forInput: undefined;

  forView: undefined;

  forFilter() {
    return yup
      .array()
      .of(
        yup
          .mixed()
          .nullable(true)
          .label(this.label)
          .test('is-date', i18n('validation.mixed.default'), (value) => {
            if (!value) {
              return true;
            }

            return moment(value, 'YYYY-MM-DD').isValid();
          })
          .transform((value) => (value ? moment(value).format('YYYY-MM-DD') : null)),
      )
      .label(this.label);
  }

  forFormInitialValue(value: any) {
    if (!value || !value.length) {
      return [];
    }

    return value.map((item: moment.MomentInput) =>
      item ? moment(item, 'YYYY-MM-DD').toDate() : null,
    );
  }

  literalMapToValue: undefined;
}
