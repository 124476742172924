import { createSelector } from 'reselect';

const selectRaw = (state) => state.layout,
  selectMenuVisible = createSelector([selectRaw], (layout) => Boolean(layout.menuVisible)),
  selectLoading = createSelector([selectRaw], (layout) => Boolean(layout.loading)),
  selectLanguage = createSelector([selectRaw], (layout) => layout.language),
  selectors = {
    selectRaw,
    selectMenuVisible,
    selectLoading,
    selectLanguage,
  };

export default selectors;
