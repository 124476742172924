import model from 'modules/sector/sectorModel';

const { fields } = model;

export default [
  fields.id,
  fields.name,
  fields.value,
  fields.classification,
  fields.createdAt,
  fields.updatedAt,
];
