export default class PermissionChecker {
  constructor(currentUser) {
    this.currentUser = currentUser;
    this.userRoles = currentUser ? currentUser.roles : [];
    this.accountType = currentUser ? currentUser.accountType : [];
  }

  match(permission) {
    if (!permission) {
      return true;
    }

    return this.rolesMatchOneOf(permission.allowedRoles);
  }

  rolesMatchOneOf(arg) {
    if (!this.userRoles) {
      return false;
    }

    if (!arg) {
      return false;
    }

    if (Array.isArray(arg)) {
      if (!arg.length) {
        return false;
      }

      return arg.some((role) => this.userRoles.filter((userRole) => userRole.name === role).length);
    }

    return this.userRoles.filter((userRole) => arg.includes(userRole.name)).length;
  }

  accountTypeMatchesTo(arg) {
    if (!arg || !this.accountType) {
      return false;
    }

    if (Array.isArray(arg)) {
      if (!arg.length) {
        return false;
      }

      return arg.some((type) => this.accountType === type);
    }

    return this.accountType === arg;
  }

  get isEmptyPermissions() {
    if (!this.isAuthenticated) {
      return false;
    }

    return !this.userRoles || !this.userRoles.length;
  }

  get isAuthenticated() {
    return Boolean(this.currentUser) && Boolean(this.currentUser.id);
  }

  get isEmailVerified() {
    if (!this.isAuthenticated) {
      return false;
    }

    return this.currentUser.emailVerified;
  }
}
