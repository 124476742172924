import authAxios from '../../modules/shared/axios/authAxios';

class PriceRequestService {
  static list = async () => {
    const res = await authAxios.get('/adjustAvailabilityPrice');

    return res.data;
  };

  static answerRequest = async (id: string, accept: boolean) => {
    const res = await authAxios.get(`/adjustAvailabilityPrice/${id}?accept=${accept}`);
    console.log(res);

    return res.data;
  };
}

export default PriceRequestService;
