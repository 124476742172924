import { i18n } from 'i18n';
import exporterFields from 'modules/market/list/marketListExporterFields';
import selectors from 'modules/market/list/marketListSelectors';
import MarketService from 'modules/market/marketService';
import Errors from 'modules/shared/error/errors';
import Exporter from 'modules/shared/exporter/exporter';
import { getHistory } from 'modules/store';
import Message from '~/view/shared/message';

const prefix = 'MARKET_LIST',
  actions = {
    FETCH_STARTED: `${prefix}_FETCH_STARTED`,
    FETCH_SUCCESS: `${prefix}_FETCH_SUCCESS`,
    FETCH_ERROR: `${prefix}_FETCH_ERROR`,

    RESETED: `${prefix}_RESETED`,
    TOGGLE_ONE_SELECTED: `${prefix}_TOGGLE_ONE_SELECTED`,
    TOGGLE_ALL_SELECTED: `${prefix}_TOGGLE_ALL_SELECTED`,
    CLEAR_ALL_SELECTED: `${prefix}_CLEAR_ALL_SELECTED`,

    PAGINATION_CHANGED: `${prefix}_PAGINATION_CHANGED`,
    SORTER_CHANGED: `${prefix}_SORTER_CHANGED`,

    EXPORT_STARTED: `${prefix}_EXPORT_STARTED`,
    EXPORT_SUCCESS: `${prefix}_EXPORT_SUCCESS`,
    EXPORT_ERROR: `${prefix}_EXPORT_ERROR`,

    doClearAllSelected() {
      return {
        type: actions.CLEAR_ALL_SELECTED,
      };
    },

    doToggleAllSelected() {
      return {
        type: actions.TOGGLE_ALL_SELECTED,
      };
    },

    doToggleOneSelected(id) {
      return {
        type: actions.TOGGLE_ONE_SELECTED,
        payload: id,
      };
    },

    doReset: () => async (dispatch) => {
      dispatch({
        type: actions.RESETED,
      });

      dispatch(actions.doFetch());
    },

    doExport: () => async (dispatch, getState) => {
      try {
        if (!exporterFields || !exporterFields.length) {
          throw new Error('exporterFields is required');
        }

        dispatch({
          type: actions.EXPORT_STARTED,
        });

        const filter = selectors.selectFilter(getState()),
          response = await MarketService.list(
            filter,
            selectors.selectOrderBy(getState()),
            null,
            null,
          );

        new Exporter(
          exporterFields,
          i18n('entities.market.exporterFileName'),
        ).transformAndExportAsExcelFile(response.rows);

        dispatch({
          type: actions.EXPORT_SUCCESS,
        });
      } catch (error) {
        const errorData = Errors.getErrorData(error);
        dispatch({
          type: actions.EXPORT_ERROR,
          payload: errorData,
        });
      }
    },

    doChangePagination: (pagination) => async (dispatch, getState) => {
      dispatch({
        type: actions.PAGINATION_CHANGED,
        payload: pagination,
      });

      const filter = selectors.selectFilter(getState());

      dispatch(actions.doFetch(filter, true));
    },

    doChangeSort: (sorter) => async (dispatch, getState) => {
      dispatch({
        type: actions.SORTER_CHANGED,
        payload: sorter,
      });

      const filter = selectors.selectFilter(getState());

      dispatch(actions.doFetch(filter, true));
    },

    doFetch:
      (filter, keepPagination = false) =>
      async (dispatch, getState) => {
        try {
          dispatch({
            type: actions.FETCH_STARTED,
            payload: { filter, keepPagination },
          });

          const response = await MarketService.list(
            filter,
            selectors.selectOrderBy(getState()),
            selectors.selectLimit(getState()),
            selectors.selectOffset(getState()),
          );

          dispatch({
            type: actions.FETCH_SUCCESS,
            payload: {
              rows: response.rows,
              count: response.count,
            },
          });
        } catch (error) {
          const errorData = Errors.getErrorData(error);
          dispatch({
            type: actions.FETCH_ERROR,
            payload: errorData,
          });
        }
      },

    TOGGLE_ACTIVE_STARTED: `${prefix}_TOGGLE_ACTIVE_STARTED`,
    TOGGLE_ACTIVE_SUCCESS: `${prefix}_TOGGLE_ACTIVE_SUCCESS`,
    TOGGLE_ACTIVE_ERROR: `${prefix}_TOGGLE_ACTIVE_ERROR`,

    doToggleActive:
      (id, active, keepPagination = true) =>
      async (dispatch) => {
        try {
          dispatch({
            type: actions.TOGGLE_ACTIVE_STARTED,
            payload: {
              keepPagination,
            },
          });

          await MarketService.setActive(id, { active: active ? 1 : 0 });

          dispatch({
            type: actions.TOGGLE_ACTIVE_SUCCESS,
          });

          Message.success(i18n('entities.market.update.success'));

          getHistory().push('/market');
        } catch (error) {
          Errors.handle(error);
          const errorData = Errors.getErrorData(error);

          dispatch({
            type: actions.TOGGLE_ACTIVE_ERROR,
            payload: errorData,
          });
        }
      },
  };

export default actions;
