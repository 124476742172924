import authSelectors from 'modules/auth/authSelectors';
import PermissionChecker from 'modules/auth/permissionChecker';
import { createSelector } from 'reselect';
import Permissions from 'security/permissions';

const getPermissionsSelector = (propName = '') => {
  const read = createSelector([authSelectors.selectCurrentUser], (currentUser) =>
      new PermissionChecker(currentUser).match(Permissions.values[`${propName}Read`]),
    ),
    edit = createSelector([authSelectors.selectCurrentUser], (currentUser) =>
      new PermissionChecker(currentUser).match(Permissions.values[`${propName}Edit`]),
    ),
    create = createSelector([authSelectors.selectCurrentUser], (currentUser) =>
      new PermissionChecker(currentUser).match(Permissions.values[`${propName}Create`]),
    ),
    destroy = createSelector([authSelectors.selectCurrentUser], (currentUser) =>
      new PermissionChecker(currentUser).match(Permissions.values[`${propName}Destroy`]),
    ),
    selectIsAccountTypeOneOf = (arg) =>
      createSelector([authSelectors.selectCurrentUser], (currentUser) =>
        new PermissionChecker(currentUser).accountTypeMatchesTo(arg),
      );

  return {
    read,
    edit,
    create,
    destroy,
    selectIsAccountTypeOneOf,
  };
};

export default getPermissionsSelector;
