import { isString } from 'lodash';
import * as yup from 'yup';

import { i18n } from '../../../i18n';
import GenericField from '../../../modules/shared/fields/genericField';
import {
  DependentFieldSettings,
  EnumeratorFieldValueItem,
  IEnumeratorFieldOptions,
  Options,
} from './fieldTypes';

export default class EnumeratorField extends GenericField {
  values: EnumeratorFieldValueItem[];

  required: boolean;

  constructor(
    name: string,
    label: string,
    values: EnumeratorFieldValueItem[],
    { required = false, dependentFieldSettings }: Options<IEnumeratorFieldOptions> = {},
  ) {
    super(name, label);

    this.values = values || [];
    this.required = required;
    this.dependentFieldSettings = dependentFieldSettings;
  }

  _id(value: any) {
    if (!value) {
      return value;
    }

    if (isString(value)) {
      return value;
    }

    return value.id;
  }

  _label(value: any) {
    if (!value) {
      return value;
    }

    if (isString(value)) {
      return value;
    }

    return value.label;
  }

  forView(value: any) {
    const option = this.values.find((valueItem: any) => valueItem.id === this._id(value));

    if (option) {
      return this._label(option);
    }

    return value;
  }

  forFormInitialValue(value: any) {
    if (Array.isArray(value)) {
      return value.map((option) => this._id(option));
    }

    return this._id(value);
  }

  forInput: undefined;

  forFilter() {
    return yup
      .string()
      .label(this.label)
      .oneOf([null, ...this.values.map((value: any) => this._id(value))]);
  }

  forForm() {
    let yupChain = yup.string().nullable(true).label(this.label);

    if (this.required) {
      yupChain = yupChain.required(i18n('validation.string.selected'));
    }

    if (this.dependentFieldSettings) {
      for (const dependentFieldSetting of this.dependentFieldSettings) {
        const [fieldName, settings] = Object.entries(dependentFieldSetting)[0];
        yupChain = yupChain.when(fieldName, settings);
      }
    }

    return yupChain;
  }

  forExport() {
    return yup.mixed().label(this.label);
  }

  forImport() {
    let yupChain = yup.string().label(this.label).nullable(true);

    if (this.required) {
      yupChain = yupChain.required(i18n('validation.string.selected'));
    }

    if (this.dependentFieldSettings) {
      for (const dependentFieldSetting of this.dependentFieldSettings) {
        const [fieldName, settings] = Object.entries(dependentFieldSetting)[0];
        yupChain = yupChain.when(fieldName, settings);
      }
    }

    return yupChain;
  }

  literalMapToValue(literal: any) {
    if (!literal) {
      return null;
    }

    const _stringifiedNormalizedLiteral = String(literal).trim().toLowerCase();

    for (const option of this.values) {
      const { literalValues } = option,
        matches = literalValues && literalValues.includes(_stringifiedNormalizedLiteral);
      if (matches) {
        return option.id;
      }
    }
    return literal;
  }
}
