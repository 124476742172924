import model from 'modules/channel/channelModel';

const { fields } = model;

export default [
  fields.id,
  fields.name,
  fields.slug,
  fields.url,
  fields.socketStream,
  fields.devices,
  fields.content,
  fields.marketplaceListing,
  // Fields.analyticsEvents,
  fields.from,
  fields.to,
  fields.type,
  fields.active,
  fields.tags,
  fields.createdAt,
  fields.updatedAt,
];
