import moment from 'moment';
import * as yup from 'yup';

import GenericField from '../../../modules/shared/fields/genericField';

export default class DateTimeRangeField extends GenericField {
  forFilter() {
    return yup.mixed().label(this.label);
  }

  forFormInitialValue(value: any) {
    if (!value || !value.length) {
      return [];
    }

    return value.map((item: moment.MomentInput) => (item ? moment(item).toDate() : null));
  }

  forExport: undefined;

  forImport: undefined;

  forForm: undefined;

  forInput: undefined;

  forView: undefined;

  literalMapToValue: undefined;
}
