import { RFPUpload } from '~/types/models/campaign';
import { PortalTypes } from '~/types/modules/campaign/agency/portal/actions.d';
import { PortalReducerState } from '~/types/modules/campaign/agency/portal/reducers.d';

const initialState: PortalReducerState = {
  loading: true,
  error: '',
  spreadsheetUploadErrors: undefined,
  record: {} as RFPUpload,
};

const reducer = (state = initialState, { type, payload }): PortalReducerState => {
  switch (type) {
    case PortalTypes.FETCH_START:
      return {
        ...state,
        loading: true,
      };
    case PortalTypes.FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        record: payload,
      };
    case PortalTypes.FETCH_ERROR:
      return {
        ...state,
        loading: false,
        error: payload,
      };
    case PortalTypes.RFP_UPLOAD_START:
      return {
        ...state,
        loading: true,
      };
    case PortalTypes.RFP_UPLOAD_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case PortalTypes.RFP_UPLOAD_ERROR:
      return {
        ...state,
        loading: false,
        spreadsheetUploadErrors: payload,
      };
    case PortalTypes.RFP_UPLOAD_ERROR_CLEAR:
      return {
        ...state,
        loading: false,
        spreadsheetUploadErrors: undefined,
      };
    default:
      return {
        ...state,
      };
  }
};

// eslint-disable-next-line import/no-default-export
export default reducer;
