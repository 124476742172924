import { ThunkAction } from 'redux-thunk';
import { RootState } from '~/types/models/rootState';
import { BasicAction, BasicErrorAction } from '../../../common';
import { RFPUpload } from '~/types/models/campaign';
import { UploaderRecord } from '../../../../../components/UploadFiles/RFPUploadFiles/Uploader';

const path = `PORTAL`;

// String action labels
export const PortalTypes = {
  FETCH_START: `${path}_FETCH_START`,
  FETCH_SUCCESS: `${path}_FETCH_SUCCESS`,
  FETCH_ERROR: `${path}_FETCH_ERROR`,
  RFP_UPLOAD_START: `${path}_RFP_UPLOAD_START`,
  RFP_UPLOAD_SUCCESS: `${path}_RFP_UPLOAD_SUCCESS`,
  RFP_UPLOAD_ERROR: `${path}_RFP_UPLOAD_ERROR`,
  RFP_UPLOAD_ERROR_CLEAR: `${path}_RFP_UPLOAD_ERROR_CLEAR`,
  RFP_UPLOAD_MEDIA_START: `${path}_RFP_UPLOAD_START`,
  RFP_UPLOAD_MEDIA_SUCCESS: `${path}_RFP_UPLOAD_SUCCESS`,
  RFP_UPLOAD_MEDIA_ERROR: `${path}_RFP_UPLOAD_ERROR`,
} as const;

type PortalActionTypeLiterals = typeof PortalTypes;

type BasicPortalActions =
  | BasicAction<PortalActionTypeLiterals, RFPUpload>
  | BasicErrorAction<PortalActionTypeLiterals, unknown>;

export interface PortalActions {
  doFetch: (id: string) => ThunkAction<void, RootState, unknown, BasicPortalActions>;
  sendRFPUpload: (
    id: string,
    data: UploaderRecord,
  ) => ThunkAction<void, RootState, unknown, BasicPortalActions>;
  sendRFPMediaUpload: (
    id: string,
    data: UploaderRecord[],
  ) => ThunkAction<void, RootState, unknown, BasicPortalActions>;
  clearSpreadUploadErrors: () => ThunkAction<void, RootState, unknown, BasicPortalActions>;
}
