import { getListActions } from 'modules/shared/actions';

import selectors from './campaignTypeSelectors';
import service from './campaignTypeService';

export const listActions = getListActions({
  service,
  selectors: selectors.list,
  prefix: 'CAMPAIGN_TYPE_LIST',
});

export default { listActions };
