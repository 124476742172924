import React, { useState } from 'react';
import camelCase from 'lodash/camelCase';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Collapse, ListItem } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useAnalytics } from '~/analytics/segment/useAnalytics';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { NavLink as RouterLink } from 'react-router-dom';

const analytics = useAnalytics();

const useStyles = makeStyles((theme) => ({
  item: {
    display: 'block',
    paddingTop: '0px',
    paddingBottom: '0px',
  },
  itemLeaf: {
    display: 'flex',
    paddingTop: '0px',
    paddingBottom: '0px',
  },
  buttonWrapper: {
    width: '100%',
    marginRight: '16px',
    paddingTop: 0,
    paddingBottom: 0,
  },
  button: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: '10px 8px',
    color: 'black',
    textTransform: 'none',
    textDecoration: 'none',
    letterSpacing: 0,
    width: '100%',
    cursor: 'pointer',
    borderRadius: 8,
    fontFamily: 'proxima-nova, helvetica, arial',
    fontWeight: theme.typography.fontWeightRegular,
    '&:hover': {
      backgroundColor: theme.palette.background.hover,
    },
  },
  buttonExpander: {},
  buttonLeaf: {
    padding: '4px',
    '&.depth-0': {
      fontWeight: theme.typography.fontWeightMedium,
    },
  },
  icon: {
    color: theme.palette.icon,
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(1),
  },
  expandIcon: {
    marginLeft: 'auto',
    height: '16px !important',
    width: '16px !important',
  },
  label: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 'auto',
  },
  active: {
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightMedium,
    '& $icon': {
      color: theme.palette.primary.main,
    },
  },
}));

function NavItem({
  title,
  href,
  depth,
  children,
  icon: Icon,
  className,
  open: openProp,
  label: Label,
  onClick,
  ...rest
}) {
  const classes = useStyles(),
    [open, setOpen] = useState(openProp),
    handleToggle = () => {
      setOpen((prevOpen) => !prevOpen);
    };

  let paddingLeft = 8;

  if (depth > 0) {
    paddingLeft = 32 + 8 * depth;
  }

  const style = {
    paddingLeft,
  };

  if (children) {
    return (
      <ListItem
        {...rest}
        className={clsx(classes.item, className)}
        disableGutters
        disablePadding
        key={title}
      >
        <div className={classes.buttonWrapper}>
          <div className={classes.button} onClick={handleToggle} style={style}>
            {Icon && <Icon className={classes.icon} />}
            {title}
            {open ? (
              <ExpandLessIcon className={classes.expandIcon} />
            ) : (
              <ExpandMoreIcon className={classes.expandIcon} />
            )}
          </div>
          <Collapse in={open}>{children}</Collapse>
        </div>
      </ListItem>
    );
  }

  function trackAnalytics(link, title) {
    let eventName = 'SidebarNavLinkClick';
    if (link === '/crm/contacts/list') {
      eventName = 'CampaignsManagerClick';
    } else if (link === '/crm/contacts/list') {
      eventName = 'CampaignsManagerClick';
    } else if (link === '/campaign/agency') {
      eventName = 'CampaignsManagerClick';
    } else if (link === '/crm/contacts/list') {
      eventName = 'CustomerContactsClick';
    } else if (link === '/crm/company/list') {
      eventName = 'CustomersCompaniesClick';
    } else if (link === '/tools/overview') {
      eventName = 'ToolsOverviewClick';
    } else if (link === '/plan/persona') {
      eventName = 'ToolsPersonaBuilderClick';
    } else if (link === '/placerank') {
      eventName = 'ToolsPlaceRankClick';
    } else if (link === '/sales/slidegen') {
      eventName = 'ToolsSlideBuilderClick';
    } else if (link === '/threads') {
      eventName = 'ToolsThreadsClick';
    } else if (link === '/sales/leadgen') {
      eventName = 'ToolsLeadGenClick';
    } else if (link === '/profile') {
      eventName = 'ProfileClick';
    } else if (link === '/iam') {
      eventName = 'UserManagementClick';
    } else if (link === '/directory') {
      eventName = 'PartnerDirectoryClick';
    } else if (link === '/location') {
      eventName = 'InventoryDirectoryClick';
    } else if (link === '/') {
      eventName = 'DashboardClick';
    } else if (link === '/invoices') {
      eventName = 'InvoicesClick';
    }
    analytics.track(eventName, { source: 'sideBarNav', link, title });
  }

  return (
    <ListItem {...rest} className={clsx(classes.itemLeaf, className)} disableGutters key={title}>
      <RouterLink
        activeClassName={classes.active}
        className={clsx(classes.button, classes.buttonLeaf, `depth-${depth}`)}
        // Component={RouterLink}
        exact
        style={style}
        to={href}
        onClick={() => trackAnalytics(href, title)}
        data-testid={`left-nav-${camelCase(title)}`}
      >
        {Icon && <Icon className={classes.icon} />}
        {title}
        {Label && (
          <span className={classes.label}>
            <Label />
          </span>
        )}
      </RouterLink>
    </ListItem>
  );
}

NavItem.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  depth: PropTypes.number.isRequired,
  href: PropTypes.string,
  icon: PropTypes.any,
  label: PropTypes.any,
  open: PropTypes.bool,
  title: PropTypes.string.isRequired,
};

NavItem.defaultProps = {
  open: false,
};

export default NavItem;
