import { i18n } from 'i18n';
import DateTimeField from 'modules/shared/fields/dateTimeField.ts';
import DateTimeRangeField from 'modules/shared/fields/dateTimeRangeField.ts';
import EnumeratorField from 'modules/shared/fields/enumeratorField.ts';
import IdField from 'modules/shared/fields/idField.ts';
import RelationToManyField from 'modules/shared/fields/relationToManyField.ts';
import StringField from 'modules/shared/fields/stringField.ts';

function label(name) {
  return i18n(`entities.callToAction.fields.${name}`);
}

function enumeratorLabel(name, value) {
  return i18n(`entities.callToAction.enumerators.${name}.${value}`);
}

const fields = {
  id: new IdField('id', label('id')),
  type: new EnumeratorField(
    'type',
    label('type'),
    [
      { id: 'phone', label: enumeratorLabel('type', 'phone') },
      { id: 'sms', label: enumeratorLabel('type', 'sms') },
      { id: 'url', label: enumeratorLabel('type', 'url') },
      { id: 'qrc', label: enumeratorLabel('type', 'qrc') },
    ],
    {
      required: true,
    },
  ),
  label: new StringField('label', label('label'), {}),
  details: new StringField('details', label('details'), {}),
  url: new StringField('url', label('url'), {}),
  action: new StringField('action', label('action'), {}),
  tags: new RelationToManyField('tags', label('tags'), {}),
  //  AnalyticsEvents: new RelationToManyField('analyticsEvents', label('analyticsEvents'), {}),
  createdAt: new DateTimeField('createdAt', label('createdAt')),
  updatedAt: new DateTimeField('updatedAt', label('updatedAt')),
  createdAtRange: new DateTimeRangeField('createdAtRange', label('createdAtRange')),
};

export default {
  fields,
};
