import authAxios from '../../modules/shared/axios/authAxios';

const PATH = 'inventoryType';

export default class InventoryTypeService {
  static async list(filter, orderBy, limit, offset) {
    // POR-1601, POR-955
    limit = 500;
    const params = {
        filter,
        orderBy,
        limit,
        offset,
      },
      response = await authAxios.get(`/${PATH}`, {
        params,
      });

    return response.data;
  }

  static async listAutocomplete(query, limit) {
    // POR-1601, POR-955
    limit = 500;
    const params = {
        query,
        limit,
      },
      response = await authAxios.get(`/${PATH}/autocomplete`, {
        params,
      });

    return response.data;
  }
}
