import { i18n } from 'i18n';
import DateTimeField from 'modules/shared/fields/dateTimeField.ts';
import DateTimeRangeField from 'modules/shared/fields/dateTimeRangeField.ts';
import EnumeratorField from 'modules/shared/fields/enumeratorField.ts';
import IdField from 'modules/shared/fields/idField.ts';
import StringField from 'modules/shared/fields/stringField.ts';

function label(name) {
  return i18n(`entities.tag.fields.${name}`);
}

function enumeratorLabel(name, value) {
  return i18n(`entities.tag.enumerators.${name}.${value}`);
}

const fields = {
  id: new IdField('id', label('id')),
  name: new StringField('name', label('name'), {
    required: true,
    min: 2,
  }),
  label: new StringField('label', label('label'), {}),
  type: new EnumeratorField(
    'type',
    label('type'),
    [
      { id: 'general', label: enumeratorLabel('type', 'general') },
      { id: 'restricted', label: enumeratorLabel('type', 'restricted') },
      { id: 'internal', label: enumeratorLabel('type', 'internal') },
      { id: 'content', label: enumeratorLabel('type', 'content') },
      { id: 'customer', label: enumeratorLabel('type', 'customer') },
      { id: 'device', label: enumeratorLabel('type', 'device') },
      { id: 'stream', label: enumeratorLabel('type', 'stream') },
      { id: 'partner', label: enumeratorLabel('type', 'partner') },
    ],
    {},
  ),
  createdAt: new DateTimeField('createdAt', label('createdAt')),
  updatedAt: new DateTimeField('updatedAt', label('updatedAt')),
  createdAtRange: new DateTimeRangeField('createdAtRange', label('createdAtRange')),
};

export default {
  fields,
};
