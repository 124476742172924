import { i18n } from 'i18n';
import BooleanField from 'modules/shared/fields/booleanField.ts';
import DateTimeField from 'modules/shared/fields/dateTimeField.ts';
import DateTimeRangeField from 'modules/shared/fields/dateTimeRangeField.ts';
import IdField from 'modules/shared/fields/idField.ts';
import RelationToManyField from 'modules/shared/fields/relationToManyField.ts';
import StringField from 'modules/shared/fields/stringField.ts';

function label(name) {
  return i18n(`entities.channel.fields.${name}`);
}

const fields = {
  id: new IdField('id', label('id')),
  name: new StringField('name', label('name'), {
    required: true,
  }),
  slug: new StringField('slug', label('slug'), {
    required: true,
    min: 1,
  }),
  url: new StringField('url', label('url'), {}),
  socketStream: new StringField('socketStream', label('socketStream'), {}),
  devices: new RelationToManyField('devices', label('devices'), {}),
  content: new RelationToManyField('content', label('content'), {}),
  marketplaceListing: new RelationToManyField(
    'marketplaceListing',
    label('marketplaceListing'),
    {},
  ),
  //  AnalyticsEvents: new RelationToManyField('analyticsEvents', label('analyticsEvents'), {}),
  from: new DateTimeField('from', label('from'), {}),
  to: new DateTimeField('to', label('to'), {}),
  type: new StringField('type', label('type'), {}),
  active: new BooleanField('active', label('active')),
  tags: new RelationToManyField('tags', label('tags'), {}),
  createdAt: new DateTimeField('createdAt', label('createdAt')),
  updatedAt: new DateTimeField('updatedAt', label('updatedAt')),
  createdAtRange: new DateTimeRangeField('createdAtRange', label('createdAtRange')),
  fromRange: new DateTimeRangeField('fromRange', label('fromRange')),
  toRange: new DateTimeRangeField('toRange', label('toRange')),
};

export default {
  fields,
};
