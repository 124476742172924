import PermissionChecker from 'modules/auth/permissionChecker';
import { FC, ReactNode } from 'react';
import { Redirect, Route } from 'react-router-dom';
import RouteLayout from './RouteLayout';

interface EmptyPermissionsRouteProps extends RouteComponentProps {
  component: ReactNode;
  currentUser: unknown;
}

const EmptyPermissionsRoute: FC<EmptyPermissionsRouteProps> = ({
  component: Component,
  currentUser,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        const permissionChecker = new PermissionChecker(currentUser);

        if (!permissionChecker.isAuthenticated) {
          return (
            <Redirect
              to={{
                pathname: '/auth/signin',
              }}
            />
          );
        }

        if (!permissionChecker.isEmptyPermissions) {
          return <Redirect to="/" />;
        }

        return <RouteLayout {...props} component={Component} hideBarsDefault={true} />;
      }}
    />
  );
};

export default EmptyPermissionsRoute;
