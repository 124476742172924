import { i18n } from 'i18n';
import CustomerAudienceService from 'modules/customerAudience/customerAudienceService';
import fields from 'modules/customerAudience/importer/customerAudienceImporterFields';
import selectors from 'modules/customerAudience/importer/customerAudienceImporterSelectors';
import importerActions from 'modules/shared/importer/importerActions';

export default importerActions(
  'CUSTOMERAUDIENCE_IMPORTER',
  selectors,
  CustomerAudienceService.import,
  fields,
  i18n('entities.customerAudience.importer.fileName'),
);
