import destroy from 'modules/deviceNetwork/destroy/deviceNetworkDestroyReducers';
import form from 'modules/deviceNetwork/form/deviceNetworkFormReducers';
import importerReducer from 'modules/deviceNetwork/importer/deviceNetworkImporterReducers';
import list from 'modules/deviceNetwork/list/deviceNetworkListReducers';
import view from 'modules/deviceNetwork/view/deviceNetworkViewReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
});
