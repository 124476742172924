import { i18n } from 'i18n';
import DeviceNetworkService from 'modules/deviceNetwork/deviceNetworkService';
import fields from 'modules/deviceNetwork/importer/deviceNetworkImporterFields';
import selectors from 'modules/deviceNetwork/importer/deviceNetworkImporterSelectors';
import importerActions from 'modules/shared/importer/importerActions';

export default importerActions(
  'DEVICENETWORK_IMPORTER',
  selectors,
  DeviceNetworkService.import,
  fields,
  i18n('entities.deviceNetwork.importer.fileName'),
);
