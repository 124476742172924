import { i18n } from 'i18n';
import fields from 'modules/storefront/importer/storefrontImporterFields';
import selectors from 'modules/storefront/importer/storefrontImporterSelectors';
import StorefrontService from 'modules/storefront/storefrontService';

import importerActions from '../../../modules/shared/importer/importerActions';

export default importerActions(
  'STOREFRONT_IMPORTER',
  selectors,
  StorefrontService.import,
  fields,
  i18n('entities.storefront.importer.fileName'),
);
