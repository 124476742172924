import { ThunkAction } from 'redux-thunk';
import { RootState } from '~/types/models/rootState';
import { BasicAction, BasicErrorAction } from '../../common';
import { Campaign } from '~/types/models/campaign';

const path = `CAMPAIGN_FORM`;

// String action labels
export const FormActionTypes = {
  INIT: `${path}_INIT`,
  CREATE_START: `${path}_CREATE_START`,
  CREATE_SUCCESS: `${path}_CREATE_SUCCESS`,
  CREATE_ERROR: `${path}_CREATE_ERROR`,
  UPDATE_START: `${path}_UPDATE_START`,
  UPDATE_SUCCESS: `${path}_UPDATE_SUCCESS`,
  UPDATE_ERROR: `${path}_UPDATE_ERROR`,
  RESET_ERROR_STATE: `${path}_RESET_ERROR_STATE`,
} as const;

type FormActionTypeLiterals = typeof FormActionTypes;

type BasicCampaignFormActions =
  | BasicAction<FormActionTypeLiterals, Partial<Campaign>>
  | BasicErrorAction<FormActionTypeLiterals, unknown>;

export interface CampaignFormActions {
  doInit: () => ThunkAction<void, RootState, unknown, BasicCampaignFormActions>;
  doNew: (data: Campaign) => ThunkAction<void, RootState, unknown, BasicCampaignFormActions>;
  doUpdate: (
    id: string,
    data: Partial<Campaign>,
  ) => ThunkAction<void, RootState, unknown, BasicCampaignFormActions>; // TODO Create types for single actions better
  doResetError: () => ThunkAction<void, RootState, unknown, BasicCampaignFormActions>;
}
