import { postcodeValidator } from 'postcode-validator';
import yup from 'yup';

import country_data from '../../constants/contry_data';
import { i18n } from '../../i18n';
import {
  BooleanField,
  DateTimeField,
  DecimalField,
  EnumeratorField,
  IdField,
  IntegerField,
  RelationToManyField,
  RelationToOneField,
  StringField,
} from '../../modules/shared/fields';
import { latitudeModelOptions, longitudeModelOptions } from '~/utils/validators';

function label(name: string): string {
  return i18n(`entities.storefront.fields.${name}`);
}

function availabilityLabel(name: string): string {
  return i18n(`entities.availability.fields.${name}`);
}

const availabilityFields = {
  id: new IdField('id', availabilityLabel('id')),
  price: new IntegerField('price', availabilityLabel('price'), {
    required: false,
    decimals: 2,
    allowNegative: false,
    valuePrefix: '$',
    dependentFieldSettings: [
      {
        available: (available: number, schema: yup.NumberSchema) =>
          available ? schema.min(0.01) : schema,
      },
    ],
  }),
  available: new BooleanField('available', availabilityLabel('available'), {
    required: false,
  }),
  date: new DateTimeField('date', availabilityLabel('date'), {
    required: false,
  }),
  updatedAt: new DateTimeField('updatedAt', availabilityLabel('updatedAt'), {
    required: false,
  }),
  purchased: new BooleanField('purchased', availabilityLabel('purchased'), {
    required: false,
    yesLabel: availabilityLabel('common.yes'),
    noLabel: availabilityLabel('common.no'),
  }),
};
const fields = {
  id: new IdField('id', label('id')),
  name: new StringField('name', label('name'), {
    required: true,
  }),
  unitNumber: new StringField('unitNumber', label('unitNumber'), {
    type: 'string',
  }),
  geopathId: new StringField('geopathId', label('geopathId'), {
    type: 'string',
  }),
  description: new StringField('description', label('description'), {
    type: 'string',
  }),
  physicalAddress: new StringField('physicalAddress', label('physicalAddress'), {
    type: 'string',
  }),
  city: new StringField('city', label('city'), {
    type: 'string',
  }),
  state: new StringField('state', label('state'), {
    type: 'string',
  }),
  zip: new StringField('zip', label('zip'), {
    type: 'string',
    // Tests zip within spec validator, once negative throws an error
    tester(zip: string) {
      let isValid = true;
      if (!zip) {
        return isValid;
      }

      const {
        parent: { country },
      } = this;
      if (!country) {
        return isValid;
      }
      isValid = postcodeValidator(zip, country);

      return isValid;
    },
    errorMessage: 'Zip is not correct for current country!',
  }),
  country: new EnumeratorField(
    'country',
    label('country'),
    country_data.map((country) => ({
      id: country.ISO,
      label: country.Country,
    })),
    {
      dependentFieldSettings: [
        {
          zip: (zip: string, schema: yup.StringSchema) =>
            zip ? schema.required('Please, define country too!') : schema,
        },
      ],
    },
  ),
  latitude: new StringField('latitude', 'Latitude', latitudeModelOptions),
  longitude: new StringField('longitude', 'Longitude', longitudeModelOptions),
  inventoryTypes: new RelationToManyField('inventoryTypes', label('inventoryTypes'), {
    delimiter: ',',
    property: 'name',
  }),
  facing: new EnumeratorField(
    'facing',
    label('facing.label'),
    [
      {
        id: 'N',
        label: label('facing.N'),
        literalValues: ['n', 'north'],
      },
      {
        id: 'NW',
        label: label('facing.NW'),
        literalValues: ['nw', 'northwest'],
      },
      {
        id: 'W',
        label: label('facing.W'),
        literalValues: ['w', 'west'],
      },
      {
        id: 'SW',
        label: label('facing.SW'),
        literalValues: ['sw', 'southwest'],
      },
      {
        id: 'S',
        label: label('facing.S'),
        literalValues: ['s', 'south'],
      },
      {
        id: 'SE',
        label: label('facing.SE'),
        literalValues: ['se', 'southeast'],
      },
      {
        id: 'E',
        label: label('facing.E'),
        literalValues: ['e', 'east'],
      },
      {
        id: 'NE',
        label: label('facing.NE'),
        literalValues: ['ne', 'northeast'],
      },
    ],
    {
      required: false,
    },
  ),
  position: new EnumeratorField(
    'position',
    label('position.label'),
    [
      {
        id: 'lhr',
        label: label('position.lhr'),
        literalValues: ['lhr', 'left', 'l'],
      },
      {
        id: 'rhr',
        label: label('position.rhr'),
        literalValues: ['rhr', 'right', 'r'],
      },
    ],
    {
      required: false,
    },
  ),
  weeklyAdultImpressionsCount: new IntegerField(
    'weeklyAdultImpressionsCount',
    label('weeklyAdultImpressionsCount'),
    {
      allowNegative: false,
    },
  ),
  uniqueAdultAdReachedCount: new IntegerField(
    'uniqueAdultAdReachedCount',
    label('uniqueAdultAdReachedCount'),
    {
      allowNegative: false,
    },
  ),
  advertisersCount: new IntegerField('advertisersCount', label('advertisersCount'), {
    allowNegative: false,
  }),
  grp: new IntegerField('grp', label('grp'), {
    allowNegative: false,
    decimals: 2,
  }),
  spotLength: new IntegerField('spotLength', label('spotLength'), {
    valueSuffix: ' sec',
    allowNegative: false,
  }),
  loopLength: new IntegerField('loopLength', label('loopLength'), {
    valueSuffix: ' sec',
    allowNegative: false,
  }),
  averageAdultFrequencyReach: new IntegerField(
    'averageAdultFrequencyReach',
    label('averageAdultFrequencyReach'),
    {
      allowNegative: false,
      decimals: 2,
    },
  ),
  sov: new IntegerField('sov', label('sov'), {
    allowNegative: false,
    decimals: 2,
    min: 0,
    max: 100,
    matches: new RegExp('^([0-9]{1,2}){1}(.[0-9]{1,2})?$'),
  }),
  fourWeekRateCardCost: new IntegerField('fourWeekRateCardCost', label('fourWeekRateCardCost'), {
    allowNegative: false,
    decimals: 2,
  }),
  fourWeekRateNegotiatedCost: new IntegerField(
    'fourWeekRateNegotiatedCost',
    label('fourWeekRateNegotiatedCost'),
    {
      allowNegative: false,
      decimals: 2,
    },
  ),
  productionCost: new IntegerField('productionCost', label('productionCost'), {
    allowNegative: false,
    decimals: 2,
  }),
  installCost: new IntegerField('installCost', label('installCost'), {
    allowNegative: false,
    decimals: 2,
  }),
  vinylSize: new StringField('vinylSize', label('vinylSize'), {
    /*
     * RegExp for panel size string [num]*[num] zerosafe
     * matches: /([1-9]\d*)\*([1-9]\d*)/g,
     * errorMessage: `Please define "${label('vinylSize')}" as [width]*[height]`,
     */
  }),
  panelSize: new StringField('panelSize', label('panelSize'), {
    /*
     * RegExp for panel size string [num]*[num] zerosafe
     * matches: /([1-9]\d*)\*([1-9]\d*)/g,
     * errorMessage: `Please define "${label('panelSize')}" as [width]*[height]`,
     */
  }),
  shippingAddress: new StringField('shippingAddress', label('shippingAddress'), {}),
  illuminated: new BooleanField('illuminated', label('illuminated.label'), {
    noLabel: i18n('illuminated.no'),
    yesLabel: i18n('illuminated.yes'),
    required: false,
  }),
  staticImageAd: new BooleanField('staticImageAd', label('staticImageAd.label'), {
    noLabel: i18n('staticImageAd.no'),
    yesLabel: i18n('staticImageAd.yes'),
    required: false,
  }),
  motionGraphicAd: new BooleanField('motionGraphicAd', label('motionGraphicAd.label'), {
    noLabel: i18n('motionGraphicAd.no'),
    yesLabel: i18n('motionGraphicAd.yes'),
    required: false,
  }),
  audioAd: new BooleanField('audioAd', label('audioAd.label'), {
    noLabel: i18n('audioAd.no'),
    yesLabel: i18n('audioAd.yes'),
    required: false,
  }),
  dimensions: new StringField('dimensions', 'Dimensions', {}),
  lengthLimit: new IntegerField('lengthLimit', label('lengthLimit'), {
    allowNegative: false,
    decimals: 2,
  }),
  fileSizeLimitMB: new IntegerField('fileSizeLimitMB', label('fileSizeLimitMB'), {
    allowNegative: false,
    decimals: 2,
  }),
  categoryRestrictions: new StringField('categoryRestrictions', label('categoryRestrictions'), {}),
  notes: new StringField('notes', 'Additional Notes', {}),

  url: new StringField('url', 'URL', {}),
  test: new StringField('test', label('test'), {}),
  hoo: new StringField('hoo', label('hoo'), {}),
  timezone: new StringField('timezone', label('timezone'), {}),
  timezoneName: new StringField('timezoneName', label('timezoneName'), {}),
  content: new RelationToManyField('content', label('content'), {}),
  customerProfiles: new RelationToManyField('customerProfiles', label('customerProfiles'), {}),
  device: new RelationToManyField('device', label('device'), {}),
  deviceSlug: new RelationToManyField('deviceSlug', 'Device Slug', {}),
  currentBalance: new DecimalField('currentBalance', 'Current Balance', {}),
  totalRevenue: new DecimalField('totalRevenue', 'Total Revenue', {}),
  tags: new RelationToManyField('tags', label('tags'), {}),
  users: new RelationToManyField('users', label('users'), {}),
  owner: new RelationToOneField('owner', label('owner'), {}),
  techsupport: new RelationToManyField('techsupport', label('techsupport'), {}),
  organizationType: new RelationToOneField('organizationType', label('organizationType'), {}),
  organizationCategory: new RelationToManyField(
    'organizationCategory',
    label('organizationCategory'),
    {},
  ),
  transactions: new RelationToManyField('transactions', label('transactions'), {}),
  status: new RelationToOneField('status', label('status'), {}),
  //  AnalyticsEvents: new RelationToManyField('analyticsEvents', label('analyticsEvents'), {}),
  organizationSector: new RelationToOneField('organizationSector', label('organizationSector'), {}),
  partnerCPMAsk: new StringField('partnerCPMAsk', 'CPM', {}),
  partnerVenueType: new StringField('partnerVenueType', 'Type', {}),
  gmapsUserRatingsTotal: new StringField('gmapsUserRatingsTotal', 'Rating Count', {}),
  gmapsRating: new StringField('gmapsRating', 'Rating Level', {}),
  gmapsPriceLevel: new StringField('gmapsPriceLevel', 'Price Level', {}),
  availability: new RelationToManyField('availability', label('availability'), {
    relatedModel: availabilityFields,
  }),
  nextAvailableDate: new StringField('nextAvailableDate', label('nextAvailableDate'), {}),
  createdAt: new DateTimeField('createdAt', label('createdAt')),
  updatedAt: new DateTimeField('updatedAt', label('updatedAt')),
};

export { fields };
