import destroy from 'modules/channel/destroy/channelDestroyReducers';
import form from 'modules/channel/form/channelFormReducers';
import importerReducer from 'modules/channel/importer/channelImporterReducers';
import list from 'modules/channel/list/channelListReducers';
import view from 'modules/channel/view/channelViewReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
});
