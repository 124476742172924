import { i18n } from 'i18n';
import PermissionService from 'modules/permission/permissionService';
import Errors from 'modules/shared/error/errors';
import Message from '~/view/shared/message';

const prefix = 'PERMISSION_FORM',
  actions = {
    RESET: `${prefix}_RESET`,

    FIND_ROLE_STARTED: `${prefix}_FIND_ROLE_STARTED`,
    FIND_ROLE_SUCCESS: `${prefix}_FIND_ROLE_SUCCESS`,
    FIND_ROLE_ERROR: `${prefix}_FIND_ROLE_ERROR`,
    FIND_GROUP_STARTED: `${prefix}_FIND_GROUP_STARTED`,
    FIND_GROUP_SUCCESS: `${prefix}_FIND_GROUP_SUCCESS`,
    FIND_GROUP_ERROR: `${prefix}_FIND_GROUP_ERROR`,

    CREATE_ROLE_STARTED: `${prefix}_CREATE_ROLE_STARTED`,
    CREATE_ROLE_SUCCESS: `${prefix}_CREATE_ROLE_SUCCESS`,
    CREATE_ROLE_ERROR: `${prefix}_CREATE_ROLE_ERROR`,
    CREATE_GROUP_STARTED: `${prefix}_CREATE_GROUP_STARTED`,
    CREATE_GROUP_SUCCESS: `${prefix}_CREATE_GROUP_STARTED`,
    CREATE_GROUP_ERROR: `${prefix}_CREATE_GROUP_STARTED`,

    UPDATE_ROLE_STARTED: `${prefix}_UPDATE_ROLE_STARTED`,
    UPDATE_ROLE_SUCCESS: `${prefix}_UPDATE_ROLE_SUCCESS`,
    UPDATE_ROLE_ERROR: `${prefix}_UPDATE_ROLE_ERROR`,
    UPDATE_GROUP_STARTED: `${prefix}_UPDATE_GROUP_STARTED`,
    UPDATE_GROUP_SUCCESS: `${prefix}_UPDATE_GROUP_SUCCESS`,
    UPDATE_GROUP_ERROR: `${prefix}_UPDATE_GROUP_ERROR`,

    doNew: () => ({
      type: actions.RESET,
    }),

    doFindGroup: (id) => async (dispatch) => {
      try {
        dispatch({
          type: actions.FIND_GROUP_STARTED,
        });

        const record = await PermissionService.find(id);

        dispatch({
          type: actions.FIND_GROUP_SUCCESS,
          payload: record,
        });
      } catch (error) {
        Errors.handle(error);

        dispatch({
          type: actions.FIND_GROUP_ERROR,
        });
      }
    },

    doFindRole: (id) => async (dispatch) => {
      try {
        dispatch({
          type: actions.FIND_ROLE_STARTED,
        });

        const record = await PermissionService.find(id);

        dispatch({
          type: actions.FIND_ROLE_SUCCESS,
          payload: record,
        });
      } catch (error) {
        Errors.handle(error);

        dispatch({
          type: actions.FIND_ROLE_ERROR,
        });
      }
    },

    doCreateRole: (values) => async (dispatch) => {
      try {
        dispatch({
          type: actions.CREATE_ROLE_STARTED,
        });

        await PermissionService.create(values);

        dispatch({
          type: actions.CREATE_ROLE_SUCCESS,
        });

        Message.success(i18n('entities.permission.create.success'));
      } catch (error) {
        Errors.handle(error);

        dispatch({
          type: actions.CREATE_ROLE_ERROR,
        });
      }
    },

    doCreateGroup: (values) => async (dispatch) => {
      try {
        dispatch({
          type: actions.CREATE_GROUP_STARTED,
        });

        await PermissionService.create(values);

        dispatch({
          type: actions.CREATE_GROUP_SUCCESS,
        });

        Message.success(i18n('entities.permission.create.success'));
      } catch (error) {
        Errors.handle(error);

        dispatch({
          type: actions.CREATE_GROUP_ERROR,
        });
      }
    },

    doUpdateRole: (id, values) => async (dispatch, getState) => {
      try {
        dispatch({
          type: actions.UPDATE_ROLE_STARTED,
        });

        await PermissionService.update(id, values);

        dispatch({
          type: actions.UPDATE_ROLE_SUCCESS,
        });

        Message.success(i18n('entities.permission.update.success'));
      } catch (error) {
        Errors.handle(error);

        dispatch({
          type: actions.UPDATE_ROLE_ERROR,
        });
      }
    },

    doUpdateGroup: (id, values) => async (dispatch, getState) => {
      try {
        dispatch({
          type: actions.UPDATE_GROUP_STARTED,
        });

        await PermissionService.update(id, values);

        dispatch({
          type: actions.UPDATE_GROUP_SUCCESS,
        });

        Message.success(i18n('entities.permission.update.success'));
      } catch (error) {
        Errors.handle(error);

        dispatch({
          type: actions.UPDATE_GROUP_ERROR,
        });
      }
    },
  };

export default actions;
