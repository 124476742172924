import { AlertContextProps, AlertToast } from './AlertContainer';

export default class Alert {
  private static _ctx: AlertContextProps;

  static registerContext(ctx: AlertContextProps) {
    this._ctx = ctx;
  }

  static info(alert: AlertToast) {
    this._ctx.addAlert({ ...alert, severity: 'info' });
  }

  static success(alert: AlertToast) {
    this._ctx.addAlert({ ...alert, severity: 'success' });
  }

  static warning(alert: AlertToast) {
    this._ctx.addAlert({ ...alert, severity: 'warning' });
  }

  static error(alert: AlertToast) {
    this._ctx.addAlert({ ...alert, severity: 'error' });
  }

  static clearAlerts() {
    this._ctx.clearAlerts();
  }
}
