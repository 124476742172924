import authAxios from 'modules/shared/axios/authAxios';

export default class ContentService {
  static async update(id, data) {
    const body = {
        id,
        data,
      },
      response = await authAxios.put(`/content/${id}`, body);

    return response.data;
  }

  static async destroyAll(ids) {
    const params = {
        ids,
      },
      response = await authAxios.delete('/content', {
        params,
      });

    return response.data;
  }

  static async create(data) {
    const body = {
        data,
      },
      response = await authAxios.post('/content', body);

    return response.data;
  }

  static async import(values, importHash) {
    const body = {
        data: values,
        importHash,
      },
      response = await authAxios.post('/content/import', body);

    return response.data;
  }

  static async find(id) {
    const response = await authAxios.get(`/content/${id}`);
    return response.data;
  }

  static async list(filter, orderBy, limit, offset) {
    const params = {
        filter,
        orderBy,
        limit,
        offset,
      },
      response = await authAxios.get('/content', {
        params,
      });

    return response.data;
  }

  static async listAutocomplete(query, limit) {
    const params = {
        query,
        limit,
      },
      response = await authAxios.get('/content/autocomplete', {
        params,
      });

    return response.data;
  }

  static async getContentLengthStats(items) {
    const ids = [];
    for (const i in items) {
      ids.push(items[i].id);
    }

    const params = {
        ids,
      },
      response = await authAxios.post('/content/lengthStats', {
        params,
      });

    return response.data;
  }

  static async findAutocompleteIds(items) {
    const ids = [];
    for (const i in items) {
      ids.push(items[i].id);
    }

    const params = {
        ids,
      },
      response = await authAxios.post('/content/autocompleteids', {
        params,
      });

    return response.data;
  }

  static async getFilterList(query, limit) {
    const params = {
        query,
        limit,
      },
      response = await authAxios.get('/filters/content', {
        params,
      });

    return response.data;
  }
}
