import { i18n } from 'i18n';
import DateTimeField from 'modules/shared/fields/dateTimeField.ts';
import DateTimeRangeField from 'modules/shared/fields/dateTimeRangeField.ts';
import EnumeratorField from 'modules/shared/fields/enumeratorField.ts';
import IdField from 'modules/shared/fields/idField.ts';
import StringField from 'modules/shared/fields/stringField.ts';

function label(name) {
  return i18n(`entities.status.fields.${name}`);
}

function enumeratorLabel(name, value) {
  return i18n(`entities.status.enumerators.${name}.${value}`);
}

const fields = {
  id: new IdField('id', label('id')),
  name: new StringField('name', label('name'), {
    required: true,
  }),
  value: new StringField('value', label('value'), {}),
  role: new EnumeratorField(
    'role',
    label('role'),
    [
      {
        id: 'Role1',
        label: enumeratorLabel('role', 'Role1'),
      },
      {
        id: 'Role2',
        label: enumeratorLabel('role', 'Role2'),
      },
      {
        id: 'Role3Role3',
        label: enumeratorLabel('role', 'Role3Role3'),
      },
    ],
    {},
  ),
  createdAt: new DateTimeField('createdAt', label('createdAt')),
  updatedAt: new DateTimeField('updatedAt', label('updatedAt')),
  createdAtRange: new DateTimeRangeField('createdAtRange', label('createdAtRange')),
};

export default {
  fields,
};
