import App from 'App';
import { i18n } from 'i18n';
import SettingsService from 'modules/settings/settingsService';
import AuthService from 'modules/auth/authService';
import ReactDOM from 'react-dom';
import Observable from './utils/Observable';

// use this namespace to organize "global" variables rather than cluttering up window object
window.ONESCREEN = {
  dispatch: new Observable(), // replayable global communication
};

(async function () {
  await AuthService.startRefreshTokenInterval();
  document.title = i18n('app.title');
  const settings = await SettingsService.initialFetch();

  ReactDOM.render(<App settings={settings} />, document.getElementById('root'));

  /**
   * It may cause cache issues when developing, so, after your app is ready,
   * change those lines
   */
  /*
   * Unregister();
   * RegisterServiceWorker();
   */
})();
