import { i18n } from 'i18n';
import BooleanField from 'modules/shared/fields/booleanField.ts';
import DateTimeField from 'modules/shared/fields/dateTimeField.ts';
import DateTimeRangeField from 'modules/shared/fields/dateTimeRangeField.ts';
import FilesField from 'modules/shared/fields/filesField.ts';
import IdField from 'modules/shared/fields/idField.ts';
import RelationToManyField from 'modules/shared/fields/relationToManyField.ts';
import RelationToOneField from 'modules/shared/fields/relationToOneField.ts';
import StringField from 'modules/shared/fields/stringField.ts';

function label(name) {
  return i18n(`entities.content.fields.${name}`);
}

const fields = {
  id: new IdField('id', label('id')),
  name: new StringField('name', label('name'), {
    required: true,
    min: 2,
    max: 255,
  }),
  description: new StringField('description', label('description'), {
    max: 21845,
  }),
  media: new FilesField('media', label('media'), 'content/media', {
    required: false,
  }),
  organizationOwner: new RelationToOneField('organizationOwner', label('organizationOwner')),
  cta: new RelationToManyField('cta', label('cta'), {}),
  category: new RelationToOneField('category', label('category'), {
    required: true,
  }),
  mediaUrl: new StringField('mediaUrl', label('mediaUrl'), { required: true }),
  mediaJSON: new StringField('mediaJSON', label('mediaJSON'), {}),
  private: new BooleanField('private', label('private')),
  isAd: new BooleanField('isAd', label('isAd')),
  status: new RelationToOneField('status', label('status')),
  createdAt: new DateTimeField('createdAt', label('createdAt')),
  updatedAt: new DateTimeField('updatedAt', label('updatedAt')),
  createdAtRange: new DateTimeRangeField('createdAtRange', label('createdAtRange')),
};

export default {
  fields,
};
