import model from 'modules/content/contentModel';

const { fields } = model;

export default [
  fields.name,
  fields.description,
  fields.media,
  fields.tags,
  fields.organizationOwner,
  fields.cta,
  fields.transactions,
  fields.private,
  fields.status,
];
