import authAxios from 'modules/shared/axios/authAxios';

export default class PermissionService {
  static async update(id, data) {
    const body = {
        id,
        data,
      },
      response = await authAxios.put(`/permissions/group/${id}`, body);

    return response.data;
  }

  static async destroyAll(Ids, groupNameId) {
    const params = {
        Ids,
        groupNameId,
      },
      response = await authAxios.delete('/permissions/group', {
        params,
      });

    return response.data;
  }

  static async create(data) {
    const body = {
        data,
      },
      response = await authAxios.post('/permissions/group', body);

    return response.data;
  }

  static async findAllRoles() {
    const response = await authAxios.get('/permissions/autocomplete');
    return response.data;
  }

  static async find(id) {
    const response = await authAxios.get(`/permissions/group/${id}`);
    return response.data;
  }

  static async list(filter, orderBy, limit, offset) {
    const params = {
        filter,
        orderBy,
        limit,
        offset,
      },
      response = await authAxios.get('/permissions/group', {
        params,
      });

    return response.data;
  }
}
