import * as yup from 'yup';

import GenericField from '../../../modules/shared/fields/genericField';
import { DependentFieldSettings, Options } from './fieldTypes';

export default class StringField extends GenericField {
  required: boolean;

  matches?: RegExp;

  min?: number;

  max?: number;

  type?: any;

  prefix?: any;

  tester?(value: string | null | undefined): boolean;

  errorMessage?: string;

  dependentFieldSettings?: DependentFieldSettings;

  constructor(
    name: string,
    label: string,
    {
      required = false,
      min,
      max,
      matches,
      tester,
      type,
      errorMessage = undefined,
      dependentFieldSettings,
    }: Options<any> = {},
  ) {
    super(name, label);

    this.required = required;
    this.matches = matches;
    this.min = min;
    this.max = max;
    this.type = type;
    this.tester = tester;
    this.errorMessage = errorMessage;
    this.dependentFieldSettings = dependentFieldSettings;
  }

  forView(value: any) {
    if (Array.isArray(value)) {
      return value.join(', ');
    }

    return value;
  }

  forFormInitialValue(value: any) {
    return value;
  }

  forInput() {
    const inputProps: { type: any; [x: string]: any } = {
      type: this.type,
    };
    if (this.prefix !== undefined) {
      inputProps.prefix = this.prefix;
    }
    return inputProps;
  }

  forForm() {
    let yupChain = yup.string().nullable(true).trim().label(this.label);

    if (this.required) {
      yupChain = yupChain.required();
    }

    if (this.min || this.min === 0) {
      yupChain = yupChain.min(this.min);
    }

    if (this.max || this.max === 0) {
      yupChain = yupChain.max(this.max);
    }

    if (this.errorMessage) {
      if (this.matches) {
        yupChain = yupChain.matches(this.matches, this.errorMessage);
      }

      if (this.tester) {
        const fn = this.tester; // TIP: Reassigning function to another var lets compiler to check the type validity in runtime
        yupChain = yupChain.test(this.name, this.errorMessage, fn);
      }
    }

    if (this.dependentFieldSettings) {
      for (const dependentFieldSetting of this.dependentFieldSettings) {
        const [fieldName, settings] = Object.entries(dependentFieldSetting)[0];
        yupChain = yupChain.when(fieldName, settings);
      }
    }

    return yupChain;
  }

  forFilter() {
    return yup.string().nullable(true).trim().label(this.label);
  }

  forExport() {
    return yup.mixed().label(this.label);
  }

  forImport() {
    let yupChain = yup.string().nullable(true).trim().label(this.label);

    if (this.required) {
      yupChain = yupChain.required();
    }

    if (this.min || this.min === 0) {
      yupChain = yupChain.min(this.min);
    }

    if (this.max) {
      yupChain = yupChain.max(this.max);
    }

    if (this.matches) {
      yupChain = yupChain.matches(this.matches, this.errorMessage);
    }

    return yupChain;
  }

  literalMapToValue: undefined;
}
