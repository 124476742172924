import authAxios from 'modules/shared/axios/authAxios';

export default class ProfileService {
  static async update(id, data) {
    const body = {
        id,
        data,
      },
      response = await authAxios.put(`/notification/setup/${id}`, body);

    return response.data;
  }

  static async changePassword(data) {
    const body = {
        data,
      },
      response = await authAxios.post('/auth/change-password', body);

    return response.data;
  }

  static async find() {
    const response = await authAxios.get('/notification/setup');

    return response.data;
  }
}
