import { i18n } from 'i18n';
import service from 'modules/ooh/oohService';
import { baseRouts } from 'view/routes';

import { getFormActions } from '../../../modules/shared/actions';

export default getFormActions({
  service,
  path: baseRouts.ooh,
  updateSuccessMessage: i18n('entities.ooh.update.success'),
});
