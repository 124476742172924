import { useCurrentUser } from './useCurrentUser';
import { useIsPartner } from './useIsPartner';
import { useIsSuperAdmin } from './useIsSuperAdmin';

const useHasAccessToPartner = (partnerId, allowSuperAdmin = true) => {
  const currentUser = useCurrentUser();
  const isPartner = useIsPartner();
  const isSuperAdmin = useIsSuperAdmin();
  const userHasAccess = currentUser?.partnerIds?.includes(partnerId);
  return (isPartner && userHasAccess) || (allowSuperAdmin && isSuperAdmin);
};

export { useHasAccessToPartner };
