import { getHistory } from '~/modules/store';
import { RFPCartItemsActions } from '~/types/modules/cart/rfp/actions';

const path = 'rfpCartItems';

const actions: RFPCartItemsActions = {
  CREATE_CAMPAIGN_INVENTORY: `${path}_CREATE_CAMPAIGN_INVENTORY`,
  doCreateCampaignInventory: (ids: string[]) => (dispatch) => {
    dispatch({
      type: actions.CREATE_CAMPAIGN_INVENTORY,
      payload: ids,
    });
    getHistory().push(`/cart/campaign`);
  },
};

export default actions;
