import withStyles from '@mui/styles/withStyles';
import React, { Component } from 'react';
import NavBar from 'view/layout/NavBar';
import TopBar from 'view/layout/TopBar';

const styles = (theme) => ({
  root: {
    color: 'rgba(0, 0, 0, 0.65)',
    backgroundColor: '#f0f2f5',
    display: 'flex',
    fontFamily: 'proxima-nova, sans-serif',
    '& h1, h2, h3, h4, h5, h6': {
      color: 'rgba(0, 0, 0, 0.85)',
    },
  },
  container: {
    minHeight: '100vh',
    display: 'flex',
    '@media all and (-ms-high-contrast:none)': {
      height: 0, // IE11 fix
    },
  },
  content: {
    paddingTop: 64, // Todo maybe change to 64 when clarify with notifications height
    flexGrow: 1,
    maxWidth: '100%',
    // OverflowX: 'hidden',
    [theme.breakpoints.up('lg')]: {
      maxWidth: 'calc(100% - 256px)',
      paddingLeft: 256,
    },
    [theme.breakpoints.down('sm')]: {
      paddingTop: 64,
    },
    '@media print': {
      paddingTop: 0,
    },
  },
  contentEmbed: {
    paddingTop: 0, // Todo maybe change to 64 when clarify with notifications height
    flexGrow: 1,
    maxWidth: '100%',
    // OverflowX: 'hidden',
    [theme.breakpoints.up('lg')]: {
      paddingLeft: 0,
    },
    [theme.breakpoints.down('sm')]: {
      paddingTop: 0,
    },
  },
  contentFull: {
    paddingTop: 55, // Todo maybe change to 64 when clarify with notifications height
    flexGrow: 1,
    maxWidth: '100%',
    // OverflowX: 'hidden',
    [theme.breakpoints.up('lg')]: {
      paddingLeft: 0,
    },
    [theme.breakpoints.down('sm')]: {
      paddingTop: 56,
    },
  },
  toolbar: theme.mixins.toolbar,
});

class Layout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openNavBarMobile: false,
      hide: false,
    };

    /*
     * This.handleClick = this.handleClick.bind(this);
     * this.isOpen = this.isOpen.bind(this);
     */
  }

  doHide() {
    this.setState({ hide: true });
  }

  setOpenNavBarMobile(vall) {
    this.setState({ openNavBarMobile: vall });
  }

  isAnonymous() {
    return this.state.hide;
  }

  render() {
    // TODO eslint fix
    /* eslint-disable */
    const { classes, hideBarsDefault } = this.props;
    const { openNavBarMobile } = this.state;

    const hideBars =
      window &&
      window.location &&
      window.location.pathname != null &&
      window.location.pathname.indexOf('/embed/') !== -1
        ? true
        : false;

    return (
      <React.Fragment>
        {!hideBars || !hideBarsDefault ? (
          <>
            <TopBar
              onOpenNavBarMobile={() => this.setOpenNavBarMobile(true)}
              isAnonymous={() => this.isAnonymous()}
            />
            {!this.state.hide && (
              <NavBar
                onMobileClose={() => this.setOpenNavBarMobile(false)}
                openMobile={openNavBarMobile}
                doHide={() => this.doHide()}
              />
            )}
            <div className={classes.container}>
              <div className={!this.state.hide ? classes.content : classes.contentFull}>
                {this.props.children}
              </div>
            </div>
          </>
        ) : (
          <div className={classes.container}>
            <div className={classes.contentEmbed}>{this.props.children}</div>
          </div>
        )}
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(Layout);
