import { FC, ReactNode } from 'react';
import { RouteComponentProps, RouteProps } from 'react-router';
import {
  AlertContainer,
  WithAlertContext,
  withAlertContext,
} from '~/view/shared/Alert/AlertContainer';
import Layout from '~/view/layout/Layout';
import Alert from '../Alert/Alert';
import { useEffectOnce } from 'usehooks-ts';

interface RouteLayoutProps extends RouteProps, RouteComponentProps {
  hideBarsDefault: boolean;
  component: ReactNode;
}

const ContextRegistryComponent: FC<WithAlertContext> = ({ alertContext, children }) => {
  useEffectOnce(() => {
    Alert.registerContext(alertContext);
  });

  return children;
};

const RouteLayout: FC<RouteLayoutProps> = ({ component: Component, hideBarsDefault, ...rest }) => {
  const AlertContextRegistryComponent = withAlertContext(ContextRegistryComponent);

  return (
    <Layout {...rest} hideBarsDefault={hideBarsDefault}>
      <AlertContainer>
        <AlertContextRegistryComponent>
          <Component {...rest} />
        </AlertContextRegistryComponent>
      </AlertContainer>
    </Layout>
  );
};

export default RouteLayout;
