const getViewReducer = (actions) => {
  const initialData = {
      loading: false,
      record: null,
      error: null,
    },
    reducer = (state = initialData, { type, payload }) => {
      if (type === actions.RESET) {
        return {
          ...initialData,
        };
      }

      if (type === actions.FIND_STARTED) {
        return {
          ...state,
          record: null,
          loading: true,
          error: null,
        };
      }

      if (type === actions.FIND_SUCCESS) {
        return {
          ...state,
          record: payload,
          loading: false,
          error: null,
        };
      }

      if (type === actions.FIND_ERROR) {
        return {
          ...state,
          record: null,
          loading: false,
          error: payload,
        };
      }

      return state;
    };

  return reducer;
};

export default getViewReducer;
