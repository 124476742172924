import { DependentFieldSettings, ForFormInitialValueOptions } from './fieldTypes';

export default abstract class GenericField {
  readonly name: string;

  readonly label: string;

  fields?: { [x: string]: GenericField };

  dependentFieldSettings?: DependentFieldSettings;

  constructor(name: string, label: string) {
    this.name = name;
    this.label = label;
  }

  abstract forView?(x: any): any;

  abstract forFilter?(): any;

  abstract forForm?(parentNodeName?: string): any;

  abstract forFormInitialValue(x?: any, options?: ForFormInitialValueOptions): any;

  abstract forExport?(): any;

  abstract forImport?(): any;

  abstract forInput?(): any;

  abstract literalMapToValue?(literal: string): any;
}
