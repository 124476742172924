import { i18n } from 'i18n';
import authActions from 'modules/auth/authActions';
import authSelectors from 'modules/auth/authSelectors';
import Errors from 'modules/shared/error/errors';
import Message from '~/view/shared/message';

import companyUserService from '../companyUserService';

const prefix = 'COMPANY_USER_FORM',
  actions = {
    RESET: `${prefix}_RESET`,

    FIND_STARTED: `${prefix}_FIND_STARTED`,
    FIND_SUCCESS: `${prefix}_FIND_SUCCESS`,
    FIND_ERROR: `${prefix}_FIND_ERROR`,

    UPDATE_STARTED: `${prefix}_UPDATE_STARTED`,
    UPDATE_SUCCESS: `${prefix}_UPDATE_SUCCESS`,
    UPDATE_ERROR: `${prefix}_UPDATE_ERROR`,

    doFind:
      (id, token = null) =>
      async (dispatch) => {
        try {
          dispatch({
            type: actions.FIND_STARTED,
          });

          const user = await companyUserService.find(id, token);

          dispatch({
            type: actions.FIND_SUCCESS,
            payload: user,
          });
        } catch (error) {
          const errorData = Errors.getErrorData(error);
          dispatch({
            type: actions.FIND_ERROR,
            payload: errorData,
          });
        }
      },

    doUpdate: (id, values, token) => async (dispatch, getState) => {
      try {
        dispatch({
          type: actions.UPDATE_STARTED,
        });

        await companyUserService.edit(id, values, token);

        dispatch({
          type: actions.UPDATE_SUCCESS,
        });

        const currentUser = authSelectors.selectCurrentUser(getState());

        if (currentUser.id === values.id) {
          dispatch(authActions.doRefreshCurrentUser());
        }

        Message.success(i18n('companyUser.doUpdateSuccess'));
      } catch (error) {
        const errorData = Errors.getErrorData(error);
        dispatch({
          type: actions.UPDATE_ERROR,
          payload: errorData,
        });
      }
    },
  };

export default actions;
