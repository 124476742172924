import StorefrontAvailabilityService from 'modules/storefrontAvailability/storefrontAvailabilityService';
import Errors from 'modules/shared/error/errors';
import Message from '~/view/shared/message';

const prefix = 'STOREFRONT_AVAILABILITY_DESTROY',
  actions = {
    DESTROY_STARTED: `${prefix}_DESTROY_STARTED`,
    DESTROY_SUCCESS: `${prefix}_DESTROY_SUCCESS`,
    DESTROY_ERROR: `${prefix}_DESTROY_ERROR`,

    DESTROY_ALL_STARTED: `${prefix}_DESTROY_ALL_STARTED`,
    DESTROY_ALL_SUCCESS: `${prefix}_DESTROY_ALL_SUCCESS`,
    DESTROY_ALL_ERROR: `${prefix}_DESTROY_ALL_ERROR`,

    doDestroy: (storefrontId, flightId) => async (dispatch) => {
      try {
        dispatch({
          type: actions.DESTROY_STARTED,
        });

        await StorefrontAvailabilityService.destroy(storefrontId, flightId);

        dispatch({
          type: actions.DESTROY_SUCCESS,
        });

        Message.success('Delete Success');
      } catch (error) {
        const errorData = Errors.getErrorData(error);

        dispatch({
          type: actions.DESTROY_ERROR,
          payload: errorData,
        });
      }
    },
  };

export default actions;
