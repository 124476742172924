import { routerMiddleware } from 'connected-react-router';
import { persistStore } from 'redux-persist';
import { createBrowserHistory } from 'history';
import initializers from 'modules/initializers';
import createRootReducer from 'modules/reducers';
import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import thunkMiddleware from 'redux-thunk';

const history = createBrowserHistory();

let store;

export function configureStoreWithPersistor(preloadedState) {
  const middlewares = [thunkMiddleware, routerMiddleware(history)].filter(Boolean);
  store = createStore(
    createRootReducer(history),
    preloadedState,
    composeWithDevTools(applyMiddleware(...middlewares)),
  );

  const persistor = persistStore(store);

  for (const initializer of initializers) {
    initializer(store);
  }

  return { store, persistor };
}

export function getHistory() {
  return history;
}

export default function getStore() {
  return store;
}
