const data = [];
const subscriptions = {};

export default function (consumerKey, callback, { frequency = 2000 } = {}) {
  this.produce = (item) => {
    data.push(item);
    Object.values(subscriptions).forEach(
      (subscription) =>
        (subscription.timer =
          subscription.timer || setInterval(subscription.broadcast, subscription.frequency)),
    );
  };
  this.broadcast = () => {
    if (this.pointer < data.length) {
      subscriptions[this.consumerKey].callback({ data: data[this.pointer++] });
    } else {
      clearInterval(subscriptions[this.consumerKey].timer) ||
        (subscriptions[this.consumerKey].timer = 0);
    }
  };

  if (consumerKey) {
    this.consumerKey = consumerKey;
    this.callback = callback;
    this.frequency = frequency;
    this.pointer = 0;
    this.timer = setInterval(this.broadcast, this.frequency);
    this.unsubscribe = () => {
      clearInterval(subscriptions[this.consumerKey].timer);
      delete subscriptions[this.consumerKey];
    };
    subscriptions[consumerKey] = this;
  }
}
