import * as yup from 'yup';

import GenericField from '../../../modules/shared/fields/genericField';

export default class DecimalRangeField extends GenericField {
  constructor(name: string, label: string) {
    super(name, label);
  }

  forFilter() {
    return yup
      .array()
      .ensure()
      .compact()
      .of(yup.number().nullable(true).label(this.label))
      .label(this.label);
  }

  forFormInitialValue(value: any) {
    return value || [];
  }

  forExport: undefined;

  forForm: undefined;

  forImport: undefined;

  forInput: undefined;

  forView: undefined;

  literalMapToValue: undefined;
}
