import model from 'modules/customerProfile/customerProfileModel';

const { fields } = model;

export default [
  fields.id,
  fields.name,
  fields.birthdate,
  fields.gender,
  fields.tags,
  fields.audienceProfile,
  //  Fields.analyticsEvents,
  fields.createdAt,
  fields.updatedAt,
];
