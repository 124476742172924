import { FC } from 'react';
import { RouteProps } from 'react-router';
import RouteLayout from './RouteLayout';

interface SimpleRouteProps extends RouteProps {}

const SimpleRoute: FC<SimpleRouteProps> = (props) => {
  return <RouteLayout hideBarsDefault={true} {...props} />;
};

export default SimpleRoute;
