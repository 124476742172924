/* eslint-disable import/no-anonymous-default-export */
import statuses from 'modules/shared/importer/importerStatuses';

const INITIAL_PAGE_SIZE = 50;

export default (actions) => {
  const initialData = {
    rows: null,
    unassignedRows: [],
    errorMessage: null,
    importing: false,
    loading: false,
    completed: false,
    pagination: {
      current: 1,
      pageSize: INITIAL_PAGE_SIZE,
    },
    sorter: {},
    fieldMap: [],
    submittedFields: [],
  };

  return (state = initialData, { type, payload }) => {
    if (type === actions.RESETED) {
      return {
        ...initialData,
      };
    }

    if (type === actions.PAGINATION_CHANGED) {
      return {
        ...state,
        pagination: payload || {
          current: 1,
          pageSize: INITIAL_PAGE_SIZE,
        },
      };
    }

    if (type === actions.SORTER_CHANGED) {
      return {
        ...state,
        sorter: payload.sorter || {},
        rows: payload.sortedRows || [],
      };
    }

    if (type === actions.CREATE_MAP_FIELD_SUCCESS) {
      return {
        ...state,
        fieldMap: payload.fieldMap,
        loading: false,
      };
    }

    if (type === actions.SWITCH_MAP_FIELD_CHECKSTATE) {
      return {
        ...state,
        fieldMap: payload.fieldMap,
        loading: false,
      };
    }

    if (type === actions.UPDATE_DESTINATION_FIELD) {
      const { fieldMap } = payload;

      return {
        ...state,
        fieldMap,
        loading: false,
      };
    }

    if (type === actions.SUBMIT_DESTINATION_FIELDS) {
      const { submittedFields, rows } = payload;

      return {
        ...state,
        submittedFields,
        unassignedRows: [],
        rows,
        fieldMap: [],
        loading: false,
      };
    }

    if (type === actions.IMPORT_UPDATED) {
      const { rows } = payload;

      return {
        ...state,
        rows,
        loading: false,
        completed: false,
      };
    }

    if (type === actions.FILE_READ_ERROR) {
      return {
        ...state,
        errorMessage: payload.message ? payload.message : payload,
        loading: false,
      };
    }

    if (type === actions.FILE_READ_SUCCESS) {
      return {
        ...state,
        errorMessage: null,
        unassignedRows: payload,
        loading: false,
      };
    }

    if (type === actions.FIELD_UPDATE_SUCCESS) {
      const { rows } = payload;

      return {
        ...state,
        errorMessage: null,
        rows,
        unassignedRows: payload,
        loading: false,
        completed: false,
      };
    }

    if (type === actions.FIELD_DELETE_SUCCESS) {
      const { rows } = payload;

      return {
        ...state,
        errorMessage: null,
        rows,
        unassignedRows: payload,
        loading: false,
      };
    }

    if (type === actions.FILE_READ_STARTED) {
      return {
        ...state,
        loading: true,
      };
    }

    if (type === actions.IMPORT_STARTED) {
      return {
        ...state,
        importing: true,
        loading: true,
      };
    }

    if (type === actions.IMPORT_PAUSED) {
      return {
        ...state,
        importing: false,
        loading: false,
      };
    }

    if (type === actions.IMPORT_SUCCESS) {
      return {
        ...state,
        importing: false,
        completed: true,
        loading: false,
      };
    }

    if (type === actions.IMPORT_ERROR) {
      return {
        ...state,
        importing: false,
        loading: false,
      };
    }

    if (type === actions.IMPORT_BATCH_SUCCESS) {
      const item = (state.rows || []).find((item) => item._line === payload.line);

      if (!item) {
        return;
      }

      item._status = statuses.IMPORTED;

      return {
        ...state,
        loading: false,
        rows: [...state.rows],
      };
    }

    if (type === actions.IMPORT_BATCH_ERROR) {
      const item = (state.rows || []).find((item) => item._line === payload.line);

      if (!item) {
        return;
      }

      item._status = statuses.ERROR;
      item._errorMessage = payload.errorMessage;

      return {
        ...state,
        loading: false,
        rows: [...state.rows],
      };
    }

    return state;
  };
};
