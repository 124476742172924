import { AgencyCampaignActionTypes } from '~/types/modules/campaign/agency/actions.d';
import { AgencyCampaignReducerState } from '~/types/modules/campaign/agency/reducers.d';

const initialState: AgencyCampaignReducerState = {
  pagination: {
    current: 1,
    pageSize: 10000,
  },
  rows: [],
  loading: false,
  error: '',
  filter: {},
  count: 0,
  sorter: '',
  selectedKeys: [],
  emailPreview: undefined,
  rfps: [],
  dmas: [],
  inventoryTypes: [],
  companies: [],
};

const reducer = (state = initialState, { type, payload }): AgencyCampaignReducerState => {
  switch (type) {
    case AgencyCampaignActionTypes.SELECT_START:
    case AgencyCampaignActionTypes.REPLACE_RFP_START:
    case AgencyCampaignActionTypes.CREATE_START:
    case AgencyCampaignActionTypes.FETCH_RFP_START:
    case AgencyCampaignActionTypes.FETCH_EMAIL_PREVIEW_START:
    case AgencyCampaignActionTypes.FETCH_COMPANY_START:
    case AgencyCampaignActionTypes.UPDATE_START:
      return {
        ...state,
        loading: true,
      };
    case AgencyCampaignActionTypes.REPLACE_RFP_ERROR:
    case AgencyCampaignActionTypes.CREATE_ERROR:
    case AgencyCampaignActionTypes.FETCH_RFP_ERROR:
    case AgencyCampaignActionTypes.FETCH_PARAMETERS_ERROR:
    case AgencyCampaignActionTypes.REPLACE_PARAMETERS_ERROR:
    case AgencyCampaignActionTypes.FETCH_ERROR:
    case AgencyCampaignActionTypes.SELECT_ERROR:
    case AgencyCampaignActionTypes.FETCH_COMPANY_ERROR:
    case AgencyCampaignActionTypes.FETCH_EMAIL_PREVIEW_ERROR:
    case AgencyCampaignActionTypes.UPDATE_ERROR:
      return {
        ...state,
        loading: false,
        error: payload,
      };
    case AgencyCampaignActionTypes.REPLACE_RFP_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case AgencyCampaignActionTypes.CREATE_SUCCESS:
    case AgencyCampaignActionTypes.UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        agencyCampaign: payload,
      };
    case AgencyCampaignActionTypes.FETCH_RFP_SUCCESS:
      return {
        ...state,
        loading: false,
        rfps: payload,
      };
    case AgencyCampaignActionTypes.FETCH_PARAMETERS_START:
    case AgencyCampaignActionTypes.REPLACE_PARAMETERS_START:
      return {
        ...state,
        loading: true,
      };
    case AgencyCampaignActionTypes.FETCH_PARAMETERS_SUCCESS:
    case AgencyCampaignActionTypes.REPLACE_PARAMETERS_SUCCESS:
      return {
        ...state,
        loading: false,
        dmas: payload.dmas,
        inventoryTypes: payload.inventoryTypes,
      };
    case AgencyCampaignActionTypes.FETCH_START:
      return {
        ...state,
        loading: true,
      };
    case AgencyCampaignActionTypes.FETCH_AGENCY_CAMPAIGN_SUCCESS:
      return {
        ...state,
        loading: false,
        agencyCampaign: payload,
      };
    case AgencyCampaignActionTypes.FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        rows: payload,
      };
    case AgencyCampaignActionTypes.FETCH_EMAIL_PREVIEW_SUCCESS:
      return {
        ...state,
        loading: false,
        emailPreview: payload,
      };
    case AgencyCampaignActionTypes.FETCH_COMPANY_SUCCESS:
      return {
        ...state,
        loading: false,
        companies: payload?.rows ?? [],
      };
    case AgencyCampaignActionTypes.CLEAR_SELECTION:
      return {
        ...state,
        selectedKeys: [],
      };
    case AgencyCampaignActionTypes.SELECT_SUCCESS:
      return {
        ...state,
        loading: false,
        selectedKeys: payload,
      };
    case AgencyCampaignActionTypes.SEND_EMAIL_SUCCESS:
      return {
        ...state,
        redirectTo: '/campaign/agency',
      };
    default:
      return {
        ...state,
      };
  }
};

// eslint-disable-next-line import/no-default-export
export default reducer;
