import * as yup from 'yup';

import GenericField from '../../../modules/shared/fields/genericField';
import { getOptions } from '../../../utils';

export default class ObjectArrayField extends GenericField {
  required: boolean;

  min?: number;

  max?: number;

  valueProp: string;

  labelProp: string;

  valueName: string;

  labelLabel: string;

  constructor(
    name: string,
    label: string,
    {
      required = false,
      min = undefined,
      max = undefined,
      valueProp = 'id',
      labelProp = 'name',
      valueName = 'value',
      labelName = 'label',
    } = {
      valueProp: 'id',
      labelProp: 'name',
      valueName: 'value',
      labelName: 'label',
    },
  ) {
    super(name, label);

    this.required = required;
    this.min = min;
    this.max = max;
    this.valueProp = valueProp;
    this.labelProp = labelProp;
    this.valueName = valueName;
    this.labelLabel = labelName;
  }

  forFormInitialValue(value: any) {
    if (!value) {
      return [];
    }
    return getOptions({
      arr: value,
      valueProp: this.valueProp,
      labelProp: this.labelProp,
      valueName: this.valueName,
      labelName: this.labelLabel,
    });
  }

  forForm() {
    let yupChain = yup
      .array()
      .nullable(true)
      .label(this.label)
      .transform((value, originalValue) => {
        if (!originalValue || !originalValue.length) {
          return [];
        }

        return originalValue;
      });

    if (this.required) {
      yupChain = yupChain.required();
    }

    if (this.min || this.min === 0) {
      yupChain = yupChain.min(this.min);
    }

    if (this.max) {
      yupChain = yupChain.max(this.max);
    }

    return yupChain;
  }

  forExport() {
    return yup
      .mixed()
      .label(this.label)
      .transform((value, originalValue) => {
        if (!originalValue || !originalValue.length) {
          return null;
        }

        return originalValue.map((value: any) => value.name || value).join(' ');
      });
  }

  forFilter: undefined;

  forImport: undefined;

  forInput: undefined;

  forView: undefined;

  literalMapToValue: undefined;
}
