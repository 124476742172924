import destroy from 'modules/locationGroup/destroy/locationGroupDestroyReducers';
import form from 'modules/locationGroup/form/locationGroupFormReducers';
import list from 'modules/locationGroup/list/locationGroupListReducers';
import view from 'modules/locationGroup/view/locationGroupViewReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
});
