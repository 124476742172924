import * as yup from 'yup';

import GenericField from '../../../modules/shared/fields/genericField';
import { FieldModel, IRelationToManyFieldOptions, Options } from './fieldTypes';

interface Item {
  id: string;
}

const getValue = (objectProperty: string, item: Item) =>
  objectProperty ? item[objectProperty] : item.id;

export default class RelationToManyField extends GenericField {
  required: boolean;

  min?: number;

  max?: number;

  relatedModel?: FieldModel;

  delimiter?: string;

  property?: string;

  constructor(
    name: string,
    label: string,
    {
      required = false,
      min = undefined,
      max = undefined,
      relatedModel = undefined,
      delimiter = undefined,
      property = undefined,
    }: Options<IRelationToManyFieldOptions> = {},
  ) {
    super(name, label);

    this.required = required;
    this.min = min;
    this.max = max;
    this.relatedModel = relatedModel;
    this.delimiter = delimiter;
    this.property = property;

    if (required && (!min || min < 1)) {
      this.min = 1;
    }
  }

  forView(value: string) {
    if (Array.isArray(value)) {
      return value.join(', ');
    }

    return value;
  }

  forInput: undefined;

  forFormInitialValue(value: string) {
    return value;
  }

  forFilter() {
    const objectProperty = this.property;
    return yup
      .mixed()
      .label(this.label)
      .transform((value, originalValue) => {
        if (!originalValue || !originalValue.length) {
          return [];
        }

        return originalValue.map((item: Item) => getValue(objectProperty, item));
      });
  }

  forForm() {
    let yupChain = yup.array().nullable(true).label(this.label);

    /*
     * If (this.required) {
     *   YupChain = yupChain.required();
     * }
     */

    if (this.min || this.min === 0) {
      yupChain = yupChain.min(this.min);
    }

    if (this.max) {
      yupChain = yupChain.max(this.max);
    }

    // Used as Array of models
    if (this.relatedModel) {
      const relatedModelShape = this.shapeOf(this.relatedModel);
      const relatedModelSchema = yup.object().shape(relatedModelShape);

      yupChain = yupChain.of(relatedModelSchema);
    } else {
      // User as Array of autocompleted values
      const objectProperty = this.property;
      yupChain = yupChain.transform((value, originalValue) => {
        if (!originalValue || !originalValue.length) {
          return [];
        }

        return originalValue.map((item: Item) => getValue(objectProperty, item));
      });
    }

    return yupChain;
  }

  forExport() {
    const objectProperty = this.property;
    const delimiter = this.delimiter;
    return yup
      .mixed()
      .label(this.label)
      .transform((value, originalValue) => {
        if (!Array.isArray(originalValue)) {
          return originalValue;
        }
        return originalValue
          .map((value: Item) => getValue(objectProperty, value))
          .join(delimiter ?? ' ');
      });
  }

  forImport() {
    let yupChain = yup.array().nullable(true).label(this.label);

    // Used as Array of models
    if (this.relatedModel) {
      const relatedModelShape = this.shapeOf(this.relatedModel);
      const relatedModelSchema = yup.object().shape(relatedModelShape);
      yupChain = yupChain.of(relatedModelSchema);
    } else {
      // User as Array of autocompleted values
      const delimiter = this.delimiter;
      yupChain = yupChain.transform((value, originalValue) => {
        if (!originalValue) {
          return null;
        }

        if (Array.isArray(originalValue)) {
          return originalValue;
        }

        return originalValue
          .trim()
          .split(delimiter ?? ' ')
          .map((value: string) => value?.trim());
      });
    }

    if (this.required) {
      yupChain = yupChain.required();
    }

    if (this.min || this.min === 0) {
      yupChain = yupChain.min(this.min);
    }

    if (this.max) {
      yupChain = yupChain.max(this.max);
    }

    return yupChain;
  }

  literalMapToValue: undefined;

  protected shapeOf(model: FieldModel) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const shape: { [x: string]: any } = {};

    for (const fieldName in model) {
      const field = model[fieldName];
      shape[fieldName] = field.forForm && field.forForm();
    }

    return shape;
  }
}
