import destroy from 'modules/storefront/destroy/storefrontDestroyReducers';
import form from 'modules/storefront/form/storefrontFormReducers';
import importerReducer from 'modules/storefront/importer/storefrontImporterReducers';
import list from 'modules/storefront/list/storefrontListReducers';
import view from 'modules/storefront/view/storefrontViewReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
});
