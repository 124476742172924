import Errors from 'modules/shared/error/errors';
import { getHistory } from 'modules/store';
import StorefrontService from 'modules/storefront/storefrontService';
import { baseRouts } from 'view/routes';

const prefix = 'STOREFRONT_VIEW',
  actions = {
    FIND_STARTED: `${prefix}_FIND_STARTED`,
    FIND_SUCCESS: `${prefix}_FIND_SUCCESS`,
    FIND_ERROR: `${prefix}_FIND_ERROR`,

    doFind: (id) => async (dispatch) => {
      try {
        dispatch({
          type: actions.FIND_STARTED,
        });

        const record = await StorefrontService.find(id);
        dispatch({
          type: actions.FIND_SUCCESS,
          payload: record,
        });
      } catch (error) {
        Errors.handle(error);

        dispatch({
          type: actions.FIND_ERROR,
        });

        getHistory().push(`/${baseRouts.location}`);
      }
    },

    SYNC_STARTED: `${prefix}_SYNC_STARTED`,
    SYNC_SUCCESS: `${prefix}_SYNC_SUCCESS`,
    SYNC_ERROR: `${prefix}_SYNC_ERROR`,

    doSync: (storefrontId, storefrontLocationId) => async (dispatch) => {
      try {
        dispatch({
          type: actions.SYNC_STARTED,
        });

        const result = await StorefrontService.refreshStatus(storefrontLocationId);

        if (!result) {
          return dispatch({
            type: actions.SYNC_ERROR,
          });
        }

        dispatch(actions.doFind(storefrontId));
      } catch (error) {
        Errors.handle(error);

        dispatch({
          type: actions.SYNC_ERROR,
        });
      }
    },
  };

export default actions;
