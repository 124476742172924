import destroy from 'modules/customerAudience/destroy/customerAudienceDestroyReducers';
import form from 'modules/customerAudience/form/customerAudienceFormReducers';
import importerReducer from 'modules/customerAudience/importer/customerAudienceImporterReducers';
import list from 'modules/customerAudience/list/customerAudienceListReducers';
import view from 'modules/customerAudience/view/customerAudienceViewReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
});
