import { Alert, AlertColor, Collapse } from '@mui/material';
import { FC, forwardRef, PropsWithChildren, useContext, useState } from 'react';
import AlertContext from './AlertContext';
import AlertMessageText from './AlertMessageText';

export interface AlertToast {
  severity?: AlertColor;
  majorText: string;
  text: string;
  handleClose?: () => void;
}

export interface AlertContextProps {
  addAlert: (alert: AlertToast) => void;
  clearAlerts: () => void;
}

export interface WithAlertContext {
  alertContext: AlertContextProps;
}

const AlertToast: FC<AlertToast> = ({ severity, text, majorText, handleClose }) => {
  const [transitionedIn, setTransitionedIn] = useState<boolean>(true);

  return (
    <Collapse in={transitionedIn}>
      <Alert
        severity={severity}
        onClose={() => {
          setTransitionedIn(false);
          handleClose();
        }}
      >
        <AlertMessageText majorText={majorText} text={text} />
      </Alert>
    </Collapse>
  );
};

interface AlertContainerProps {
  maxStack?: number;
}

export const AlertContainer: FC<PropsWithChildren<AlertContainerProps>> = ({
  children,
  maxStack = 1,
}) => {
  const [alerts, setAlerts] = useState<AlertToast[]>([]);

  const handleAdd = (alert: AlertToast) => {
    const updatedAlerts = [...alerts];
    updatedAlerts.length >= maxStack && updatedAlerts.shift();
    updatedAlerts.push(alert);
    setAlerts(updatedAlerts);
  };

  const handleClose = (): void => {
    const updatedAlerts = [...alerts];
    updatedAlerts.shift();
    setAlerts(updatedAlerts);
  };

  const handleClearAlerts = (): void => {
    setAlerts([]);
  };

  const renderAlerts = (alerts: AlertToast[]) => {
    return alerts.map((alert, i) => (
      <AlertToast key={i} {...alert} handleClose={() => handleClose()} />
    ));
  };

  return (
    <AlertContext.Provider
      value={{
        addAlert: handleAdd,
        clearAlerts: handleClearAlerts,
      }}
    >
      <>{renderAlerts(alerts)}</>
      <>{children}</>
    </AlertContext.Provider>
  );
};

export const useAlertContext = (): AlertContextProps => useContext(AlertContext);

export const withAlertContext: FC<WithAlertContext> = (Component) => {
  const Wrapper = forwardRef((props, ref) => (
    <AlertContext.Consumer>
      {(context) => <Component {...props} ref={ref} alertContext={context} />}
    </AlertContext.Consumer>
  ));
  Wrapper.displayName = 'withAlertContext';
  return Wrapper;
};
