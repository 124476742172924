import destroy from 'modules/deviceCapability/destroy/deviceCapabilityDestroyReducers';
import form from 'modules/deviceCapability/form/deviceCapabilityFormReducers';
import importerReducer from 'modules/deviceCapability/importer/deviceCapabilityImporterReducers';
import list from 'modules/deviceCapability/list/deviceCapabilityListReducers';
import view from 'modules/deviceCapability/view/deviceCapabilityViewReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
});
