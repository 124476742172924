import { RFPCartItemsReducerState } from '~/types/modules/cart/rfp/reducers';
import actions from '../rfp/actions';

const initialData: RFPCartItemsReducerState = {
  campaignInventoryIDs: [],
};

const reducer = (state = initialData, { type, payload }): RFPCartItemsReducerState => {
  switch (type) {
    case actions.CREATE_CAMPAIGN_INVENTORY:
      return {
        ...state,
        campaignInventoryIDs: payload,
      };

    default:
      return { ...state };
  }
};

export default reducer;
