import model from 'modules/tag/tagModel';

const { fields } = model;

export default [
  fields.id,
  fields.name,
  fields.label,
  fields.type,
  fields.createdAt,
  fields.updatedAt,
];
