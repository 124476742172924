import { i18n } from 'i18n';
import DateTimeField from 'modules/shared/fields/dateTimeField.ts';
import DateTimeRangeField from 'modules/shared/fields/dateTimeRangeField.ts';
import IdField from 'modules/shared/fields/idField.ts';
import ImagesField from 'modules/shared/fields/imagesField.ts';
import RelationToManyField from 'modules/shared/fields/relationToManyField.ts';
import RelationToOneField from 'modules/shared/fields/relationToOneField.ts';
import StringField from 'modules/shared/fields/stringField.ts';

function label(name) {
  return i18n(`entities.deviceProfile.fields.${name}`);
}

const fields = {
  id: new IdField('id', label('id')),
  name: new StringField('name', label('name'), {
    required: true,
  }),
  productImage: new ImagesField(
    'productImage',
    label('productImage'),
    'deviceProfile/productImage',
    {},
  ),
  capabilitiesAvailable: new RelationToManyField(
    'capabilitiesAvailable',
    label('capabilitiesAvailable'),
    {
      required: true,
    },
  ),
  status: new RelationToOneField('status', label('status'), {
    required: true,
  }),
  version: new StringField('version', label('version'), {
    required: true,
  }),
  capabilitiesEnabled: new RelationToManyField(
    'capabilitiesEnabled',
    label('capabilitiesEnabled'),
    {
      required: true,
    },
  ),
  createdAt: new DateTimeField('createdAt', label('createdAt')),
  updatedAt: new DateTimeField('updatedAt', label('updatedAt')),
  createdAtRange: new DateTimeRangeField('createdAtRange', label('createdAtRange')),
};

export default {
  fields,
};
