import { FC, forwardRef, PropsWithChildren } from 'react';

import { generateUtilityClasses, SxProps, Theme } from '@mui/material';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import clsx from 'clsx';
import { BaseAlert, BaseAlertClasses, BaseAlertProps } from '~/view/shared/OSAIAlert/BaseAlert';

const classes = {
  ...generateUtilityClasses('SuccessAlert', ['root']),
};

const styles = {
  [`&.${classes.root}`]: {
    background: (theme) => theme.palette.success.background,
    color: (theme) => theme.palette.success.dark,
    [`& svg`]: {
      fill: (theme) => theme.palette.success.dark,
    },
    [`& a`]: {
      color: (theme) => theme.palette.success.dark,
    },
  },
  [`.${BaseAlertClasses.close}`]: {
    [`& svg`]: {
      fill: (theme) => theme.palette.success.dark,
    },
  },
};

export interface SuccessAlertProps extends BaseAlertProps {
  sx?: SxProps<Theme>;
  className?: string;
}

const SuccessAlert: FC<SuccessAlertProps> = forwardRef<HTMLDivElement, SuccessAlertProps>(
  ({ className, sx, children, ...props }: PropsWithChildren<SuccessAlertProps>, ref) => {
    return (
      <BaseAlert
        ref={ref}
        {...props}
        variant="outlined"
        severity="success"
        icon={<CheckCircleOutlineOutlinedIcon />}
        className={clsx(className, classes.root)}
        sx={Array.isArray(sx) ? sx.concat([styles]) : { ...sx, ...styles }}
      >
        {children}
      </BaseAlert>
    );
  },
);

SuccessAlert.displayName = 'SuccessAlert';

export { SuccessAlert };

export { classes as SuccessAlertClasses };
