import { i18n } from 'i18n';
import fields from 'modules/sector/importer/sectorImporterFields';
import selectors from 'modules/sector/importer/sectorImporterSelectors';
import SectorService from 'modules/sector/sectorService';
import importerActions from 'modules/shared/importer/importerActions';

export default importerActions(
  'SECTOR_IMPORTER',
  selectors,
  SectorService.import,
  fields,
  i18n('entities.sector.importer.fileName'),
);
