import { i18n } from 'i18n';
import exporterFields from 'modules/locationGroup/list/locationGroupListExporterFields';
import selectors from 'modules/locationGroup/list/locationGroupListSelectors';
import MarketService from 'modules/locationGroup/locationGroupService';
import { getListActions } from 'modules/shared/actions';
import Errors from 'modules/shared/error/errors';
import Exporter from 'modules/shared/exporter/exporter';

const prefix = 'LOCATION_GROUP_LIST',
  defaultActions = getListActions({
    service: MarketService,
    selectors,
    prefix,
  }),
  actions = {
    ...defaultActions,
    TOGGLE_ONE_SELECTED: `${prefix}_TOGGLE_ONE_SELECTED`,
    TOGGLE_ALL_SELECTED: `${prefix}_TOGGLE_ALL_SELECTED`,
    CLEAR_ALL_SELECTED: `${prefix}_CLEAR_ALL_SELECTED`,
    EXPORT_STARTED: `${prefix}_EXPORT_STARTED`,
    EXPORT_SUCCESS: `${prefix}_EXPORT_SUCCESS`,
    EXPORT_ERROR: `${prefix}_EXPORT_ERROR`,

    doToggleAllSelected() {
      return {
        type: actions.TOGGLE_ALL_SELECTED,
      };
    },

    doToggleOneSelected(id) {
      return {
        type: actions.TOGGLE_ONE_SELECTED,
        payload: id,
      };
    },

    doClearAllSelected() {
      return {
        type: actions.CLEAR_ALL_SELECTED,
      };
    },

    doExport: () => async (dispatch, getState) => {
      try {
        if (!exporterFields || !exporterFields.length) {
          throw new Error('exporterFields is required');
        }

        dispatch({
          type: actions.EXPORT_STARTED,
        });

        const filter = selectors.selectFilter(getState()),
          response = await MarketService.list(
            filter,
            selectors.selectOrderBy(getState()),
            null,
            null,
          );

        new Exporter(
          exporterFields,
          i18n('entities.locationGroup.exporterFileName'),
        ).transformAndExportAsExcelFile(response.rows);

        dispatch({
          type: actions.EXPORT_SUCCESS,
        });
      } catch (error) {
        const errorData = Errors.getErrorData(error);

        dispatch({
          type: actions.EXPORT_ERROR,
          payload: errorData,
        });
      }
    },
  };

export default actions;
