/* eslint-disable no-unused-vars */
import MenuIcon from '@mui/icons-material/Menu';
import { AppBar, Button, colors, Grid, IconButton, Toolbar } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import UserMenu from 'view/layout/UserMenu';
import { useAnalytics } from '~/analytics/segment/useAnalytics';

const useStyles = makeStyles((theme) => ({
  root: {
    boxShadow: 'none',
    '@media print': {
      '&$topBarRoot': {
        display: 'none',
        padding: 0,
      },
    },
  },
  topBarRoot: {},
  flexGrow: {
    flexGrow: 1,
  },
  logo: {
    paddingLeft: theme.spacing(0),
    fontWeight: '500',
    fontSize: '1.5em',
    color: theme.palette.getContrastText(theme.palette.primary.main),
    textDecoration: 'none',
  },
  search: {
    backgroundColor: 'rgba(255,255,255, 0.1)',
    borderRadius: 4,
    flexBasis: 300,
    height: 36,
    padding: theme.spacing(0, 2),
    display: 'flex',
    alignItems: 'center',
  },
  searchIcon: {
    marginRight: theme.spacing(2),
    color: 'inherit',
  },
  searchInput: {
    flexGrow: 1,
    color: 'inherit',
    '& input::placeholder': {
      opacity: 1,
      color: 'inherit',
    },
  },
  searchPopper: {
    zIndex: theme.zIndex.appBar + 100,
  },
  searchPopperContent: {
    marginTop: theme.spacing(1),
  },
  trialButton: {
    marginLeft: theme.spacing(2),
    color: theme.palette.common.white,
    backgroundColor: colors.green[600],
    '&:hover': {
      backgroundColor: colors.green[900],
    },
  },
  trialIcon: {
    marginRight: theme.spacing(1),
  },
  menuButton: {
    marginRight: theme.spacing(1),
    [theme.breakpoints.up('lg')]: {
      display: 'none',
    },
  },
  chatButton: {
    marginLeft: theme.spacing(1),
  },
  notificationsButton: {
    marginLeft: theme.spacing(1),
  },
  notificationsBadge: {
    backgroundColor: colors.orange[600],
  },
  logoutButton: {
    marginLeft: theme.spacing(1),
  },
  logoutIcon: {
    marginRight: theme.spacing(1),
  },
}));

function TopBar({ onOpenNavBarMobile, isAnonymous, className, ...rest }) {
  const classes = useStyles();
  const analytics = useAnalytics();

  const handleCompleteListingTracking = () => {
    analytics.track('CompleteYourListing', { source: 'pd' });
  };

  const handleSignInTracking = () => {
    analytics.track('SignIn', { source: 'pd' });
  };

  useEffect(() => {
    /*
     *Let mounted = true;
     *
     *const fetchNotifications = () => {
     *  axios.get('/api/account/notifications').then((response) => {
     *    if (mounted) {
     *      setNotifications(response.data.notifications);
     *    }
     *  });
     *};
     *
     *fetchNotifications();
     *
     *return () => {
     *  mounted = false;
     *};
     */
  }, []);

  const logoRoute = isAnonymous() === false ? '/' : '/directory';
  const isAnonymousUser = isAnonymous();

  return (
    <AppBar
      {...rest}
      className={clsx(classes.root, classes.topBarRoot, className)}
      color="primary"
      style={{
        backgroundColor: 'white',
        boxShadow: '0px 2px 11px -1px rgba(0, 0, 0, 13%)',
      }}
    >
      <Toolbar
        style={{
          borderBottom: '0px',
        }}
      >
        {!isAnonymousUser && (
          <IconButton
            className={classes.menuButton}
            onClick={onOpenNavBarMobile}
            style={{
              color: 'black',
            }}
            size="large"
          >
            <MenuIcon />
          </IconButton>
        )}
        <RouterLink className={classes.logo} to={logoRoute}>
          <img
            src="/images/onescreen_logo_small.png"
            style={{
              height: '25px',
            }}
          />
        </RouterLink>
        <div className={classes.flexGrow} />
        {isAnonymous() === false ? (
          <UserMenu />
        ) : (
          <>
            <Button
              target="_about"
              href={'https://www.onescreen.ai/get-listed'}
              variant="contained"
              onClick={handleCompleteListingTracking}
            >
              Complete Your Listing
            </Button>
            <Grid sx={{ marginLeft: '10px' }}>
              <Button href={`/auth/signin`} variant="outlined" onClick={handleSignInTracking}>
                Sign In
              </Button>
            </Grid>
          </>
        )}
      </Toolbar>
    </AppBar>
  );
}

TopBar.propTypes = {
  className: PropTypes.string,
  onOpenNavBarMobile: PropTypes.func,
  isAnonymous: PropTypes.func,
};

export default TopBar;
