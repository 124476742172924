import destroy from 'modules/playlist/destroy/playlistDestroyReducers';
import form from 'modules/playlist/form/playlistFormReducers';
import list from 'modules/playlist/list/playlistListReducers';
import view from 'modules/playlist/view/playlistViewReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
});
