import { getFormActions } from '~/modules/shared/actions';
import service from '~/modules/cart/invoiceService';
import { i18n } from '~/i18n';
import { CartFormActions } from '~/types/modules/cart/form/actions';
import { getHistory } from '~/modules/store';
import Message from '~/view/shared/message';

const path = 'invoices';

const commonActions = getFormActions({
  service,
  path: path,
});

const actions: CartFormActions = {
  ...commonActions,
  CREATE_STRIPE_INVOICE_STARTED: `${path}_CREATE_STRIPE_INVOICE_STARTED`,
  CREATE_STRIPE_INVOICE_SUCCESS: `${path}_CREATE_STRIPE_INVOICE_SUCCESS`,
  CREATE_STRIPE_INVOICE_ERROR: `${path}_CREATE_STRIPE_INVOICE_ERROR`,
  doCreateStripeInvoice: (id, data) => async (dispatch, getState) => {
    try {
      dispatch({
        type: actions.CREATE_STRIPE_INVOICE_STARTED,
      });

      const stripeInvoiceId = await service.generateStripeInvoiceFromInvoice<string>(id, data);
      const isQBOconnected = getState().quickbook.isConnected;
      if (isQBOconnected) {
        await service.syncStripeInvoiceWithQBO(stripeInvoiceId);
      }

      dispatch({
        type: actions.CREATE_STRIPE_INVOICE_SUCCESS,
      });

      Message.success(i18n('entities.invoice.create.success'));
      getHistory().goBack();
    } catch (e) {
      dispatch({
        type: actions.CREATE_STRIPE_INVOICE_ERROR,
        payload: e,
      });
      Message.error(i18n('entities.common.create.error'));
    }
  },
};

export default actions;
