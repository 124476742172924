import destroy from 'modules/transaction/destroy/transactionDestroyReducers';
import form from 'modules/transaction/form/transactionFormReducers';
import importerReducer from 'modules/transaction/importer/transactionImporterReducers';
import list from 'modules/transaction/list/transactionListReducers';
import view from 'modules/transaction/view/transactionViewReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
});
