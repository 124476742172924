import { getListActions } from '../../shared/actions';
import PriceRequestService from '../priceRequestService';
import priceRequestListSelectors from './priceRequestListSelectors';

export default getListActions({
  service: PriceRequestService,
  selectors: priceRequestListSelectors,
  prefix: 'PRICE_REQUEST_LIST',
});

/*
 * Import Errors from '../../shared/error/errors';
 * import Message from '../../../view/shared/message';
 * import { getHistory } from '../../store';
 * import { i18n } from '../../../i18n';
 * import PriceRequestService from '../priceRequestService';
 * import { Dispatch, Store } from 'redux';
 */

// Const prefix = 'PRICE_REQUEST';

/*
 * Const actions = {
 *     RESET: `${prefix}_RESET`,
 */

/*
 *     FIND_STARTED: `${prefix}_FIND_STARTED`,
 *     FIND_SUCCESS: `${prefix}_FIND_SUCCESS`,
 *     FIND_ERROR: `${prefix}_FIND_ERROR`,
 */

/*
 *     CREATE_STARTED: `${prefix}_CREATE_STARTED`,
 *     CREATE_SUCCESS: `${prefix}_CREATE_SUCCESS`,
 *     CREATE_ERROR: `${prefix}_CREATE_ERROR`,
 */

/*
 *     UPDATE_STARTED: `${prefix}_UPDATE_STARTED`,
 *     UPDATE_SUCCESS: `${prefix}_UPDATE_SUCCESS`,
 *     UPDATE_ERROR: `${prefix}_UPDATE_ERROR`,
 */

/*
 *     DoFetch: () => async (dispatch: Dispatch) => {
 *         try {
 *             dispatch({
 *                 type: actions.FIND_STARTED,
 *             });
 */

//             Const records = await PriceRequestService.list();

/*
 *             Dispatch({
 *                 type: actions.FIND_SUCCESS,
 *                 payload: records,
 *             });
 *         } catch (error) {
 *             Errors.handle(error);
 */

/*
 *             Dispatch({
 *                 type: actions.FIND_ERROR,
 *             });
 *         }
 *     },
 */

/*
 *     // doCreate: (data: any) => async (dispatch: Dispatch) => {
 *         // try {
 *         //     dispatch({
 *         //         type: actions.CREATE_STARTED,
 *         //     });
 */

//         //     await MarketService.create(values);

/*
 *         //     dispatch({
 *         //         type: actions.CREATE_SUCCESS,
 *         //     });
 */

//         //     Message.success(i18n('entities.market.create.success'));

/*
 *         //     getHistory().push('/market');
 *         // } catch (error) {
 *         //     Errors.handle(error);
 */

/*
 *         //     dispatch({
 *         //         type: actions.CREATE_ERROR,
 *         //     });
 *         // }
 *     // },
 */

/*
 *     DoSubmit: (id: string, accept: boolean) => async (
 *         dispatch: Dispatch,
 *         getState: any,
 *     ) => {
 *         console.log(getState)
 *         try {
 *             dispatch({
 *                 type: actions.UPDATE_STARTED,
 *             });
 */

//             Await PriceRequestService.answerRequest(id, accept);

/*
 *             Dispatch({
 *                 type: actions.UPDATE_SUCCESS,
 *             });
 */

/*
 *             Message.success(i18n('entities.priceRequest.submit.success'));
 *         } catch (error) {
 *             Errors.handle(error);
 */

/*
 *             Dispatch({
 *                 type: actions.UPDATE_ERROR,
 *             });
 *         }
 *     },
 * };
 */

// Export default actions;
