import { i18n } from 'i18n';
import CustomerProfileService from 'modules/customerProfile/customerProfileService';
import listActions from 'modules/customerProfile/list/customerProfileListActions';
import Errors from 'modules/shared/error/errors';
import { getHistory } from 'modules/store';
import Message from '~/view/shared/message';

const prefix = 'CUSTOMERPROFILE_DESTROY',
  actions = {
    DESTROY_STARTED: `${prefix}_DESTROY_STARTED`,
    DESTROY_SUCCESS: `${prefix}_DESTROY_SUCCESS`,
    DESTROY_ERROR: `${prefix}_DESTROY_ERROR`,

    DESTROY_ALL_STARTED: `${prefix}_DESTROY_ALL_STARTED`,
    DESTROY_ALL_SUCCESS: `${prefix}_DESTROY_ALL_SUCCESS`,
    DESTROY_ALL_ERROR: `${prefix}_DESTROY_ALL_ERROR`,

    doDestroy: (id) => async (dispatch) => {
      try {
        dispatch({
          type: actions.DESTROY_STARTED,
        });

        await CustomerProfileService.destroyAll([id]);

        dispatch({
          type: actions.DESTROY_SUCCESS,
        });

        Message.success(i18n('entities.customerProfile.destroy.success'));

        getHistory().push('/customer-profile');
      } catch (error) {
        Errors.handle(error);

        dispatch({
          type: actions.DESTROY_ERROR,
        });
      }
    },

    doDestroyAll: (ids) => async (dispatch) => {
      try {
        dispatch({
          type: actions.DESTROY_ALL_STARTED,
        });

        await CustomerProfileService.destroyAll(ids);

        dispatch({
          type: actions.DESTROY_ALL_SUCCESS,
        });

        if (listActions) {
          dispatch(listActions.doClearAllSelected());
        }

        Message.success(i18n('entities.customerProfile.destroyAll.success'));

        getHistory().push('/customer-profile');
      } catch (error) {
        Errors.handle(error);

        dispatch({
          type: actions.DESTROY_ALL_ERROR,
        });
      }
    },
  };

export default actions;
