import authAxios from 'modules/shared/axios/authAxios';

export default class DeviceCapabilityService {
  static async update(id, data) {
    const body = {
        id,
        data,
      },
      response = await authAxios.put(`/device-capability/${id}`, body);

    return response.data;
  }

  static async destroyAll(ids) {
    const params = {
        ids,
      },
      response = await authAxios.delete('/device-capability', {
        params,
      });

    return response.data;
  }

  static async create(data) {
    const body = {
        data,
      },
      response = await authAxios.post('/device-capability', body);

    return response.data;
  }

  static async import(values, importHash) {
    const body = {
        data: values,
        importHash,
      },
      response = await authAxios.post('/device-capability/import', body);

    return response.data;
  }

  static async find(id) {
    const response = await authAxios.get(`/device-capability/${id}`);
    return response.data;
  }

  static async list(filter, orderBy, limit, offset) {
    const params = {
        filter,
        orderBy,
        limit,
        offset,
      },
      response = await authAxios.get('/device-capability', {
        params,
      });

    return response.data;
  }

  static async listAutocomplete(query, limit) {
    const params = {
        query,
        limit,
      },
      response = await authAxios.get('/device-capability/autocomplete', {
        params,
      });

    return response.data;
  }
}
