import model from 'modules/status/statusModel';

const { fields } = model;

export default [
  fields.id,
  fields.name,
  fields.value,
  fields.role,
  fields.createdAt,
  fields.updatedAt,
];
