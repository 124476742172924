import * as yup from 'yup';

import GenericField from '../../../modules/shared/fields/genericField';

export default class JsonField extends GenericField {
  constructor(name: string, label: string) {
    super(name, label);
  }

  forView(value: any) {
    return value;
  }

  forFormInitialValue(value: any) {
    return value;
  }

  forInput: undefined;

  forForm() {
    const yupChain = yup.mixed().label(this.label);
    return yupChain;
  }

  forFilter() {
    const yupChain = yup.mixed().label(this.label);
    return yupChain;
  }

  forExport() {
    const yupChain = yup
      .mixed()
      .label(this.label)
      .transform((value, originalValue) => JSON.stringify(originalValue, null, 2));
    return yupChain;
  }

  forImport() {
    const yupChain = yup.mixed().label(this.label);
    return yupChain;
  }

  literalMapToValue: undefined;
}
