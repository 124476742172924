import authAxios from 'modules/shared/axios/authAxios';

export default class OrganizationTypeService {
  static async update(id, data) {
    const body = {
        id,
        data,
      },
      response = await authAxios.put(`/organization-type/${id}`, body);

    return response.data;
  }

  static async destroyAll(ids) {
    const params = {
        ids,
      },
      response = await authAxios.delete('/organization-type', {
        params,
      });

    return response.data;
  }

  static async create(data) {
    const body = {
        data,
      },
      response = await authAxios.post('/organization-type', body);

    return response.data;
  }

  static async import(values, importHash) {
    const body = {
        data: values,
        importHash,
      },
      response = await authAxios.post('/organization-type/import', body);

    return response.data;
  }

  static async find(id) {
    const response = await authAxios.get(`/organization-type/${id}`);
    return response.data;
  }

  static async list(filter, orderBy, limit, offset) {
    const params = {
        filter,
        orderBy,
        limit,
        offset,
      },
      response = await authAxios.get('/organization-type', {
        params,
      });

    return response.data;
  }

  static async listAutocomplete(query, limit) {
    const params = {
        query,
        limit,
      },
      response = await authAxios.get('/organization-type/autocomplete', {
        params,
      });

    return response.data;
  }
}
