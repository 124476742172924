import { i18n } from 'i18n';
import importerActions from 'modules/shared/importer/importerActions';
import fields from 'modules/status/importer/statusImporterFields';
import selectors from 'modules/status/importer/statusImporterSelectors';
import StatusService from 'modules/status/statusService';

export default importerActions(
  'STATUS_IMPORTER',
  selectors,
  StatusService.import,
  fields,
  i18n('entities.status.importer.fileName'),
);
