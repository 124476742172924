import { i18n } from 'i18n';
import BooleanField from 'modules/shared/fields/booleanField.ts';
import DateTimeField from 'modules/shared/fields/dateTimeField.ts';
import DateTimeRangeField from 'modules/shared/fields/dateTimeRangeField.ts';
import DecimalField from 'modules/shared/fields/decimalField.ts';
import DecimalRangeField from 'modules/shared/fields/decimalRangeField.ts';
import IdField from 'modules/shared/fields/idField.ts';
import RelationToManyField from 'modules/shared/fields/relationToManyField.ts';
import RelationToOneField from 'modules/shared/fields/relationToOneField.ts';
import StringField from 'modules/shared/fields/stringField.ts';

function label(name) {
  return i18n(`entities.organization.fields.${name}`);
}

const fields = {
  id: new IdField('id', label('id')),
  name: new StringField('name', label('name'), { required: true }),
  url: new StringField('url', label('url'), {}),
  balance: new DecimalField('balance', label('balance'), {
    // "required": true
  }),
  users: new RelationToManyField('users', label('users'), {}),
  // Owner: new RelationToOneField('owner', label('owner'), {}),
  owner: new IdField('owner', label('owner'), { required: true }),
  email: new StringField('email', label('email'), { required: true }),
  organizationCategory: new RelationToManyField(
    'organizationCategory',
    label('organizationCategory'),
    {},
  ),
  organizationType: new RelationToOneField('organizationType', label('organizationType'), {}),
  status: new RelationToOneField('status', label('status'), {
    required: true,
  }),
  active: new BooleanField('active', label('active')),
  contentOwned: new RelationToManyField('contentOwned', label('contentOwned'), {}),
  transactions: new RelationToManyField('transactions', label('transactions'), {}),
  //  AnalyticsEvents: new RelationToManyField('analyticsEvents', label('analyticsEvents'), {}),
  organizationSector: new RelationToOneField('organizationSector', label('organizationSector'), {}),
  createdAt: new DateTimeField('createdAt', label('createdAt')),
  updatedAt: new DateTimeField('updatedAt', label('updatedAt')),
  createdAtRange: new DateTimeRangeField('createdAtRange', label('createdAtRange')),
  balanceRange: new DecimalRangeField('balanceRange', label('balanceRange')),
  totalSpent: new DecimalField('totalSpent', label('totalSpent')), // Todo check
  budgetAvailable: new DecimalField('budgetAvailable', label('budgetAvailable')), // Todo check
  remainingBudgetAllocated: new DecimalField( // Todo check
    'remainingBudgetAllocated',
    label('remainingBudgetAllocated'),
  ),
};

export default {
  fields,
};
