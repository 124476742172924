import { i18n } from 'i18n';
import { getHistory } from 'modules/store';
import Message from '~/view/shared/message';

import Errors from '../../../modules/shared/error/errors';
import getCommonActions from './getCommonActions';

const getFormActions = ({
  service,
  path,
  createSuccessMessage = '',
  updateSuccessMessage = '',
}) => {
  const { doFind, reset, ...rest } = getCommonActions({ service, path, prefix: 'FORM' });
  const actions = {
    ...rest,

    FIND_STARTED: `${path}_FORM_FIND_STARTED`,
    FIND_SUCCESS: `${path}_FORM_FIND_SUCCESS`,
    FIND_ERROR: `${path}_FORM_FIND_ERROR`,

    CREATE_STARTED: `${path}_FORM_CREATE_STARTED`,
    CREATE_SUCCESS: `${path}_FORM_CREATE_SUCCESS`,
    CREATE_ERROR: `${path}_FORM_CREATE_ERROR`,

    UPDATE_STARTED: `${path}_FORM_UPDATE_STARTED`,
    UPDATE_SUCCESS: `${path}_FORM_UPDATE_SUCCESS`,
    UPDATE_ERROR: `${path}_FORM_UPDATE_ERROR`,
  };
  const goBack = (back) => {
    if (back) {
      getHistory().push(back);
      window && window.location.reload();
    } else {
      getHistory().push(`/${path}`);
    }
  };
  const doNew = reset;

  const doCreate = (values) => async (dispatch) => {
    if (service && service.create) {
      try {
        dispatch({
          type: actions.CREATE_STARTED,
        });

        await service.create(values);

        dispatch({
          type: actions.CREATE_SUCCESS,
        });

        Message.success(createSuccessMessage || i18n('entities.common.create.success'));

        goBack();
      } catch (error) {
        const errorData = Errors.getErrorData(error);
        const errorMessage = errorData
          ? errorData.errorMessage
          : i18n('errors.defaultErrorMessage');

        Message.error(errorMessage);

        dispatch({
          type: actions.CREATE_ERROR,
          payload: errorData,
        });
      }
    } else {
      goBack();
    }
  };
  const doUpdate = (id, values) => async (dispatch, _getState) => {
    const path = `/directory/company/${values.slug}`;
    if (service && service.update) {
      try {
        dispatch({
          type: actions.UPDATE_STARTED,
        });

        await service.update(id, values);

        dispatch({
          type: actions.UPDATE_SUCCESS,
        });

        Message.success(updateSuccessMessage || i18n('entities.common.update.success'));

        goBack(path);
      } catch (error) {
        const errorData = Errors.getErrorData(error);
        const errorMessage = errorData
          ? errorData.errorMessage
          : i18n('errors.defaultErrorMessage');

        Message.error(errorMessage);

        dispatch({
          type: actions.UPDATE_ERROR,
          payload: errorData,
        });
      }
    } else {
      goBack(path);
    }
  };

  return {
    ...actions,
    doNew,
    doFind,
    doCreate,
    doUpdate,
  };
};

export default getFormActions;
