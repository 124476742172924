import model from 'modules/device/deviceModel';

const { fields } = model;

export default [
  fields.id,
  fields.name,
  fields.channels,
  fields.assigned,
  fields.status,
  fields.lastSeen,
  fields.hdmiActive,
  fields.currentImpressions,
  fields.ip,
  fields.gps,
  fields.deviceProfile,
  fields.deviceNetwork,
  fields.createdAt,
  fields.updatedAt,
];
