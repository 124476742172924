import { toDate, zonedTimeToUtc } from 'date-fns-tz';
import { isValid } from 'date-fns/esm';
import moment from 'moment';
import * as yup from 'yup';

import { i18n } from '../../../i18n';
import GenericField from '../../../modules/shared/fields/genericField';

const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;

const transformToDate = (date: string): Date | null => (date ? toDate(date) : null);
const transformToLocaleDateString = (date: Date): string => date.toISOString();
const transformToUTCString = (date: Date): string => {
  const utcDate = zonedTimeToUtc(date, tz);
  const isoDateString = utcDate.toISOString();
  return isoDateString;
};

export default class DateField extends GenericField {
  required: boolean;

  constructor(name: string, label: string, { required = false } = {}) {
    super(name, label);

    this.required = required;
  }

  forView(value: any) {
    return value;
  }

  forFormInitialValue(date: string) {
    return date;
  }

  forInput: undefined;

  forFilter() {
    return yup
      .mixed()
      .nullable(true)
      .label(this.label)
      .test('is-date', i18n('validation.mixed.default'), (date) => {
        if (!date) {
          return true;
        }

        const valid = isValid(new Date(date));

        return valid;
      })
      .transform(transformToUTCString);
  }

  forForm() {
    let yupChain = yup
      .mixed()
      .nullable(true)
      .label(this.label)
      .test('is-date', i18n('validation.mixed.default'), (date) => {
        if (!date) {
          return true;
        }

        const valid = isValid(new Date(date));

        return valid;
      })
      .transform(transformToUTCString);

    if (this.required) {
      yupChain = yupChain.required();
    }

    return yupChain;
  }

  forExport() {
    return yup.mixed().label(this.label);
  }

  forImport() {
    let yupChain = yup
      .mixed()
      .nullable(true)
      .label(this.label)
      .test('is-date', i18n('validation.mixed.default'), (date) => {
        if (!date) {
          return true;
        }

        const valid = isValid(new Date(date));

        return valid;
      });

    if (this.required) {
      yupChain = yupChain.required();
    }

    return yupChain;
  }

  literalMapToValue: undefined;
}
