import { i18n } from 'i18n';
import BooleanField from 'modules/shared/fields/booleanField.ts';
import DateTimeField from 'modules/shared/fields/dateTimeField.ts';
import DateTimeRangeField from 'modules/shared/fields/dateTimeRangeField.ts';
import DecimalField from 'modules/shared/fields/decimalField.ts';
import DecimalRangeField from 'modules/shared/fields/decimalRangeField.ts';
import IdField from 'modules/shared/fields/idField.ts';
import IntegerRangeField from 'modules/shared/fields/integerRangeField.ts';
import RelationToManyField from 'modules/shared/fields/relationToManyField.ts';
import RelationToOneField from 'modules/shared/fields/relationToOneField.ts';
import StringField from 'modules/shared/fields/stringField.ts';

function label(name) {
  return i18n(`entities.market.fields.${name}`);
}

const fields = {
  id: new IdField('id', label('id')),
  name: new StringField('name', label('name'), {}),
  contentOwned: new RelationToManyField('contentOwned', label('content'), {}),
  organization: new RelationToOneField('organization', label('organization'), {}),
  storefront: new RelationToManyField('storefront', label('storefront'), {}),
  analytics: new RelationToManyField('analytics', label('analytics'), {}),
  locationGroup: new RelationToManyField('locationGroup', label('locationGroups'), {}),
  cpmMax: new DecimalField('cpmMax', label('cpmMax'), {}),
  actualCPM: new DecimalField('actualCPM', label('actualCPM'), {}),
  budget: new DecimalField('budget', label('budget'), {}),
  spent: new DecimalField('spent', label('spent'), {}),
  visitors: new DecimalField('visitors', label('visitors'), {}),
  impressions: new DecimalField('impressions', 'Impressions', {}),
  naAmount: new DecimalField('naAmount', label('naAmount'), {}),
  plays: new DecimalField('plays', label('plays'), {}),
  startDate: new DateTimeField('startDate', label('startDate'), {}),
  endDate: new DateTimeField('endDate', label('endDate'), {}),
  active: new BooleanField('active', label('active')),
  spendType: new StringField('spendType', label('spendType'), {}),
  spendRatio: new StringField('spendRatio', label('spendRatio'), {}),
  spendUnit: new StringField('spendUnit', label('spendUnit'), {}),
  spendUnitFrequency: new StringField('spendUnitFrequency', label('spendUnitFrequency'), {}),
  createdAt: new DateTimeField('createdAt', label('createdAt')),
  updatedAt: new DateTimeField('updatedAt', label('updatedAt')),
  createdAtRange: new DateTimeRangeField('createdAtRange', label('createdAtRange')),
  netValueRange: new DecimalRangeField('netValueRange', label('netValueRange')),
  contentBidDateRange: new DateTimeRangeField('contentBidDateRange', label('contentBidDateRange')),
  contentBidValueRange: new DecimalRangeField(
    'contentBidValueRange',
    label('contentBidValueRange'),
  ),
  elapsedLiveTimeRange: new IntegerRangeField(
    'elapsedLiveTimeRange',
    label('elapsedLiveTimeRange'),
  ),
};

export default {
  fields,
};
