import Axios from 'axios';
import config from 'config';
import moment from 'moment';
import Qs from 'qs';

const AxiosInstance = Axios.create({
  baseURL: config.backendUrl,
  paramsSerializer(params) {
    return Qs.stringify(params, {
      arrayFormat: 'brackets',
      filter: (prefix, value: Date | unknown) => {
        if (value instanceof moment || value instanceof Date) {
          return (value as Date).toISOString();
        }

        return value;
      },
    });
  },
});

export { AxiosInstance as Axios };
