import authAxios from 'modules/shared/axios/authAxios';
import { InvoiceDto, StripeInvoiceCreateDTO } from './dto/invoice.dto';

export default class InvoiceService {
  static async addItem(body) {
    const response = await authAxios.post('/invoice/item', body);

    return response.data;
  }

  static async deleteCartItem(data) {
    const body = {
      data,
    };

    const response = await authAxios.delete('/invoice/item', body);

    return response.data;
  }

  static async deleteInvoice(id) {
    const params = {
      id,
    };

    const response = await authAxios.delete('/invoice', {
      params,
    });
    return response.data;
  }

  static async create(data) {
    const body = {
      data,
    };

    const response = await authAxios.post('/invoice', body);

    return response.data;
  }

  static async find(id = null) {
    let response;
    if (id === null) {
      response = await authAxios.get('/invoice');
    } else {
      response = await authAxios.get('/invoice/' + id);
    }
    return response.data;
  }

  static async updateInvoice(id, data) {
    const body = {
      id,
      data,
    };

    const response = await authAxios.post('/invoice', body);
    return response.data;
  }

  static async getAllInvoices() {
    const response = await authAxios.get<InvoiceDto[]>('/invoice/stripe/list', {
      withCredentials: true,
    });
    return response.data;
  }

  static async getStripeClientSecret(amount) {
    const response = await authAxios.get(`/invoice/stripe_client_id/${amount}`);
    return response.data;
  }

  static async generateStripeInvoiceFromInvoice(
    id: string,
    data: StripeInvoiceCreateDTO,
  ): Promise<string> {
    const body = {
      id,
      data,
    };

    const { data: stripeInvoiceId } = await authAxios.post<string>('/stripeInvoice', body);

    return stripeInvoiceId;
  }

  static async syncStripeInvoiceWithQBO(stripeInvoiceId: string) {
    await authAxios.get(`quickbook/invoice/sync/stripe/${stripeInvoiceId}`);
  }
}
