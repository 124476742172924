import { fields } from 'modules/storefront/storefrontModel.ts';

export default [
  fields.id,
  fields.name,
  fields.unitNumber,
  fields.geopathId,
  fields.description,
  fields.physicalAddress,
  fields.latitude,
  fields.longitude,
  fields.inventoryTypes,
  fields.facing,
  fields.position,
  fields.weeklyAdultImpressionsCount,
  fields.uniqueAdultAdReachedCount,
  fields.averageAdultFrequencyReach,
  fields.grp,
  fields.city,
  fields.state,
  fields.zip,
  fields.country,
  fields.spotLength,
  fields.loopLength,
  fields.sov,
  fields.advertisersCount,
  fields.fourWeekRateNegotiatedCost,
  fields.fourWeekRateCardCost,
  fields.productionCost,
  fields.installCost,
  fields.panelSize,
  fields.vinylSize,
  fields.shippingAddress,
  fields.illuminated,
  fields.staticImageAd,
  fields.motionGraphicAd,
  fields.audioAd,
  fields.dimensions,
  fields.lengthLimit,
  fields.fileSizeLimitMB,
  fields.categoryRestrictions,
  fields.notes,
  fields.nextAvailableDate,
];
