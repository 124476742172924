import authAxios from 'modules/shared/axios/authAxios';

export default class DeviceService {
  static async update(id, data) {
    const body = {
        id,
        data,
      },
      response = await authAxios.put(`/device/${id}`, body);

    return response.data;
  }

  static async destroyAll(ids) {
    const params = {
        ids,
      },
      response = await authAxios.delete('/device', {
        params,
      });

    return response.data;
  }

  static async create(data) {
    const body = {
        data,
      },
      response = await authAxios.post('/device', body);

    return response.data;
  }

  static async import(values, importHash) {
    const body = {
        data: values,
        importHash,
      },
      response = await authAxios.post('/device/import', body);

    return response.data;
  }

  static async find(id) {
    const response = await authAxios.get(`/device/${id}`);
    return response.data;
  }

  static async list(filter, orderBy, limit, offset) {
    const params = {
        filter,
        orderBy,
        limit,
        offset,
      },
      response = await authAxios.get('/device', {
        params,
      });

    return response.data;
  }

  static async listAutocomplete(query, limit) {
    const params = {
        query,
        limit,
      },
      response = await authAxios.get('/device/autocomplete', {
        params,
      });

    return response.data;
  }
}
