import destroy from 'modules/customerProfile/destroy/customerProfileDestroyReducers';
import form from 'modules/customerProfile/form/customerProfileFormReducers';
import importerReducer from 'modules/customerProfile/importer/customerProfileImporterReducers';
import list from 'modules/customerProfile/list/customerProfileListReducers';
import view from 'modules/customerProfile/view/customerProfileViewReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
});
