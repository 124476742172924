import PermissionChecker from 'modules/auth/permissionChecker';
import { FC, ReactNode } from 'react';
import { Redirect, Route } from 'react-router-dom';

interface PublicRouteProps extends RouteComponentProps {
  component: ReactNode;
  currentUser: unknown;
}

const PublicRoute: FC<PublicRouteProps> = ({ component: Component, currentUser, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        const permissionChecker = new PermissionChecker(currentUser);

        if (permissionChecker.isAuthenticated) {
          return <Redirect to="/" />;
        }

        return <Component {...props} />;
      }}
    />
  );
};

export default PublicRoute;
