import { ApiRoutes } from 'modules/apiRoutes';

export const INVENTORY_DIRECTORY_CARD = 'CARD';
export const PARTNER_DETAIL = 'PARTNER_DETAIL';
export const PARTNER_DIRECTORY_CARD = 'CARD';

export const ACCOUNT_TYPE = {
  OWNER: 'owner', // TODO remove
  HOST: 'host', // TODO remove
  ANONYMOUS: 'anonymous',
  ADMIN: 'admin',
  ADVERTISER: 'advertiser',
  PARTNER: 'partner',
};

export const ACCOUNT_ROLE = {
  SUPER_ADMIN: [ACCOUNT_TYPE.OWNER, ACCOUNT_TYPE.HOST, ACCOUNT_TYPE.ADMIN],
  ADMIN: [ACCOUNT_TYPE.PARTNER],
  PARTNER: [ACCOUNT_TYPE.PARTNER],
  ADVERTISER: [ACCOUNT_TYPE.ADVERTISER],
  OTHER: [ACCOUNT_TYPE.ANONYMOUS, ACCOUNT_TYPE.ADVERTISER], // TODO check
};

export const ACCOUNT_TYPE_LABELS = {
  HOST: 'Host', // TODO remove
  ANONYMOUS: 'Anonymous',
  ADMIN: 'Admin',
  ADVERTISER: 'Advertiser',
  PARTNER: 'Partner',
};

export const USER_FIELDS = {
  id: 'id',
  companyId: 'companyId',
  accountType: 'accountType',
  active: 'active',
  city: 'city',
  email: 'email',
  emails: 'emails',
  name: 'name',
  password: 'password',
  phoneNumber: 'phoneNumber',
  phone: 'phone',
  state: 'state',
  street: 'street',
  zip: 'zip',
  tosAgreed: 'tosAgreed',
  url: 'url',
  groupName: 'groupName',
  partnerName: 'partnerName',
  avatarsIam: 'avatarsIam',
  rolesRequired: 'rolesRequired',
};

export const INPUT_COMPONENT = {
  TEXT: 'text',
  CHECKBOX: 'checkbox',
  SELECT: 'select',
};

export const SIGN_UP_OPTIONS = [
  {
    accountType: ACCOUNT_TYPE.ADVERTISER,
    label: ACCOUNT_TYPE_LABELS.ADVERTISER,
    title: 'Advertiser Onboarding',
    route: ApiRoutes.SIGN_UP_ADVERTISER,
    fields: [
      { name: USER_FIELDS.name, autoFocus: true, required: true },
      { name: USER_FIELDS.email, type: 'email', required: true },
      { name: USER_FIELDS.password, type: 'password', required: true },
      { name: USER_FIELDS.phone, type: 'tel' },
      { name: USER_FIELDS.url, type: 'url' },
      { name: USER_FIELDS.state },
      { name: USER_FIELDS.city },
      { name: USER_FIELDS.street },
      { name: USER_FIELDS.zip },
      // { name: USER_FIELDS.active, inputComponent: INPUT_COMPONENT.CHECKBOX },
    ],
  },
  {
    accountType: ACCOUNT_TYPE.PARTNER,
    label: ACCOUNT_TYPE_LABELS.PARTNER,
    title: 'Partner Onboarding',
    route: ApiRoutes.SIGN_UP_PARTNER,
    fields: [
      { name: USER_FIELDS.name, autoFocus: true, required: true },
      { name: USER_FIELDS.email, type: 'email', required: true },
      { name: USER_FIELDS.password, type: 'password', required: true },
      { name: USER_FIELDS.phone, type: 'tel' },
      { name: USER_FIELDS.url, type: 'url' },
      { name: USER_FIELDS.state },
      { name: USER_FIELDS.city },
      { name: USER_FIELDS.street },
      { name: USER_FIELDS.zip },
      // { name: USER_FIELDS.active, inputComponent: INPUT_COMPONENT.CHECKBOX },
    ],
  },
];

export const SERVICE_TYPE = [
  {
    value: 'platform',
    label: 'Platform',
  },
  {
    value: 'billboards',
    label: 'Billboards',
  },
  {
    value: 'staticBillboards',
    label: 'Static Billboards',
  },
  {
    value: 'digitalBillboards',
    label: 'Digital Billboards',
  },
  {
    value: 'tvScreens',
    label: 'TV Screens',
  },
  {
    value: 'posters',
    label: 'Posters',
  },
  {
    value: 'platform',
    label: 'Platform',
  },
  {
    value: 'vehicles',
    label: 'Vehicles',
  },
  {
    value: 'ledTrucks',
    label: 'LED Trucks',
  },
  {
    value: 'wrappedVehicles',
    label: 'Wrapped Vehicles', // Change from Wrapped Vehicles
  },
];

export const DEFAULT_LOCATION = {
  latitude: 40.7259, // Todo change
  longitude: -73.9259, // Todo change
};

export const DEFAULT_FORM_MAX_WIDTH = 1440;
export const DEFAULT_PAGE_SIZES = [25, 50, 100];
export const DEFAULT_PAGE_SIZE = DEFAULT_PAGE_SIZES[0]; // 50, 20

export const MEDIA_TYPE = {
  key: 'name',
  value: 'Media Company',
};
