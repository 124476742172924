import model from 'modules/market/marketModel';

const { fields } = model;

export default [
  fields.contentOwned,
  fields.organization,
  fields.transactions,
  fields.analytics,
  fields.netValue,
  fields.contentBidDate,
  fields.contentBidValue,
  fields.elapsedLiveTime,
  fields.payload,
];
