import { createTheme } from '@mui/material/styles';

import './types/createPalette.d';
import './types/createTypography.d';
import './types/material.d';

import overrides from './overrides';
import palette from './palette';
import typography from './typography';

const baseTheme = {
  palette,
  typography,
  components: overrides,
};

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const theme = createTheme(baseTheme);
