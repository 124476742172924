import actions from '~/modules/storefrontAvailability/form/storefrontAvailabilityFormActions';

const initialData = {
  record: null,
  findLoading: false,
  findError: null,
  createLoading: false,
  createError: null,
  updateLoading: false,
  updateError: null,
};

export default (state = initialData, { type, payload }) => {
  switch (type) {
    case actions.RESET: {
      return {
        ...initialData,
      };
    }
    case actions.FIND_STARTED: {
      return {
        ...state,
        record: null,
        findLoading: true,
        findError: null,
      };
    }
    case actions.FIND_SUCCESS: {
      return {
        ...state,
        record: payload,
        findLoading: false,
      };
    }
    case actions.FIND_ERROR: {
      return {
        ...state,
        record: null,
        findLoading: false,
        findError: payload.error,
      };
    }
    case actions.CREATE_STARTED: {
      return {
        ...state,
        createLoading: true,
        createError: null,
      };
    }
    case actions.CREATE_SUCCESS: {
      return {
        ...state,
        createLoading: false,
      };
    }
    case actions.CREATE_ERROR: {
      return {
        ...state,
        createLoading: false,
        createError: payload.error,
      };
    }
    case actions.UPDATE_STARTED: {
      return {
        ...state,
        updateLoading: true,
        updateError: null,
      };
    }
    case actions.UPDATE_SUCCESS: {
      return {
        ...state,
        updateLoading: false,
      };
    }
    case actions.UPDATE_ERROR: {
      return {
        ...state,
        updateLoading: false,
        updateError: payload.error,
      };
    }
    default: {
      return state;
    }
  }
};
