import Alert from '~/view/shared/Alert/Alert';
import { AlertToast } from '~/view/shared/Alert/AlertContainer';
import { selectErrorMessage } from './errors';

class FlightConflictErrorHandler {
  static showConflictToast(error) {
    const { message, conflicts } = selectErrorMessage(error);
    const alert = FlightConflictErrorHandler.#conflictMessage(message, conflicts);
    Alert.error(alert);
  }

  static #conflictMessage(message, conflicts): AlertToast {
    let names = ``;

    for (let i = 0; i < conflicts.length; i++) {
      const { inventoryName } = conflicts[i];
      if (conflicts.length > 1 && i === conflicts.length - 1) {
        names += ` and ${inventoryName}`;
      } else {
        if (i === 0) {
          names += `${inventoryName}`;
        } else {
          names += `, ${inventoryName}`;
        }
      }
    }

    const alert: AlertToast = {
      majorText: message,
      text: `${names} are not available on this campaign.`,
    };

    return alert;
  }
}

export default FlightConflictErrorHandler;
