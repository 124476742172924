import authAxios from 'modules/shared/axios/authAxios';

const PATH = 'serviceType';

export default class ServiceTypeService {
  static async list(filter, orderBy, limit, offset) {
    const params = {
        filter,
        orderBy,
        limit,
        offset,
      },
      response = await authAxios.get(`/${PATH}`, {
        params,
      });

    return response.data;
  }
}
