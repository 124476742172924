import { ThunkAction } from 'redux-thunk';
import { RootState } from '~/types/models/rootState';
import { BasicAction, BasicErrorAction } from '../../common';

// String action labels
export const RFPManagementActionTypes = {
  FETCH_RFPS_START: `RFP_FETCH_START`,
  FETCH_RFPS_SUCCESS: `RFP_FETCH_SUCCESS`,
  FETCH_RFPS_ERROR: `RFP_FETCH_ERROR`,
  FETCH_RFPS_INVENTORY_START: `RFP_FETCH_INVENTORY_START`,
  FETCH_RFPS_INVENTORY_SUCCESS: `RFP_FETCH_INVENTORY_SUCCESS`,
  FETCH_RFPS_INVENTORY_ERROR: `RFP_FETCH_INVENTORY_ERROR`,
} as const;

type RFPManagerActionTypeLiterals = typeof RFPManagerActionTypes;

type BasicRFPManagementActions =
  | BasicAction<RFPManagerActionTypeLiterals>
  | BasicErrorAction<RFPManagerActionTypeLiterals, unknown>;

export interface RFPManagementActions {
  doFetchRFPRequests: (
    campaignId: string,
  ) => ThunkAction<void, RootState, unknown, BasicRFPManagementActions>; // TODO Create types for single actions better
  doFetchAggregatedInventory: (
    campaignId: string,
  ) => ThunkAction<void, RootState, unknown, BasicRFPManagementActions>;
}
