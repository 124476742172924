import destroy from 'modules/content/destroy/contentDestroyReducers';
import form from 'modules/content/form/contentFormReducers';
import importerReducer from 'modules/content/importer/contentImporterReducers';
import list from 'modules/content/list/contentListReducers';
import view from 'modules/content/view/contentViewReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
});
