import { i18n } from 'i18n';
import DateTimeField from 'modules/shared/fields/dateTimeField.ts';
import DateTimeRangeField from 'modules/shared/fields/dateTimeRangeField.ts';
import IdField from 'modules/shared/fields/idField.ts';
import StringField from 'modules/shared/fields/stringField.ts';

function label(name) {
  return i18n(`entities.sector.fields.${name}`);
}

const fields = {
  id: new IdField('id', label('id')),
  name: new StringField('name', label('name'), {
    required: true,
    min: 2,
  }),
  value: new StringField('value', label('value'), {}),
  classification: new StringField('classification', label('classification'), {}),
  createdAt: new DateTimeField('createdAt', label('createdAt')),
  updatedAt: new DateTimeField('updatedAt', label('updatedAt')),
  createdAtRange: new DateTimeRangeField('createdAtRange', label('createdAtRange')),
};

export default {
  fields,
};
