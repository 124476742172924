import { Dispatch, Store } from 'redux';

import { i18n } from '../../../i18n';
import Errors from '../../../modules/shared/error/errors';
import { getHistory } from '../../../modules/store';
import Message from '../../../view/shared/message';
import PriceRequestService from '../priceRequestService';

const prefix = 'PRICE_REQUEST',
  actions = {
    RESET: `${prefix}_RESET`,

    FIND_STARTED: `${prefix}_FIND_STARTED`,
    FIND_SUCCESS: `${prefix}_FIND_SUCCESS`,
    FIND_ERROR: `${prefix}_FIND_ERROR`,

    CREATE_STARTED: `${prefix}_CREATE_STARTED`,
    CREATE_SUCCESS: `${prefix}_CREATE_SUCCESS`,
    CREATE_ERROR: `${prefix}_CREATE_ERROR`,

    UPDATE_STARTED: `${prefix}_UPDATE_STARTED`,
    UPDATE_SUCCESS: `${prefix}_UPDATE_SUCCESS`,
    UPDATE_ERROR: `${prefix}_UPDATE_ERROR`,

    /*
     * DoFetch: () => async (dispatch: Dispatch) => {
     *     try {
     *         dispatch({
     *             type: actions.FIND_STARTED,
     *         });
     */

    //         Const records = await PriceRequestService.fetch();

    /*
     *         Dispatch({
     *             type: actions.FIND_SUCCESS,
     *             payload: records,
     *         });
     *     } catch (error) {
     *         Errors.handle(error);
     */

    /*
     *         Dispatch({
     *             type: actions.FIND_ERROR,
     *         });
     *     }
     * },
     */

    /*
     * DoCreate: (data: any) => async (dispatch: Dispatch) => {
     * try {
     *     dispatch({
     *         type: actions.CREATE_STARTED,
     *     });
     */

    //     Await MarketService.create(values);

    /*
     *     Dispatch({
     *         Type: actions.CREATE_SUCCESS,
     *     });
     */

    //     Message.success(i18n('entities.market.create.success'));

    /*
     *     GetHistory().push('/market');
     * } catch (error) {
     *     Errors.handle(error);
     */

    /*
     *     Dispatch({
     *         Type: actions.CREATE_ERROR,
     *     });
     * }
     * },
     */

    doSubmit: (id: string, accept: boolean) => async (dispatch: Dispatch, getState: any) => {
      try {
        dispatch({
          type: actions.UPDATE_STARTED,
        });

        await PriceRequestService.answerRequest(id, accept);

        dispatch({
          type: actions.UPDATE_SUCCESS,
        });

        Message.success(i18n('entities.priceRequest.submit.success'));
      } catch (error) {
        Errors.handle(error);

        dispatch({
          type: actions.UPDATE_ERROR,
        });
      }
    },
  };

export default actions;
