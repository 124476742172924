import { FC, forwardRef, PropsWithChildren } from 'react';

import { generateUtilityClasses, SxProps, Theme } from '@mui/material';
import clsx from 'clsx';
import { BaseAlert, BaseAlertClasses, BaseAlertProps } from '~/view/shared/OSAIAlert/BaseAlert';

const classes = {
  ...generateUtilityClasses('ErrorAlert', ['root']),
};

const styles = {
  [`&.${classes.root}`]: {
    background: (theme) => theme.palette.error.background,
    color: (theme) => theme.palette.error.dark,
    [`& svg`]: {
      fill: (theme) => theme.palette.error.dark,
    },
  },
  [`.${BaseAlertClasses.close}`]: {
    [`& svg`]: {
      fill: (theme) => theme.palette.error.dark,
    },
  },
};

export interface ErrorAlertProps extends BaseAlertProps {
  sx?: SxProps<Theme>;
  className?: string;
}

const ErrorAlert: FC<ErrorAlertProps> = forwardRef<HTMLDivElement, ErrorAlertProps>(
  ({ className, sx, children, ...props }: PropsWithChildren<ErrorAlertProps>, ref) => {
    return (
      <BaseAlert
        ref={ref}
        {...props}
        variant="outlined"
        severity="error"
        className={clsx(className, classes.root)}
        sx={Array.isArray(sx) ? sx.concat([styles]) : { ...sx, ...styles }}
      >
        {children}
      </BaseAlert>
    );
  },
);

ErrorAlert.displayName = 'ErrorAlert';
export { ErrorAlert };

export { classes as ErrorAlertClasses };
