import actions from '~/modules/storefrontAvailability/list/storefrontAvailabilityListActions';

const INITIAL_PAGE_SIZE = 10;
const initialPagination = {
  current: 1,
  pageSize: INITIAL_PAGE_SIZE,
};
const initialData = {
  rows: [],
  analytics: {},
  futureRows: [],
  count: 0,
  pagination: initialPagination,
  filter: {},
  sorter: {},
  loading: false,
  error: null,
};

export default (state = initialData, { type, payload }) => {
  switch (type) {
    case actions.RESETED: {
      return {
        ...initialData,
      };
    }
    case actions.PAGINATION_CHANGED: {
      return {
        ...state,
        pagination: payload || {
          current: 1,
          pageSize: INITIAL_PAGE_SIZE,
        },
      };
    }
    case actions.SORTER_CHANGED: {
      return {
        ...state,
        sorter: payload || {},
      };
    }
    case actions.FETCH_STARTED: {
      return {
        ...state,
        filter: payload ? payload.filter : {},
        pagination:
          payload && payload.keepPagination
            ? state.pagination
            : {
                current: 1,
                pageSize: INITIAL_PAGE_SIZE,
              },
        loading: true,
        error: null,
      };
    }
    case actions.FETCH_SUCCESS: {
      return {
        ...state,
        rows: payload.rows,
        count: payload.count,
        loading: false,
      };
    }
    case actions.FETCH_ERROR: {
      return {
        ...state,
        rows: [],
        count: 0,
        loading: false,
        error: payload.error,
      };
    }
    case actions.GET_FUTURE_STARTED: {
      return {
        ...state,
        loading: true,
        error: null,
      };
    }
    case actions.GET_FUTURE_SUCCESS: {
      const reDateParse = /(\d{4})-(\d{2})-(\d{2}).*/;
      const temp = {
        ...state,
        futureRows: payload.map((payloadItem) => ({
          ...payloadItem,
          startDate: payloadItem.startDate.replace(reDateParse, '$2/$3/$1'),
          endDate: payloadItem.endDate.replace(reDateParse, '$2/$3/$1'),
        })),
        loading: false,
      };

      return temp;
    }
    case actions.GET_FUTURE_ERROR: {
      return {
        ...state,
        futureRows: [],
        loading: false,
        error: payload.error,
      };
    }
    case actions.GET_ATHENA_STARTED: {
      return {
        ...state,
        loadingAnalytics: true,
        errorAnalytics: null,
      };
    }
    case actions.GET_ATHENA_SUCCESS: {
      return {
        ...state,
        analytics: payload,
        loadingAnalytics: false,
        errorAnalytics: true,
      };
    }
    case actions.GET_ATHENA_ERROR: {
      return {
        ...state,
        analytics: {},
        loadingAnalytics: false,
        errorAnalytics: true,
      };
    }
    default: {
      return state;
    }
  }
};
