import selectors from 'modules/ooh/list/oohListSelectors';
import service from 'modules/ooh/oohService';

import { getListActions } from '../../../modules/shared/actions';

export default getListActions({
  service,
  selectors,
  prefix: 'OOH_LIST',
});
