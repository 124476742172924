import { styled } from '@mui/material/styles';
import { ExtendedVariant, TypographyStyle } from '@mui/material/styles/createTypography';

const Body1 = styled('div')(({ theme }) => ({
  ...(theme.typography.body1 as Record<ExtendedVariant, TypographyStyle>),
  display: 'inline-block',
}));

const Body1Contrasted = styled('div')(({ theme }) => ({
  ...(theme.typography.body1 as Record<ExtendedVariant, TypographyStyle>),
  color: theme.palette.primary.contrastText,
}));

const Body1Bold = styled('div')(({ theme }) => ({
  ...(theme.typography.body1 as Record<ExtendedVariant, TypographyStyle>),
  fontWeight: 700,
  display: 'inline-block',
}));

const Body1ContrastedBold = styled('div')(({ theme }) => ({
  ...(theme.typography.body1 as Record<ExtendedVariant, TypographyStyle>),
  color: theme.palette.primary.contrastText,
  fontWeight: 700,
}));

const Body2Promo = styled('div')(({ theme }) => ({
  ...(theme.typography.body2 as Record<ExtendedVariant, TypographyStyle>),
  color: theme.palette.primary.main,
}));

const Body2 = styled('div')(({ theme }) => ({
  ...(theme.typography.body2 as Record<ExtendedVariant, TypographyStyle>),
}));

const H3 = styled('div')(({ theme }) => ({
  ...(theme.typography.h3 as Record<ExtendedVariant, TypographyStyle>),
  fontFamily: 'stolzl',
  fontWeight: 700,
}));

const H3Promo = styled('div')(({ theme }) => ({
  ...(theme.typography.h3 as Record<ExtendedVariant, TypographyStyle>),
  fontFamily: 'stolzl',
  fontWeight: 700,
}));

const H4 = styled('div')(({ theme }) => ({
  ...(theme.typography.h4 as Record<ExtendedVariant, TypographyStyle>),
  fontFamily: 'stolzl',
  fontWeight: 700,
}));

const H5 = styled('div')(({ theme }) => ({
  ...(theme.typography.h5 as Record<ExtendedVariant, TypographyStyle>),
  fontFamily: 'stolzl',
  fontWeight: 700,
}));

const H6 = styled('div')(({ theme }) => ({
  ...(theme.typography.h6 as Record<ExtendedVariant, TypographyStyle>),
  fontFamily: 'stolzl',
  fontWeight: 700,
}));

const H4Promo = styled('div')(({ theme }) => ({
  ...(theme.typography.h4 as Record<ExtendedVariant, TypographyStyle>),
  color: theme.palette.primary.main,
  fontFamily: 'stolzl',
  fontWeight: 700,
}));

const PromoButtonText = styled('div')(({ theme }) => ({
  ...(theme.typography.button as Record<ExtendedVariant, TypographyStyle>),
  color: theme.palette.primary.main,
  textTransform: 'initial',
}));

const PromoFilledButtonText = styled('div')(({ theme }) => ({
  ...(theme.typography.button as Record<ExtendedVariant, TypographyStyle>),
  color: theme.palette.primary.contrastText,
  textTransform: 'initial',
}));

export {
  Body1,
  Body1Bold,
  Body1Contrasted,
  Body1ContrastedBold,
  Body2Promo,
  Body2,
  H3,
  H3Promo,
  H4,
  H5,
  H6,
  H4Promo,
  PromoButtonText,
  PromoFilledButtonText,
};
