import _get from 'lodash/get';
import { createSelector } from 'reselect';

import { getFormSelector, getListSelector } from '../../shared/selectors';

/*
 * TODO: Doing custom selector is needed here as we try to implement hybrid structure btw list and form
 * like List of forms into one Page entity.
 * For future, create some hybrid or custom common constructor for such cases.
 */

const selectRawForList = (state: any) => _get(state, 'priceRequest.list'),
  selectRawForForm = (state: any) => _get(state, 'priceRequest.form');

export default getListSelector(selectRawForList);
