import authAxios from '~/modules/shared/axios/authAxios';

export default class StorefrontAvailabilityService {
  static async create(storefrontId, data) {
    const body = {
      ...data,
    };
    const response = await authAxios.post(`/storefront/${storefrontId}/flights`, body);
    return response.data;
  }

  static async update(storefrontId, flightId, data) {
    const body = {
        ...data,
      },
      response = await authAxios.put(`/storefront/${storefrontId}/flights/${flightId}`, body);

    return response.data;
  }

  static async destroy(storefrontId, flightId) {
    const response = await authAxios.delete(`/storefront/${storefrontId}/flights/${flightId}`);
    return response.data;
  }

  static async list({ storefrontId, filter, orderBy, limit, offset }) {
    const params = {
        filter,
        orderBy,
        limit,
        offset,
      },
      response = await authAxios.get(`/storefront/${storefrontId}/flights`, {
        params,
      });

    return response.data;
  }

  static async getFutureList({ storefrontId }) {
    const response = await authAxios.get(`/storefront/${storefrontId}/futureFlights`);

    return response.data;
  }
}
