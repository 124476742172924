const backendUrl = 'https://api-develop.onescreen.ai',
  gtmConfig = {
    containerId: process.env.REACT_APP_GTM_CONTAINER_ID || 'GTM-T7T5VM2',
  },
  segmentConfig = {
    key: 'WnpGDvb7eAaB7JYG4654ud81ZVzi7Wmx',
  };

export default {
  backendUrl,
  gtmConfig,
  segmentConfig,
};
