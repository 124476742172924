import destroy from 'modules/organization/destroy/organizationDestroyReducers';
import form from 'modules/organization/form/organizationFormReducers';
import importerReducer from 'modules/organization/importer/organizationImporterReducers';
import list from 'modules/organization/list/organizationListReducers';
import view from 'modules/organization/view/organizationViewReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
});
