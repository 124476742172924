import { i18n } from 'i18n';
import BooleanField from 'modules/shared/fields/booleanField.ts';
import DateTimeField from 'modules/shared/fields/dateTimeField.ts';
import IdField from 'modules/shared/fields/idField.ts';
import RelationToManyField from 'modules/shared/fields/relationToManyField.ts';
import RelationToOneField from 'modules/shared/fields/relationToOneField.ts';
import StringField from 'modules/shared/fields/stringField.ts';

function label(name) {
  return i18n(`entities.locationGroup.fields.${name}`);
}

const fields = {
  id: new IdField('id', label('id')),
  name: new StringField('name', label('name'), {}),
  organization: new RelationToOneField('organization', label('organization'), {}),
  locations: new RelationToManyField('locations', label('locations'), {}),
  locked: new BooleanField('locked', label('active')),
  createdAt: new DateTimeField('createdAt', label('createdAt')),
  updatedAt: new DateTimeField('updatedAt', label('updatedAt')),
};

export default {
  fields,
};
