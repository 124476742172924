const getFormReducer = (actions) => {
  const initialData = {
      record: null,
      findLoading: false,
      saveLoading: false,
      error: null,
    },
    reducer = (state = initialData, { type, payload }) => {
      if (type === actions.RESET) {
        return {
          ...initialData,
        };
      }

      if (type === actions.FIND_STARTED) {
        return {
          ...state,
          record: null,
          findLoading: true,
          error: null,
        };
      }

      if (type === actions.FIND_SUCCESS) {
        return {
          ...state,
          record: payload,
          findLoading: false,
        };
      }

      if (type === actions.FIND_ERROR) {
        return {
          ...state,
          record: null,
          findLoading: false,
        };
      }

      if (type === actions.CREATE_STARTED) {
        return {
          ...state,
          saveLoading: true,
          error: null,
        };
      }

      if (type === actions.CREATE_SUCCESS) {
        return {
          ...state,
          saveLoading: false,
        };
      }

      if (type === actions.CREATE_ERROR) {
        return {
          ...state,
          saveLoading: false,
          error: payload,
        };
      }

      if (type === actions.UPDATE_STARTED) {
        return {
          ...state,
          saveLoading: true,
          error: null,
        };
      }

      if (type === actions.UPDATE_SUCCESS) {
        return {
          ...state,
          saveLoading: false,
        };
      }

      if (type === actions.UPDATE_ERROR) {
        return {
          ...state,
          saveLoading: false,
          error: payload,
        };
      }

      return state;
    };

  return reducer;
};

export default getFormReducer;
