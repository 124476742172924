import slugify from 'slugify';

export const getOptions = ({
  arr = [],
  valueProp = 'id',
  labelProp = 'name',
  valueName = 'value',
  labelName = 'label',
}) => arr.map((val) => ({ [valueName]: val[valueProp], [labelName]: val[labelProp] }));

export const getJoinedTextFromArraysObj = ({ obj, arrayNames = [], propName, joinStr = ', ' }) => {
  const strArr = [],
    arrLen = arrayNames.length;
  for (let i = 0; i < arrLen; i += 1) {
    if (obj[arrayNames[i]]) {
      const arrStr = obj[arrayNames[i]].map((val) => val[propName]).join(joinStr);
      if (arrStr) {
        strArr.push(arrStr);
      }
    }
  }
  return strArr.join(joinStr);
};

const DEFAULT_DATA_LABEL = 'label',
  DEFAULT_DATA_VALUE = 'name' || 'key',
  DEFAULT_FILTER_KEY = 'filterKey';

export const getFilteredListRequest = ({
  items = [],
  dataLabel = DEFAULT_DATA_LABEL,
  dataValue = DEFAULT_DATA_VALUE,
  filterKey = DEFAULT_FILTER_KEY,
}) => {
  const req = {};
  for (let i = 0; i < items.length; i += 1) {
    const key = items[i][filterKey] === 'name' ? dataLabel : dataValue;
    if (req[items[i][filterKey]]) {
      if (!req[items[i][filterKey]].includes(items[i][key] || items[i].key)) {
        req[items[i][filterKey]].push(items[i][key] || items[i].key);
      }
    } else {
      req[items[i][filterKey]] = [items[i][key] || items[i].key];
    }
  }
  return req;
};

export const getDefaultsListRequest = ({ defaults = [], defaultKey = 'key' }) => {
  const req = {};
  for (let i = 0; i < defaults.length; i += 1) {
    const keys = Object.keys(defaults[i]) || [];
    for (let j = 0; j < keys.length; j += 1) {
      const key = keys[j];
      req[key] = [...(req[key] || []), ...defaults[i][key].map((val) => val[defaultKey])];
    }
  }
  return req;
};

export const getDefaultsListItems = ({ defaults = [], filterKey = DEFAULT_FILTER_KEY }) => {
  const items = [];
  for (let i = 0; i < defaults.length; i += 1) {
    const keys = Object.keys(defaults[i]) || [];
    for (let i1 = 0; i1 < keys.length; i1 += 1) {
      const key = keys[i1],
        arr = defaults[i][key];
      for (let i2 = 0; i2 < arr.length; i2 += 1) {
        items.push({ ...defaults[i][key][i2], [filterKey]: key });
      }
    }
  }
  return items;
};

export const joinValues = (arr, separator = ', ') => {
  const valueArr = arr.filter((val) => val) || [];
  return valueArr.join(separator);
};

export const getUrlSearchParam = (search, param) => {
  if (URLSearchParams && search && param) {
    const urlSearchParam = new URLSearchParams(search).get(param);
    if (urlSearchParam) {
      return urlSearchParam;
    }
  }
  return null;
};

export const isJsonString = (str) => {
  try {
    const json = JSON.parse(str);
    return typeof json === 'object';
  } catch (e) {
    return false;
  }
};

export const openWindow = (url) => {
  if (url && window) {
    const fullUrl = url.includes('http') ? url : `http://${url}`;
    window.open(fullUrl, '_blank');
  }
};

export const getSlug = (str = '') =>
  slugify(str, {
    replacement: '-', // Replace spaces with replacement character, defaults to `-`
    remove: true, // Remove characters that match regex, defaults to `undefined`
    lower: true, // Convert to lower case, defaults to `false`
    strict: true, // Strip special characters except replacement, defaults to `false`
    locale: 'vi', // Language code of the locale to use
    trim: true, // Trim leading and trailing replacement chars, defaults to `true`
  });

export const setUrlPagination = (pagination) => {
  if (pagination.pageSize && pagination.current) {
    const url = new URL(window.location.href);
    url.searchParams.set('limit', `${pagination.pageSize}`);
    url.searchParams.set('offset', `${(pagination.current - 1) * pagination.pageSize}`);
    const newUrl = url.toString();
    if (window.history.pushState) {
      window.history.pushState('', '', newUrl);
    } else {
      document.location.href = newUrl;
    }
  }
};
