import { combineReducers } from 'redux';
import { default as RFPManagement } from './RFPManager/reducers';
import { default as agency } from './agency/reducers';
import { default as portal } from './agency/portal/reducers';
import { default as form } from './form/reducers';
import { default as list } from './list/reducers';
import { default as view } from './view/reducers';

export default combineReducers({
  RFPManagement,
  agency,
  portal,
  list,
  view,
  form,
});
