import * as yup from 'yup';

import GenericField from '../../../modules/shared/fields/genericField';
import { IIntegerFieldOptions, Options } from './fieldTypes';

export default class IntegerField extends GenericField {
  public required: boolean;

  public allowNegative: boolean;

  public decimals: number;

  public min?: number;

  public max?: number;

  public matches?: RegExp;

  public type?: number;

  public prefix?: string;

  public suffix?: string;

  public defaultValue?: number;

  constructor(
    name: string,
    label: string,
    {
      required = false,
      min = undefined,
      max = undefined,
      type = 'number',
      valuePrefix = undefined,
      valueSuffix = undefined,
      allowNegative = true,
      decimals = 0,
      defaultValue = undefined,
      matches,
      dependentFieldSettings,
    }: Options<IIntegerFieldOptions> = {},
  ) {
    super(name, label);

    this.required = required;
    this.min = min;
    this.type = type;
    this.max = max;
    this.matches = matches;
    this.prefix = valuePrefix;
    this.suffix = valueSuffix;
    this.decimals = decimals;
    this.allowNegative = allowNegative;
    this.dependentFieldSettings = dependentFieldSettings;
    this.defaultValue = defaultValue;
  }

  forView(value: number): string {
    const viewValue: string = Number(value).toFixed(this.decimals);

    return `${this.prefix || ''}${viewValue}${this.suffix || ''}`;
  }

  forFormInitialValue(value: number) {
    return value ?? this.defaultValue;
  }

  forInput() {
    const inputProps: { type: any; [x: string]: any } = {
      type: this.type,
    };
    if (this.prefix !== undefined) {
      inputProps.prefix = this.prefix;
    }
    return inputProps;
  }

  forFilter() {
    return yup
      .number()
      .integer()
      .moreThan(
        -1,
        `${this.label} accepts only Non-Negative Numeric values with no special characters`,
      )
      .nullable(true)
      .label(this.label);
  }

  forForm() {
    let yupChain = yup.number().nullable(true).label(this.label);

    if (!this.decimals) {
      yupChain = yupChain.integer();
    }

    if (!this.allowNegative) {
      yupChain = yupChain.moreThan(
        -1,
        `${this.label} accepts only Non-Negative Numeric values with no special characters`,
      );
    }

    if (this.required) {
      yupChain = yupChain.required();
    }

    if (this.min || this.min === 0) {
      yupChain = yupChain.min(this.min);
    }

    if (this.max) {
      yupChain = yupChain.max(this.max);
    }

    if (this.defaultValue) {
      yupChain = yupChain.default(this.defaultValue);
    }

    if (this.dependentFieldSettings) {
      for (const dependentFieldSetting of this.dependentFieldSettings) {
        const [fieldName, settings] = Object.entries(dependentFieldSetting)[0];
        yupChain = yupChain.when(fieldName, settings);
      }
    }

    return yupChain;
  }

  forExport() {
    return yup.mixed().label(this.label);
  }

  forImport() {
    let yupChain = yup.number().nullable(true).label(this.label);

    if (!this.decimals) {
      yupChain = yupChain.integer();
    }

    if (!this.allowNegative) {
      yupChain = yupChain.moreThan(
        -1,
        `${this.label} accepts only Non-Negative Numeric values with no special characters`,
      );
    }

    if (this.required) {
      yupChain = yupChain.required();
    }

    if (this.min || this.min === 0) {
      yupChain = yupChain.min(this.min);
    }

    if (this.max) {
      yupChain = yupChain.max(this.max);
    }

    if (this.defaultValue) {
      yupChain = yupChain.default(this.defaultValue);
    }

    if (this.dependentFieldSettings) {
      for (const dependentFieldSetting of this.dependentFieldSettings) {
        const [fieldName, settings] = Object.entries(dependentFieldSetting)[0];
        yupChain = yupChain.when(fieldName, settings);
      }
    }

    return yupChain;
  }

  literalMapToValue: undefined;
}
