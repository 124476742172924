import authSelectors from 'modules/auth/authSelectors';
import PropTypes from 'prop-types';
import layoutSelectors from 'modules/layout/layoutSelectors';
import { Component } from 'react';
import { connect } from 'react-redux';
import { Switch, withRouter } from 'react-router-dom';
import routes from 'view/routes';
import CustomLoadable from 'view/shared/CustomLoadable';
import ProgressBar from 'view/shared/ProgressBar';
import EmailUnverifiedRoute from 'view/shared/routes/EmailUnverifiedRoute';
import EmptyPermissionsRoute from 'view/shared/routes/EmptyPermissionsRoute';
import PrivateRoute from 'view/shared/routes/PrivateRoute';
import PublicRoute from 'view/shared/routes/PublicRoute';

import { withAnalytics } from '~/analytics/segment/withAnalytics';
import SimpleRoute from './SimpleRoute';

class RoutesComponent extends Component {
  trackPageView() {
    try {
      if (this.props.currentUser?.id) {
        const isOsaiEmail = this.props.currentUser.email.includes('@onescreen');
        const isImpersonated = this.props.currentUser?.isImpersonated ?? false;

        if (this.props.currentUser.id === '000') {
          this.props.analytics.identify({
            email: this.props.currentUser.email,
            accountType: this.props.currentUser.accountType,
            isAdmin: isOsaiEmail,
            isImpersonated,
          });
        } else {
          this.props.analytics.identify(this.props.currentUser.id, {
            email: this.props.currentUser.email,
            name: this.props.currentUser.fullName,
            accountType: this.props.currentUser.accountType,
            partner: this.props.currentUser.partnerName,
            cart: this.props.currentUser.cart,
            isAdmin: isOsaiEmail,
            isImpersonated,
          });
        }
      }
    } catch (e) {
      throw new Error('Failed to run identify for analytics');
    }

    this.props.analytics.page();
  }

  componentDidMount() {
    window && window.scrollTo(0, 0);

    this.trackPageView();

    if (this.props.loading) {
      ProgressBar && ProgressBar.start();
    }
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.location.pathname !== this.props.location.pathname ||
      prevProps.location.search !== this.props.location.search
    ) {
      this.trackPageView();
    }

    if (prevProps.loading === this.props.loading) {
      return;
    }

    if (!this.props.loading) {
      ProgressBar && ProgressBar.done();
    }
  }

  get currentUser() {
    return this.props.currentUser;
  }

  render() {
    const { loading } = this.props;

    if (loading) {
      return <div />;
    }

    return (
      <Switch>
        {routes.publicRoutes.map((route) => (
          <PublicRoute
            key={route.path}
            exact
            path={route.path}
            currentUser={this.currentUser}
            component={CustomLoadable({
              loader: route.loader,
            })}
          />
        ))}

        {routes.emptyPermissionsRoutes.map((route) => (
          <EmptyPermissionsRoute
            key={route.path}
            exact
            path={route.path}
            currentUser={this.currentUser}
            component={CustomLoadable({
              loader: route.loader,
            })}
          />
        ))}

        {routes.emailUnverifiedRoutes.map((route) => (
          <EmailUnverifiedRoute
            key={route.path}
            exact
            path={route.path}
            currentUser={this.currentUser}
            component={CustomLoadable({
              loader: route.loader,
            })}
          />
        ))}

        {routes.privateRoutes.map((route) => (
          <PrivateRoute
            key={route.path}
            currentUser={this.currentUser}
            permissionRequired={route.permissionRequired}
            path={route.path}
            component={CustomLoadable({
              loader: () => route.loader(this.currentUser),
            })}
            exact={Boolean(route.exact)}
          />
        ))}

        {routes.simpleRoutes.map((route) => (
          <SimpleRoute
            key={route.path}
            exact
            path={route.path}
            component={CustomLoadable({
              loader: (currentUser) => route.loader(currentUser),
            })}
          />
        ))}
      </Switch>
    );
  }
}

RoutesComponent.propTypes = {
  currentUser: PropTypes.object,
  analytics: PropTypes.object,
  loading: PropTypes.bool,
  location: PropTypes.object,
};

const select = (state) => ({
  loading: authSelectors.selectLoadingInit(state) || layoutSelectors.selectLoading(state),
  currentUser: authSelectors.selectCurrentUser(state),
});

export default withRouter(connect(select)(withAnalytics(RoutesComponent)));
