import { i18n } from 'i18n';
import Errors from 'modules/shared/error/errors';
import { getHistory } from '../../../modules/store';
import StorefrontService from '../../../modules/storefront/storefrontService';
import { baseRouts } from 'view/routes';
import Message from '~/view/shared/message';

const prefix = 'STOREFRONT_DESTROY',
  actions = {
    DESTROY_STARTED: `${prefix}_DESTROY_STARTED`,
    DESTROY_SUCCESS: `${prefix}_DESTROY_SUCCESS`,
    DESTROY_ERROR: `${prefix}_DESTROY_ERROR`,

    DESTROY_ALL_STARTED: `${prefix}_DESTROY_ALL_STARTED`,
    DESTROY_ALL_SUCCESS: `${prefix}_DESTROY_ALL_SUCCESS`,
    DESTROY_ALL_ERROR: `${prefix}_DESTROY_ALL_ERROR`,

    doDestroy: (id) => async (dispatch) => {
      try {
        dispatch({
          type: actions.DESTROY_STARTED,
        });

        await StorefrontService.destroyAll([id]);

        dispatch({
          type: actions.DESTROY_SUCCESS,
        });

        Message.success(i18n('entities.storefront.destroy.success'));

        getHistory().goBack();
      } catch (error) {
        Errors.handle(error);

        dispatch({
          type: actions.DESTROY_ERROR,
        });
      }
    },

    doDestroyAll: (ids) => async (dispatch) => {
      try {
        dispatch({
          type: actions.DESTROY_ALL_STARTED,
        });

        await StorefrontService.destroyAll(ids);

        dispatch({
          type: actions.DESTROY_ALL_SUCCESS,
        });

        Message.success(i18n('entities.storefront.destroyAll.success'));

        getHistory().push(`/${baseRouts.location}`);
      } catch (error) {
        Errors.handle(error);

        dispatch({
          type: actions.DESTROY_ALL_ERROR,
        });
      }
    },
  };

export default actions;
