import get from 'lodash/get';
import { createSelector } from 'reselect';

const getViewSelector = (selectRaw) => {
  const selectRecord = createSelector([selectRaw], (raw) => raw.record),
    selectLoading = createSelector([selectRaw], (raw) => Boolean(raw.loading)),
    selectError = createSelector([selectRaw], (raw) => Boolean(raw.error)),
    selectHasValueOf = (propName) =>
      createSelector([selectRaw], (raw) => {
        const value = get(raw?.record, propName, null);
        return Array.isArray(value) ? Boolean(value.length) : !value;
      });

  return {
    selectLoading,
    selectRecord,
    selectError,
    selectRaw,
    selectHasValueOf,
  };
};

export default getViewSelector;
