import { i18n } from 'i18n';
import fields from 'modules/organizationType/importer/organizationTypeImporterFields';
import selectors from 'modules/organizationType/importer/organizationTypeImporterSelectors';
import OrganizationTypeService from 'modules/organizationType/organizationTypeService';
import importerActions from 'modules/shared/importer/importerActions';

export default importerActions(
  'ORGANIZATIONTYPE_IMPORTER',
  selectors,
  OrganizationTypeService.import,
  fields,
  i18n('entities.organizationType.importer.fileName'),
);
