import { get } from 'lodash';
import * as yup from 'yup';

import GenericField, { ForFormInitialValueOptions } from './genericField';

export default class ObjectField extends GenericField {
  constructor(name: string, label: string, fields: { [x: string]: GenericField } = {}) {
    super(name, label);
    this.fields = fields;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  forFormInitialValue(record: Record<string, any>, options: ForFormInitialValueOptions) {
    for (const key in this.fields) {
      const field = this.fields[key];

      record = record ?? {};

      const fieldName = this._normalizedFieldName(field.name, options?.parentNodeName);

      if (field.fields) {
        record[fieldName] =
          get(record, fieldName) ?? field.forFormInitialValue({}, { parentFieldName: fieldName });
      } else {
        record[fieldName] = get(record, fieldName) ?? field.forFormInitialValue();
      }
    }

    return record;
  }

  forForm(parentNodeName: string) {
    const shape: { [x: string]: any } = {};

    for (const key in this.fields) {
      const field = this.fields[key];
      const fieldName = this._normalizedFieldName(field.name, parentNodeName);
      if (field.fields) {
        shape[fieldName] = field.forForm && field.forForm(fieldName);
      } else {
        shape[fieldName] = field.forForm && field.forForm();
      }
    }

    const yupChain = yup.object().nullable(true).shape(shape);

    return yupChain;
  }

  forFilter: undefined;

  forInput: undefined;

  forView: undefined;

  forExport() {
    const shape: { [x: string]: any } = {};

    for (const key in this.fields) {
      const field = this.fields[key];
      shape[field.name] = field.forExport && field.forExport();
    }

    const yupChain = yup.object().nullable(true).shape(shape);

    return yupChain;
  }

  forImport() {
    const shape: { [x: string]: any } = {};

    for (const key in this.fields) {
      const field = this.fields[key];
      shape[field.name] = field.forForm && field.forForm();
    }

    const yupChain = yup.object().shape(shape);

    return yupChain;
  }

  literalMapToValue: undefined;

  protected _normalizedFieldName(fieldName: string, parentNodeName: string) {
    const splittedPropNames = fieldName.split('.');
    const splitterParentPropNames = parentNodeName?.split('.') ?? [];
    const normalizedPropNames = splittedPropNames.filter(
      (prop) => !splitterParentPropNames.includes(prop),
    );
    const normalizedFieldName = normalizedPropNames.join('.');

    return normalizedFieldName;
  }
}
