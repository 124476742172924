import authAxios from 'modules/shared/axios/authAxios';

export default class MarketService {
  static async update(id, data) {
    const body = {
        id,
        data,
      },
      response = await authAxios.put(`/market/${id}`, body);

    return response.data;
  }

  static async setActive(id, data) {
    const body = {
        id,
        data,
      },
      response = await authAxios.put(`/market/${id}/active`, body);

    return response.data;
  }

  static async destroyAll(ids) {
    const params = {
        ids,
      },
      response = await authAxios.delete('/market', {
        params,
      });

    return response.data;
  }

  static async create(data) {
    const body = {
        data,
      },
      response = await authAxios.post('/market', body);

    return response.data;
  }

  static async import(values, importHash) {
    const body = {
        data: values,
        importHash,
      },
      response = await authAxios.post('/market/import', body);

    return response.data;
  }

  static async find(id) {
    const response = await authAxios.get(`/market/${id}`);
    return response.data;
  }

  static async list(filter, orderBy, limit, offset) {
    const params = {
        filter,
        orderBy,
        limit,
        offset,
      },
      response = await authAxios.get('/market', {
        params,
      });

    return response.data;
  }

  static async listAutocomplete(query, limit) {
    const params = {
        query,
        limit,
      },
      response = await authAxios.get('/market/autocomplete', {
        params,
      });

    return response.data;
  }

  static async getFilterList(query, limit) {
    const params = {
        query,
        limit,
      },
      response = await authAxios.get('/filters/market', {
        params,
      });

    return response.data;
  }
}
