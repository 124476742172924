import { OptionsObject, ProviderContext, SnackbarKey, SnackbarMessage } from 'notistack';
import { ErrorAlert, ErrorAlertProps } from '~/view/shared/OSAIAlert/ErrorAlert';
import { WarningAlert, WarningAlertProps } from '~/view/shared/OSAIAlert/WarningAlert';
import { SuccessAlert, SuccessAlertProps } from '~/view/shared/OSAIAlert/SuccessAlert';
import { InfoAlertProps } from '~/view/shared/OSAIAlert/InfoAlert';

type EnqueueSnackbarMethod = ProviderContext['enqueueSnackbar'];
type CloseSnackbarMethod = ProviderContext['closeSnackbar'];

export interface MessageOptions<T> {
  alert?: T;
  snackback?: OptionsObject;
}

export default class Message {
  static notistakEnqueueSnackbar?: EnqueueSnackbarMethod;
  static closeSnackBar?: CloseSnackbarMethod;

  static registerNotistakEnqueueSnackbar(
    enqueueSnackbar: EnqueueSnackbarMethod,
    closeSnackBar: CloseSnackbarMethod,
  ) {
    this.notistakEnqueueSnackbar = enqueueSnackbar;
    this.closeSnackBar = closeSnackBar;
  }

  static success(message: SnackbarMessage, options?: MessageOptions<SuccessAlertProps>) {
    this.notistakEnqueueSnackbar(message, {
      ...options?.snackback,
      variant: 'success',
      content: (key, message) => {
        return <SuccessAlert {...this.mergePropsWithDefault(options, key)}>{message}</SuccessAlert>;
      },
    });
  }

  static successWithLink(
    message: SnackbarMessage,
    linkUrl?: string,
    linkText?: string,
    options?: MessageOptions<SuccessAlertProps>,
  ) {
    this.notistakEnqueueSnackbar(message, {
      ...options?.snackback,
      variant: 'success',
      content: (key, message) => {
        return (
          <SuccessAlert {...this.mergePropsWithDefault(options, key)}>
            {message}
            &nbsp;
            <a href={linkUrl} rel="noreferrer">
              {linkText}
            </a>
          </SuccessAlert>
        );
      },
    });
  }

  static info(message: SnackbarMessage, options?: MessageOptions<InfoAlertProps>) {
    this.notistakEnqueueSnackbar(message, {
      ...options?.snackback,
      variant: 'info',
      content: (key, message) => (
        <SuccessAlert {...this.mergePropsWithDefault(options, key)}>{message}</SuccessAlert>
      ),
    });
  }

  static warning(message: SnackbarMessage, options?: MessageOptions<WarningAlertProps>) {
    this.notistakEnqueueSnackbar(message, {
      ...options?.snackback,
      variant: 'warning',
      content: (key, message) => (
        <WarningAlert {...this.mergePropsWithDefault(options, key)}>{message}</WarningAlert>
      ),
    });
  }

  static error(message: SnackbarMessage, options?: MessageOptions<ErrorAlertProps>) {
    this.notistakEnqueueSnackbar(message, {
      ...options?.snackback,
      variant: 'error',
      content: (key, message) => (
        <ErrorAlert {...this.mergePropsWithDefault(options, key)}>{message}</ErrorAlert>
      ),
    });
  }

  private static mergePropsWithDefault(
    options: MessageOptions<SuccessAlertProps>,
    key: SnackbarKey,
  ) {
    return {
      ...options?.alert,
      dismissable: options?.alert?.dismissable ?? true,
      onClose: options?.alert?.onClose ?? (() => this.closeSnackBar(key)),
    };
  }
}
