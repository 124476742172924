import { getListReducer } from '../../shared/reducers';
import actions from './priceRequestListActions';

export default getListReducer(actions);

/*
 * Const initialData = {
 *     records: [],
 *     findLoading: false,
 *     saveLoading: false,
 *     error: null,
 * };
 */

/*
 * Const reducer = (state = initialData, { type, payload }: any) => {
 *     if (type === actions.RESET) {
 *     return {
 *         ...initialData,
 *     };
 *     }
 */

/*
 *     If (type === actions.FIND_STARTED) {
 *     return {
 *         ...state,
 *         record: null,
 *         findLoading: true,
 *         error: null,
 *     };
 *     }
 */

/*
 *     If (type === actions.FIND_SUCCESS) {
 *     return {
 *         ...state,
 *         record: payload,
 *         findLoading: false,
 *     };
 *     }
 */

/*
 *     If (type === actions.FIND_ERROR) {
 *     return {
 *         ...state,
 *         record: null,
 *         findLoading: false,
 *     };
 *     }
 */

/*
 *     If (type === actions.CREATE_STARTED) {
 *     return {
 *         ...state,
 *         saveLoading: true,
 *         error: null,
 *     };
 *     }
 */

/*
 *     If (type === actions.CREATE_SUCCESS) {
 *     return {
 *         ...state,
 *         saveLoading: false,
 *     };
 *     }
 */

/*
 *     If (type === actions.CREATE_ERROR) {
 *     return {
 *         ...state,
 *         saveLoading: false,
 *         error: payload,
 *     };
 *     }
 */

/*
 *     If (type === actions.UPDATE_STARTED) {
 *     return {
 *         ...state,
 *         saveLoading: true,
 *         error: null,
 *     };
 *     }
 */

/*
 *     If (type === actions.UPDATE_SUCCESS) {
 *     return {
 *         ...state,
 *         saveLoading: false,
 *     };
 *     }
 */

/*
 *     If (type === actions.UPDATE_ERROR) {
 *     return {
 *         ...state,
 *         saveLoading: false,
 *         error: payload,
 *     };
 *     }
 */

/*
 *     Return state;
 * };
 */

// Export default reducer;
