import { i18n } from 'i18n';
import ChannelService from 'modules/channel/channelService';
import fields from 'modules/channel/importer/channelImporterFields';
import selectors from 'modules/channel/importer/channelImporterSelectors';
import importerActions from 'modules/shared/importer/importerActions';

export default importerActions(
  'CHANNEL_IMPORTER',
  selectors,
  ChannelService.import,
  fields,
  i18n('entities.channel.importer.fileName'),
);
