import { ThunkAction } from 'redux-thunk';
import { RootState } from '~/types/models/rootState';
import { BasicAction, BasicErrorAction } from '../../common';
import { Campaign } from '~/types/models/campaign';

const path = `CAMPAIGN_VIEW`;

// String action labels
export const ViewActionTypes = {
  FIND_START: `${path}_FIND_START`,
  FIND_SUCCESS: `${path}_FIND_SUCCESS`,
  FIND_ERROR: `${path}_FIND_ERROR`,
} as const;

type ViewActionTypeLiterals = typeof ViewActionTypes;

type BasicCampaignViewActions =
  | BasicAction<ViewActionTypeLiterals, Campaign>
  | BasicErrorAction<ViewActionTypeLiterals, unknown>;

export interface CampaignViewActions {
  doFind: (id: string) => ThunkAction<void, RootState, unknown, BasicCampaignViewActions>; // TODO Create types for single actions better
}
