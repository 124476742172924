import { ComponentType } from 'react';
import { useAnalytics } from './useAnalytics';

export interface WithAnalyticsProps {
  analytics: SegmentAnalytics.AnalyticsJS;
}

export function withAnalytics<T>(WrappedComponent: ComponentType<T>) {
  const componentName = WrappedComponent?.displayName || WrappedComponent?.name || 'Component';
  const wrappedName = `WithAnalytics(${componentName})`;

  const WithAnalyticsComponent = (props: T & WithAnalyticsProps) => {
    const analytics = useAnalytics();

    return <WrappedComponent {...props} analytics={analytics} />;
  };

  WithAnalyticsComponent.displayName = wrappedName;

  return WithAnalyticsComponent;
}
