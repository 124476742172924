import { RFPManagementActionTypes } from '~/types/modules/campaign/RFPManagement/actions.d';
import { RFPManagementReducerState } from '~/types/modules/campaign/RFPManagement/reducers.d';

const initialState: RFPManagementReducerState = {
  loadingRequests: true,
  loadingInventory: true,
  RFPRequests: [],
  RFPResponseAggregateInventory: [],
};

const reducer = (state = initialState, { type, payload }): RFPManagementReducerState => {
  switch (type) {
    case RFPManagementActionTypes.FETCH_RFPS_START:
      return {
        ...state,
      };
    case RFPManagementActionTypes.FETCH_RFPS_SUCCESS:
      return {
        ...state,
        RFPRequests: payload,
        loadingRequests: false,
      };
    case RFPManagementActionTypes.FETCH_RFPS_ERROR:
      return {
        ...state,
        RFPRequests: [],
        error: payload,
        loadingRequests: false,
      };
    case RFPManagementActionTypes.FETCH_RFPS_INVENTORY_START:
      return {
        ...state,
      };
    case RFPManagementActionTypes.FETCH_RFPS_INVENTORY_SUCCESS:
      return {
        ...state,
        RFPResponseAggregateInventory: payload,
        loadingInventory: false,
      };
    case RFPManagementActionTypes.FETCH_RFPS_INVENTORY_ERROR:
      return {
        ...state,
        RFPResponseAggregateInventory: [],
        error: payload,
        loadingInventory: false,
      };
    default:
      return {
        ...state,
      };
  }
};

// eslint-disable-next-line import/no-default-export
export default reducer;
