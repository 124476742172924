import service from '~/modules/quickbook/service';
import { default as Message } from '~/view/shared/__mocks__/message';
import { i18n } from '~/i18n';
import { Quickbookctions } from '~/types/modules/quickbook/actions';
import { getHistory } from '../store';

const path = 'QUICKBOOK';

const actions: Quickbookctions = {
  QBO_CONNECT_STARTED: `${path}_QBO_CONNECT_STARTED`,
  QBO_CONNECT_SUCCESS: `${path}_QBO_CONNECT_SUCCESS`,
  QBO_CONNECT_ERROR: `${path}_QBO_CONNECT_ERROR`,
  QBO_TOKEN_CONFIRM_STARTED: `${path}_QBO_TOKEN_CONFIRM_STARTED`,
  QBO_TOKEN_CONFIRM_SUCCESS: `${path}_QBO_TOKEN_CONFIRM_SUCCESS`,
  QBO_TOKEN_CONFIRM_ERROR: `${path}_QBO_TOKEN_CONFIRM_ERROR`,
  QBO_ACCOUNT_CHECK_STARTED: `${path}_QBO_ACCOUNT_CHECK_STARTED`,
  QBO_ACCOUNT_CHECK_SUCCESS: `${path}_QBO_ACCOUNT_CHECK_SUCCESS`,
  QBO_ACCOUNT_CHECK_ERROR: `${path}_QBO_ACCOUNT_CHECK_ERROR`,
  QBO_DISCONNECT_STARTED: `${path}_QBO_DISCONNECT_STARTED`,
  QBO_DISCONNECT_SUCCESS: `${path}_QBO_DISCONNECT_SUCCESS`,
  QBO_DISCONNECT_ERROR: `${path}_QBO_DISCONNECT_ERROR`,
  doCheck: () => async (dispatch, _getState) => {
    try {
      dispatch({
        type: actions.QBO_ACCOUNT_CHECK_STARTED,
      });

      const isConnected = await service.checkQuickbookAccount();

      dispatch({
        type: actions.QBO_ACCOUNT_CHECK_SUCCESS,
        payload: isConnected,
      });
    } catch (e) {
      dispatch({
        type: actions.QBO_ACCOUNT_CHECK_ERROR,
        payload: e,
      });
      Message.error(i18n('common.error'));
    }
  },
  doConnect: () => async (dispatch, _getState) => {
    try {
      dispatch({
        type: actions.QBO_CONNECT_STARTED,
      });

      // this just updates cart info, which we are using for invoice
      await service.connectQuickbookAccount();

      dispatch({
        type: actions.QBO_CONNECT_SUCCESS,
      });

      Message.success(i18n('common.success'));
    } catch (e) {
      dispatch({
        type: actions.QBO_CONNECT_ERROR,
        payload: e,
      });
      Message.error(i18n('common.error'));
    }
  },
  doDisconnect: () => async (dispatch, _getState) => {
    try {
      dispatch({
        type: actions.QBO_DISCONNECT_STARTED,
      });

      // this just updates cart info, which we are using for invoice
      await service.disconnectQuickbookAccount();

      dispatch({
        type: actions.QBO_DISCONNECT_SUCCESS,
      });

      Message.success(i18n('common.success'));
    } catch (e) {
      dispatch({
        type: actions.QBO_DISCONNECT_ERROR,
        payload: e,
      });
      Message.error(i18n('common.error'));
    }
  },
  doConfirmToken: (tokenId) => async (dispatch, _getState) => {
    try {
      dispatch({
        type: actions.QBO_TOKEN_CONFIRM_STARTED,
      });

      await service.confirmQuickbookToken(tokenId);

      dispatch({
        type: actions.QBO_TOKEN_CONFIRM_SUCCESS,
      });

      setTimeout(() => {
        const history = getHistory();
        history.push('/invoices');
      }, 5000);
    } catch (e) {
      dispatch({
        type: actions.QBO_TOKEN_CONFIRM_ERROR,
        payload: e,
      });
      Message.error(i18n('common.error'));
    }
  },
};

export default actions;
