import destroy from 'modules/market/destroy/marketDestroyReducers';
import form from 'modules/market/form/marketFormReducers';
import importerReducer from 'modules/market/importer/marketImporterReducers';
import list from 'modules/market/list/marketListReducers';
import view from 'modules/market/view/marketViewReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
});
