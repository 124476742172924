import destroy from 'modules/callToAction/destroy/callToActionDestroyReducers';
import form from 'modules/callToAction/form/callToActionFormReducers';
import importerReducer from 'modules/callToAction/importer/callToActionImporterReducers';
import list from 'modules/callToAction/list/callToActionListReducers';
import view from 'modules/callToAction/view/callToActionViewReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
});
