import { ViewActionTypes } from '~/types/modules/campaign/view/actions.d';
import { CampaignViewReducerState } from '~/types/modules/campaign/view/reducers.d';

const initialState: CampaignViewReducerState = {
  loading: false,
  record: null,
  error: null,
};

const reducer = (state = initialState, { type, payload }): CampaignViewReducerState => {
  switch (type) {
    case ViewActionTypes.FIND_START:
      return {
        ...state,
        loading: true,
      };
    case ViewActionTypes.FIND_SUCCESS:
      return {
        ...state,
        record: payload,
      };
    case ViewActionTypes.FIND_ERROR:
      return {
        ...state,
        error: payload,
      };
    default:
      return {
        ...state,
      };
  }
};

export default reducer;
