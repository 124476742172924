import { DateRangeIterator } from '~/utils/dateRangeIterator';
import { addDays, format, isSameDay, isValid, parse, parseISO } from 'date-fns';

export const getAvailableDatesFromOverAllDateRange = (
  startDate,
  endDate,
  unavailableDateRangeCollection, // array of tuples of [[startDate, endDate], [startDate, endDate]]
) => {
  if (typeof startDate === 'string') {
    startDate = parse(startDate, 'MM/dd/yyyy', new Date());
  }

  if (typeof endDate === 'string') {
    endDate = parse(endDate, 'MM/dd/yyyy', new Date());
  }

  const freeDates = new Set();
  const overallDateRangeIterator = new DateRangeIterator(startDate, endDate);

  for (const currentDayOfRange of overallDateRangeIterator) {
    let isConflict = false;

    for (const [startDateJS, endDateJS] of unavailableDateRangeCollection) {
      if (!isConflict) {
        isConflict = currentDayOfRange >= startDateJS && currentDayOfRange <= endDateJS;

        if (isConflict) {
          // unavailableDateRangeCollection being flight ranges associated with a single inventory,
          // so we can break early and continue to check the next day because conflict for the day
          // was already found, no need to continue checking against the other flights
          break;
        }
      }
    }

    if (!isConflict) {
      freeDates.add(currentDayOfRange);
    }
  }

  return freeDates;
};

export const flattenFreeDaysIntoRanges = (freeDates) => {
  const freeSlots = [];
  let slot = [];
  let previousFreeDay = undefined;
  for (const freeDay of freeDates) {
    if (previousFreeDay && slot.length) {
      const startOfSlotRange = previousFreeDay;
      const previousDayOfFreeDay = addDays(freeDay, -1);

      if (isSameDay(startOfSlotRange, previousDayOfFreeDay)) {
        slot[1] = freeDay;
      } else {
        if (slot.length === 1) {
          // This is a free floating day
          // i.e. 01/11/2022, 01/12/2022, 01/15/2022, 01/22/2022, 01/23/2022
          // this handles ['01/15/2022', '01/15/2022']
          slot[1] = slot[0];
        }
        freeSlots.push(slot);
        slot = [freeDay];
      }
    } else {
      slot.push(freeDay);
    }

    previousFreeDay = freeDay;
  }

  if (slot.length) {
    freeSlots.push(slot);
  }

  return freeSlots;
};

export const changeDateFormattingFromSimpleDate = (dateString) => {
  if (!dateString) {
    return ' ';
  }
  const [year, month, day] = dateString.split('-');
  return [month, day, year].join('/');
};

export const parseDateFromString = (dateString: string): Date => {
  const date = parseISO(dateString);
  const retVal = isValid(date) ? date : null;
  return retVal;
};

export const convertServerDateStringToDate = (dateString) => {
  if (!dateString) {
    return undefined;
  }

  const parsedDate = parse(dateString, 'MM/dd/yyyy', new Date());
  return isDateValid(parsedDate) ? parsedDate : undefined;
};

export const formatDateForBackend = (dateValue: Date): string | null => {
  if (!dateValue) {
    return null;
  }

  try {
    return format(dateValue, 'yyyy-MM-dd');
  } catch (e) {
    return null;
  }
};

export const isDateValid = (date: Date): boolean => !!date && isValid(date);
