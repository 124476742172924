import { FC, forwardRef, PropsWithChildren, useState } from 'react';

import { Alert, AlertProps, Box, generateUtilityClasses, SxProps, Theme } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import clsx from 'clsx';

const classes = {
  ...generateUtilityClasses('BaseAlert', [
    'root',
    'container',
    'content',
    'action',
    'close',
    'icon',
    'message',
  ]),
};

const styles = {
  [`&.${classes.root}`]: {
    borderRadius: '4px',
    border: '0',
  },
  [`& .${classes.icon}`]: {
    display: 'flex',
    alignItems: 'center',
  },
  [`& .${classes.message}`]: {
    flexGrow: 1,
  },
  [`& .${classes.container}`]: {
    display: 'flex',
    justifyContent: 'space-between',
    lineHeight: '24px',
  },
  [`& .${classes.content}`]: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
  [`& .${classes.action}`]: {
    marginLeft: '20px',
    cursor: 'pointer',
    color: (theme) => theme.palette.primary.main,
    textDecorationLine: 'underline',
    display: 'flex',
    alignItems: 'center',
  },
  [`& .${classes.close}`]: {
    display: 'flex',
    justifyContent: 'flex-end',
    cursor: 'pointer',
    marginLeft: '24px',
    alignItems: 'center',
  },
};

export interface BaseAlertProps extends Pick<AlertProps, 'variant' | 'severity' | 'icon' | 'ref'> {
  sx?: SxProps<Theme>;
  className?: string;
  actionText?: string;
  onClickAction?: () => void;
  dismissable?: boolean;
  onClose?: () => void;
  hugMode?: boolean;
}

const BaseAlert: FC<BaseAlertProps> = forwardRef<HTMLDivElement, BaseAlertProps>(
  (
    {
      className,
      sx,
      children,
      dismissable,
      actionText,
      onClickAction,
      onClose,
      hugMode,
      ...alertProps
    }: PropsWithChildren<BaseAlertProps>,
    ref,
  ) => {
    const [visible, setVisible] = useState(true);

    const onDismiss = () => {
      onClose?.();
      setVisible(false);
    };

    const onActionClick = () => {
      onClickAction?.();
    };

    const hugModeStyle = hugMode ? { width: 'max-content' } : {};

    return (
      <Box
        ref={ref}
        className={clsx(className, classes.root)}
        sx={Array.isArray(sx) ? sx.concat([styles]) : { ...sx, ...styles, ...hugModeStyle }}
      >
        {visible ? (
          <Alert
            {...alertProps}
            className={clsx(className, classes.root)}
            classes={{
              icon: classes.icon,
              message: classes.message,
            }}
            sx={Array.isArray(sx) ? sx.concat([styles]) : { ...sx, ...styles, ...hugModeStyle }}
          >
            <Box className={classes.container}>
              <Box className={classes.content}>
                {children}
                {actionText && (
                  <span className={classes.action} onClick={onActionClick}>
                    {actionText}
                  </span>
                )}
              </Box>
              {dismissable && (
                <Box className={classes.close}>
                  <CloseIcon onClick={onDismiss} />
                </Box>
              )}
            </Box>
          </Alert>
        ) : (
          <></>
        )}
      </Box>
    );
  },
);

BaseAlert.displayName = 'BaseAlert';

export { BaseAlert };

export { classes as BaseAlertClasses };
