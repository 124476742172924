import { i18n } from 'i18n';
import { getHistory } from 'modules/store';
import { baseRouts } from 'view/routes';
import Message from '~/view/shared/message';

import Errors from '../../../modules/shared/error/errors';
import StorefrontService from '../../../modules/storefront/storefrontService';

const prefix = 'STOREFRONT_FORM';
const actions = {
  RESET: `${prefix}_RESET`,

  FIND_STARTED: `${prefix}_FIND_STARTED`,
  FIND_SUCCESS: `${prefix}_FIND_SUCCESS`,
  FIND_ERROR: `${prefix}_FIND_ERROR`,

  CREATE_STARTED: `${prefix}_CREATE_STARTED`,
  CREATE_SUCCESS: `${prefix}_CREATE_SUCCESS`,
  CREATE_ERROR: `${prefix}_CREATE_ERROR`,

  UPDATE_STARTED: `${prefix}_UPDATE_STARTED`,
  UPDATE_SUCCESS: `${prefix}_UPDATE_SUCCESS`,
  UPDATE_ERROR: `${prefix}_UPDATE_ERROR`,

  doNew: () => ({
    type: actions.RESET,
  }),

  doFind: (id) => async (dispatch) => {
    try {
      dispatch({
        type: actions.FIND_STARTED,
      });

      const record = await StorefrontService.find(id);

      dispatch({
        type: actions.FIND_SUCCESS,
        payload: record,
      });
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: actions.FIND_ERROR,
      });

      getHistory().push(`/${baseRouts.location}`);
    }
  },

  doCreate: (values) => async (dispatch) => {
    try {
      dispatch({
        type: actions.CREATE_STARTED,
      });

      await StorefrontService.create(values);

      dispatch({
        type: actions.CREATE_SUCCESS,
      });

      Message.success(i18n('entities.storefront.create.success'));

      window.ONESCREEN.dispatch.produce({ action: actions.CREATE_SUCCESS });

      getHistory().push(`/${baseRouts.location}`);
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: actions.CREATE_ERROR,
      });
    }
  },

  doUpdate: (id, values) => async (dispatch) => {
    try {
      dispatch({
        type: actions.UPDATE_STARTED,
      });

      await StorefrontService.update(id, values);

      dispatch({
        type: actions.UPDATE_SUCCESS,
      });

      Message.success(i18n('entities.storefront.update.success'));

      window.ONESCREEN.dispatch.produce({ action: actions.UPDATE_SUCCESS });

      getHistory().push(`/${baseRouts.location}/${id}`);
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: actions.UPDATE_ERROR,
      });
    }
  },
};

export default actions;
