import { i18n } from 'i18n';
import DeviceProfileService from 'modules/deviceProfile/deviceProfileService';
import fields from 'modules/deviceProfile/importer/deviceProfileImporterFields';
import selectors from 'modules/deviceProfile/importer/deviceProfileImporterSelectors';
import importerActions from 'modules/shared/importer/importerActions';

export default importerActions(
  'DEVICEPROFILE_IMPORTER',
  selectors,
  DeviceProfileService.import,
  fields,
  i18n('entities.deviceProfile.importer.fileName'),
);
