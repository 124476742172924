import Axios from 'axios';
import config from 'config';
import authAxios from 'modules/shared/axios/authAxios';

const PATH = '/user/company';

export default class CompanyUserService {
  static async edit(id, data, token) {
    const body = {
      data,
    };
    if (token) {
      const path = `${config.backendUrl}${PATH}/${id}`,
        options = { headers: { Authorization: `Bearer ${token}` } },
        response = await Axios.put(path, body, options);
      return response.data;
    }

    const response = await authAxios.put(PATH, body);
    return response.data;
  }

  static async find(id, token = null) {
    if (token) {
      const path = `${config.backendUrl}${PATH}/${id}`,
        options = { headers: { Authorization: `Bearer ${token}` } },
        response = await Axios.get(path, options);
      return response.data;
    }

    const path = `${PATH}/${id}`,
      response = await authAxios.get(path);
    return response.data;
  }
}
