import { i18n } from 'i18n';
import DeviceCapabilityService from 'modules/deviceCapability/deviceCapabilityService';
import fields from 'modules/deviceCapability/importer/deviceCapabilityImporterFields';
import selectors from 'modules/deviceCapability/importer/deviceCapabilityImporterSelectors';
import importerActions from 'modules/shared/importer/importerActions';

export default importerActions(
  'DEVICECAPABILITY_IMPORTER',
  selectors,
  DeviceCapabilityService.import,
  fields,
  i18n('entities.deviceCapability.importer.fileName'),
);
