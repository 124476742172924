import { getListSelector, getPermissionsSelector } from 'modules/shared/selectors';

const stateName = 'budgetRequirement';

export const permissions = getPermissionsSelector(stateName);
export const list = getListSelector((state) => state[stateName].list);

const selectors = {
  permissions,
  list,
};

export default selectors;
