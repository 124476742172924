import destroy from 'modules/status/destroy/statusDestroyReducers';
import form from 'modules/status/form/statusFormReducers';
import importerReducer from 'modules/status/importer/statusImporterReducers';
import list from 'modules/status/list/statusListReducers';
import view from 'modules/status/view/statusViewReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
});
