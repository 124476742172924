import { i18n } from 'i18n';
import CallToActionService from 'modules/callToAction/callToActionService';
import fields from 'modules/callToAction/importer/callToActionImporterFields';
import selectors from 'modules/callToAction/importer/callToActionImporterSelectors';
import importerActions from 'modules/shared/importer/importerActions';

export default importerActions(
  'CALLTOACTION_IMPORTER',
  selectors,
  CallToActionService.import,
  fields,
  i18n('entities.callToAction.importer.fileName'),
);
