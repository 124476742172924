import { createSelector } from 'reselect';

const selectRaw = (state) => state.iam.list.users;
const selectLoading = createSelector([selectRaw], (raw) => raw.loading);
const selectExportLoading = createSelector([selectRaw], (raw) => raw.exportLoading);
const selectRows = createSelector([selectRaw], (raw) => raw.rows);
const selectCount = createSelector([selectRaw], (raw) => raw.count);
const selectHasRows = createSelector([selectCount], (count) => count > 0);
const selectSorter = createSelector([selectRaw], (raw) => raw.sorter || {});
const selectOrderBy = createSelector([selectRaw], (raw) => {
  const { sorter } = raw;

  if (!sorter) {
    return null;
  }

  if (!sorter.columnKey) {
    return null;
  }

  const direction = sorter.order === 'desc' ? 'DESC' : 'ASC';

  return `${sorter.columnKey}_${direction}`;
});
const selectFilter = createSelector([selectRaw], (raw) => raw.filter);
const selectLimit = createSelector([selectRaw], (raw) => {
  const { pagination } = raw;
  return pagination.pageSize;
});
const selectOffset = createSelector([selectRaw], (raw) => {
  const { pagination } = raw;

  if (!pagination || !pagination.pageSize) {
    return 0;
  }

  const current = pagination.current || 1;

  return (current - 1) * pagination.pageSize;
});
const selectPagination = createSelector([selectRaw, selectCount], (raw, count) => ({
  ...raw.pagination,
  total: count,
}));
const selectSelectedKeys = createSelector([selectRaw], (raw) => raw.selectedKeys);
const selectSelectedRows = createSelector([selectRaw, selectRows], (raw, rows) =>
  rows.filter((row) => raw.selectedKeys.includes(row.id)),
);
const selectIsAllSelected = createSelector(
  [selectRows, selectSelectedKeys],
  (rows, selectedKeys) => rows.length === selectedKeys.length,
);

export default {
  selectLoading,
  selectRows,
  selectCount,
  selectOrderBy,
  selectLimit,
  selectFilter,
  selectOffset,
  selectPagination,
  selectSelectedKeys,
  selectSelectedRows,
  selectHasRows,
  selectExportLoading,
  selectIsAllSelected,
  selectSorter,
};
