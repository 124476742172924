import { createSelector } from 'reselect';

const selectRaw = (state) => state.iam.view,
  selectUser = createSelector([selectRaw], (raw) => raw.user),
  selectLoading = createSelector([selectRaw], (raw) => Boolean(raw.loading)),
  selectors = {
    selectLoading,
    selectUser,
    selectRaw,
  };

export default selectors;
