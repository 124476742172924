export class HubSpot {
  static showWidget() {
    if (window.HubSpotConversations) {
      window.HubSpotConversations.resetAndReloadWidget();
    }
  }

  static hideWidget() {
    if (window.HubSpotConversations) {
      window.HubSpotConversations.widget.remove();
    }
  }
}
