import { FC } from 'react';
import { Body1, Body1Bold } from '~/components/TextBlocks';

interface AlertMessageTextProps {
  majorText: string;
  text: string;
}

const AlertMessageText: FC<AlertMessageTextProps> = ({ majorText, text }) => {
  return (
    <>
      <Body1Bold>{majorText}</Body1Bold>
      {' - '}
      <Body1>{text}</Body1>
    </>
  );
};

export default AlertMessageText;
