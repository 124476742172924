import { ListActionTypes } from '~/types/modules/campaign/list/actions.d';
import { CampaignListReducerState } from '~/types/modules/campaign/list/reducers.d';

const initialState: CampaignListReducerState = {
  pagination: {
    current: 1,
    pageSize: 10000,
  },
  rows: [],
  loading: false,
  error: '',
  filter: {},
  count: 0,
  sorter: '',
  selectedKeys: [],
};

const reducer = (state = initialState, { type, payload }): CampaignListReducerState => {
  switch (type) {
    case ListActionTypes.FETCH_START:
      return {
        ...state,
        loading: true,
      };
    case ListActionTypes.FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        rows: payload,
      };
    case ListActionTypes.FETCH_ERROR:
      return {
        ...state,
        loading: false,
        error: payload,
      };
    case ListActionTypes.SELECT_START:
      return {
        ...state,
        loading: true,
      };
    case ListActionTypes.SELECT_SUCCESS:
      return {
        ...state,
        loading: false,
        selectedKeys: payload,
      };
    case ListActionTypes.SELECT_ERROR:
      return {
        ...state,
        loading: false,
        error: payload,
      };
    default:
      return {
        ...state,
      };
  }
};

// eslint-disable-next-line import/no-default-export
export default reducer;
