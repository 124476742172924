import destroy from 'modules/sector/destroy/sectorDestroyReducers';
import form from 'modules/sector/form/sectorFormReducers';
import importerReducer from 'modules/sector/importer/sectorImporterReducers';
import list from 'modules/sector/list/sectorListReducers';
import view from 'modules/sector/view/sectorViewReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
});
