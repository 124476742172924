import model from 'modules/deviceProfile/deviceProfileModel';

const { fields } = model;

export default [
  fields.name,
  fields.productImage,
  fields.capabilitiesAvailable,
  fields.status,
  fields.version,
  fields.capabilitiesEnabled,
];
