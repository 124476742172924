import { i18n } from '~/i18n';
import StorefrontAvailabilityService from '~/modules/storefrontAvailability/storefrontAvailabilityService';
import Errors from '~/modules/shared/error/errors';
import { getHistory } from '~/modules/store';
import { baseRouts } from '~/view/routes';
import Message from '~/view/shared/message';

const prefix = 'STOREFRONT_AVAILABILITY_FORM',
  actions = {
    RESET: `${prefix}_RESET`,

    FIND_STARTED: `${prefix}_FIND_STARTED`,
    FIND_SUCCESS: `${prefix}_FIND_SUCCESS`,
    FIND_ERROR: `${prefix}_FIND_ERROR`,

    CREATE_STARTED: `${prefix}_CREATE_STARTED`,
    CREATE_SUCCESS: `${prefix}_CREATE_SUCCESS`,
    CREATE_ERROR: `${prefix}_CREATE_ERROR`,

    UPDATE_STARTED: `${prefix}_UPDATE_STARTED`,
    UPDATE_SUCCESS: `${prefix}_UPDATE_SUCCESS`,
    UPDATE_ERROR: `${prefix}_UPDATE_ERROR`,

    doNew: () => ({
      type: actions.RESET,
    }),

    // doFind: (id) => async (dispatch) => {
    //   try {
    //     dispatch({
    //       type: actions.FIND_STARTED,
    //     });
    //
    //     const record = await StorefrontAvailabilityService.find(id);
    //
    //     dispatch({
    //       type: actions.FIND_SUCCESS,
    //       payload: record,
    //     });
    //   } catch (error) {
    //     Errors.handle(error);
    //
    //     dispatch({
    //       type: actions.FIND_ERROR,
    //     });
    //   }
    // },

    doCreate: (storefrontId, data) => async (dispatch) => {
      try {
        dispatch({
          type: actions.CREATE_STARTED,
        });

        await StorefrontAvailabilityService.create(storefrontId, data);

        dispatch({
          type: actions.CREATE_SUCCESS,
        });

        Message.success(i18n('entities.availability.create.success'));
      } catch (error) {
        const errorData = Errors.getErrorData(error);
        dispatch({
          type: actions.CREATE_ERROR,
          payload: errorData,
        });
      }
    },

    doUpdate: (storefrontId, flightId, data) => async (dispatch, getState) => {
      try {
        dispatch({
          type: actions.UPDATE_STARTED,
        });

        await StorefrontAvailabilityService.update(storefrontId, flightId, data);

        dispatch({
          type: actions.UPDATE_SUCCESS,
        });

        Message.success(i18n('entities.availability.update.success'));
      } catch (error) {
        const errorData = Errors.getErrorData(error);

        dispatch({
          type: actions.UPDATE_ERROR,
          payload: errorData,
        });
      }
    },
  };

export default actions;
