import { i18n } from 'i18n';
import CustomerProfileService from 'modules/customerProfile/customerProfileService';
import fields from 'modules/customerProfile/importer/customerProfileImporterFields';
import selectors from 'modules/customerProfile/importer/customerProfileImporterSelectors';
import importerActions from 'modules/shared/importer/importerActions';

export default importerActions(
  'CUSTOMERPROFILE_IMPORTER',
  selectors,
  CustomerProfileService.import,
  fields,
  i18n('entities.customerProfile.importer.fileName'),
);
