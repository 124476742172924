import DeleteIcon from '@mui/icons-material/Delete';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import Badge from '@mui/material/Badge';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import { Alert as AlertClassic } from '@mui/material';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import ListItemText from '@mui/material/ListItemText';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';
import CartService from '~/modules/cart/cartService';
import React, { useState } from 'react';
import { on, trigger } from '../../utils/events';

const Accordion = withStyles({
    root: {
      border: '1px solid rgba(0, 0, 0, .125)',
      boxShadow: 'none',
      '&:not(:last-child)': {
        borderBottom: 0,
      },
      '&:before': {
        display: 'none',
      },
      '&$expanded': {
        margin: 'auto',
      },
    },
    expanded: {},
  })(MuiAccordion),
  AccordionDetails = withStyles((theme) => ({
    root: {
      padding: theme.spacing(0),
      margin: theme.spacing(0),
    },
  }))(MuiAccordionDetails),
  AccordionSummary = withStyles({
    root: {
      backgroundColor: 'rgba(0, 0, 0, .03)',
      borderBottom: '1px solid rgba(0, 0, 0, .125)',
      marginBottom: -1,
      minHeight: 56,
      '&$expanded': {
        minHeight: 56,
      },
    },
    content: {
      '&$expanded': {
        margin: '0px 0',
      },
      margin: '0px 0 0',
      padding: '0px 0 0',
    },
    expanded: {},
  })(MuiAccordionSummary),
  StyledMenu = withStyles({
    paper: {
      border: '1px solid #d3d4d5',
    },
  })((props) => (
    <Menu
      elevation={0}
      anchorEl={null}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      {...props}
    />
  )),
  useStyles = makeStyles((theme) => ({
    button: {
      margin: theme.spacing(0),
    },
  })),
  Cart = ({
    data = { costs: { subtotal: 0, fee: 0, total: 0, taxes: 0, discount: 0 }, items: [] },
    ...rest
  }) => {
    const classes = useStyles(),
      [cartItems, setCartitems] = useState(data?.items),
      [cartItemCount, setCartitemCount] = useState(data?.items?.length || 0),
      [openCart, setOpenCart] = useState(false);

    on('updatedCart', async (doOpenCart = false) => {
      console.log('updatedCart:', doOpenCart);
      const newCart = await CartService.getCart();
      setCartitemCount(newCart?.items?.length);
      setCartitems(newCart?.items);
      if (doOpenCart === true) {
        setOpenCart(true);
      }
    });

    const toggleOpenCart = () => {
      setOpenCart(!openCart);
    };

    async function deleteCartItem(itemId) {
      const updatedCart = await CartService.deleteItems([itemId]);
      trigger('updatedCartPage');
      trigger('updatedCart', false);
    }

    console.log('cartItems:', cartItems);
    const dmasCount = new Set([...cartItems.map((b) => b?.dmaId)])?.size;
    const countyCount = new Set([...cartItems.map((b) => b?.countyId)])?.size;
    const zipCount = new Set([...cartItems.map((b) => b?.zip)])?.size;
    const impressionsWeekly = cartItems?.length
      ? [
          ...cartItems
            .filter((b) => b?.weeklyAdultImpressionsCount)
            .map((b) => b?.weeklyAdultImpressionsCount),
          0,
        ].reduce((prev, curr) => prev + curr)
      : 0;
    return (
      <div
        style={{
          fontFamily:
            'proxima-nova,-apple-system,BlinkMacSystemFont,Segoe UI,Helvetica,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji',
        }}
      >
        <MenuItem onClick={toggleOpenCart}>
          <IconButton aria-label="Notifications" size="large" style={{ color: 'black' }}>
            <Badge badgeContent={cartItemCount} color="secondary">
              <ShoppingCartIcon />
            </Badge>
          </IconButton>
        </MenuItem>
        <Drawer anchor="right" open={openCart} onClose={toggleOpenCart}>
          {cartItemCount !== 0 ? (
            <>
              <AlertClassic severity="success">{`${cartItemCount} Items reaching ${impressionsWeekly.toLocaleString()} across ${dmasCount} DMA(s), ${countyCount} counties, ${zipCount} zip(s)`}</AlertClassic>
              <Button
                variant="contained"
                color="primary"
                size="small"
                className={classes.button}
                onClick={() => {
                  window.location = '/cart';
                }}
              >
                Go to Cart
              </Button>
              {cartItems.map((inventory) => (
                <>
                  <Accordion
                    square
                    style={{
                      paddingBottom: '0px',
                      paddingTop: '0px',
                      marginBottom: '0px',
                      marginTop: '0px',
                      margin: 0,
                      padding: 0,
                    }}
                    key={`${inventory.id}`}
                  >
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <div style={{ alignItems: 'left', width: '70%', marginTop: '10px' }}>
                        <div style={{ fontSize: '19px' }}>
                          <Typography>
                            <a href={`/location/${inventory.id}`} target="_blank">
                              {inventory.name}
                            </a>{' '}
                            {inventory.weeklyAdultImpressionsCount !== undefined &&
                            inventory.weeklyAdultImpressionsCount !== null
                              ? `Reaching ${inventory.weeklyAdultImpressionsCount}`
                              : ``}
                          </Typography>
                        </div>
                        <div style={{ fontSize: '10px' }}>
                          <Typography style={{ fontSize: '14px' }}>
                            {`${inventory.description !== null ? inventory.description : ''}`} from{' '}
                            <a href={`/directory/company/${inventory.partnerSlug}`} target="_blank">
                              {inventory.partnerName}
                            </a>{' '}
                          </Typography>
                        </div>
                      </div>
                      <div
                        style={{
                          alignItems: 'right',
                          width: '30%',
                          textAlign: 'right',
                          marginTop: '20px',
                        }}
                      >
                        <span style={{ fontSize: '17px', marginRight: '-1px' }}>
                          <Typography>
                            <b>{inventory.cost === null ? `Cost TBD` : `$${inventory.cost}`}</b>
                          </Typography>
                        </span>
                      </div>
                    </AccordionSummary>
                    <AccordionDetails style={{ padding: 0, margin: 0 }}>
                      <Typography>
                        <List dense={true} style={{ marginBottom: '-8px' }}>
                          <ListItem
                            style={{ minWidth: '350px', maxWidth: '500px' }}
                            key={inventory.id}
                            divider
                            role="listitem"
                          >
                            <ListItemText
                              style={{ marginTop: '-14px' }}
                              primary={
                                <React.Fragment>
                                  {inventory['dateFrom'] !== null && inventory['dateTo'] !== null
                                    ? inventory['dateFrom'] + '-' + inventory['dateTo']
                                    : ``}
                                </React.Fragment>
                              }
                              secondary={
                                <React.Fragment>{`${
                                  inventory.physicalAddress !== null
                                    ? inventory.physicalAddress
                                    : ``
                                } ${inventory.city} ${inventory.state} ${
                                  inventory.zip
                                }`}</React.Fragment>
                              }
                            />
                            <ListItemSecondaryAction>
                              <div style={{ marginBottom: '0px' }}>
                                <span style={{ fontSize: '15px', marginRight: '6px' }}>
                                  <b></b>
                                </span>
                                <React.Fragment>
                                  <IconButton
                                    aria-label="delete"
                                    size="small"
                                    style={{ top: '-16px', right: '-3px' }}
                                    onClick={() => {
                                      deleteCartItem(inventory.id);
                                    }}
                                  >
                                    <DeleteIcon
                                      style={{ marginTop: '4px', marginBottom: '-6px' }}
                                    />
                                  </IconButton>
                                </React.Fragment>
                              </div>
                            </ListItemSecondaryAction>
                          </ListItem>
                        </List>
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                </>
              ))}
            </>
          ) : (
            <></>
          )}
          {cartItemCount === 0 ? (
            <ListItem style={{ minWidth: '350px' }} divider>
              <ListItemText
                primary={
                  <React.Fragment>
                    <Typography>
                      <center>Your cart is empty</center>
                    </Typography>
                  </React.Fragment>
                }
              />
            </ListItem>
          ) : (
            <>
              {false && (
                <>
                  <ListItem style={{ minWidth: '350px', textAlign: 'right' }} divider>
                    <ListItemText primary={`Platform Fee: 0`} secondary="" />
                  </ListItem>
                  <ListItem style={{ minWidth: '350px', textAlign: 'right' }} divider>
                    <ListItemText primary={`Total: 0`} secondary="" />
                  </ListItem>
                </>
              )}
            </>
          )}
        </Drawer>
      </div>
    );
  };

export default Cart;
