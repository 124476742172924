import model from 'modules/device/deviceModel';

const { fields } = model;

export default [
  fields.name,
  fields.channels,
  fields.assigned,
  fields.status,
  fields.lastSeen,
  fields.ip,
  fields.gps,
  fields.deviceProfile,
  fields.deviceNetwork,
];
