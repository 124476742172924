import model from 'modules/ooh/oohModel.ts';

const { fields } = model;

export default [
  fields.id,
  fields.name,
  fields.pros,
  fields.cons,
  fields.description,
  fields.tier,
  fields.serviceTypes,
  fields.inventoryTypes,
  fields.timeToLaunchDetails,
  fields.campaignTypes,
  fields.contactName,
  fields.businessPhone,
  fields.facebook,
  fields.twitter,
  fields.youtube,
  fields.instagram,
  fields.linkedin,
  fields.other,
  fields.email,
  fields.title,
  fields.phone,
  fields.state,
  fields.street,
  fields.city,
  fields.country,
  fields.zip,
  fields.audioAd,
  fields.motionGraphicAd,
  fields.staticImageAd,
  fields.tags,
  fields.cc,
  fields.timeToLaunch,
  fields.internalNotes,
  fields.updatedAt,
  fields.createdAt,
  fields.benefits,
  fields.creativeRequirements,
  fields.partnerServiceTypes,
  fields.partnerInventoryTypes,
  fields.storefront,
  fields.slug,
  fields.url,
];
