import { createSelector } from 'reselect';

const getFormSelector = (selectRaw) => {
  const selectRecord = createSelector([selectRaw], (raw) => Boolean(raw) && raw.record),
    selectFindLoading = createSelector(
      [selectRaw],
      (raw) => Boolean(raw) && Boolean(raw.findLoading),
    ),
    selectSaveLoading = createSelector(
      [selectRaw],
      (raw) => Boolean(raw) && Boolean(raw.saveLoading),
    ),
    selectError = createSelector([selectRaw], (raw) => raw && raw.error);

  return {
    selectFindLoading,
    selectSaveLoading,
    selectRecord,
    selectError,
    selectRaw,
  };
};

export default getFormSelector;
