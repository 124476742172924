import palette from '../palette';

export default {
  styleOverrides: {
    root: {},
    input: {
      display: 'flex',
      '&::placeholder': {
        opacity: 1,
        color: palette.text.secondary,
      },
    },
    outlinedInput: {
      display: 'flex',
    },
  },
};
