import { useEffect } from 'react';
import ProgressBar from 'view/shared/ProgressBar';
import Spinner from 'view/shared/Spinner';

export default function LoadingComponent() {
  useEffect(() => {
    ProgressBar.start();

    return () => {
      ProgressBar.done();
    };
  });

  return (
    <p style={{ padding: 20 }}>
      <Spinner />
    </p>
  );
}
