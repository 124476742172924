import { setLanguageCode } from 'i18n';

const prefix = 'LAYOUT',
  actions = {
    MENU_TOGGLE: `${prefix}_MENU_TOGGLE`,
    MENU_HIDE: `${prefix}_MENU_HIDE`,
    MENU_SHOW: `${prefix}_MENU_SHOW`,

    doChangeLanguage: (language) => {
      setLanguageCode(language);

      /**
       * I18n is outside Redux store,
       * no we need this hack to load it properly
       */
      window.location.reload();
    },

    doToggleMenu: () => ({
      type: actions.MENU_TOGGLE,
    }),

    doShowMenu: () => ({
      type: actions.MENU_SHOW,
    }),

    doHideMenu: () => ({
      type: actions.MENU_HIDE,
    }),
  };

export default actions;
