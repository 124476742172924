import { AuthToken } from 'modules/auth/authToken';
import authAxios from 'modules/shared/axios/authAxios';
import jwt_decode from 'jwt-decode';
import { hoursToMilliseconds, minutesToMilliseconds, secondsToMilliseconds } from 'date-fns';

export default class AuthService {
  static async sendEmailVerification() {
    const response = await authAxios.post('/auth/send-email-address-verification-email');

    return response.data;
  }

  static async sendPasswordResetEmail(email) {
    const response = await authAxios.post('/auth/send-password-reset-email', {
      email,
    });

    return response.data;
  }

  static async registerWithEmailAndPassword(email, password) {
    const response = await authAxios.post('/auth/sign-up', {
      email,
      password,
    });

    return response.data;
  }

  static async registerAgency(
    fullName,
    email,
    password,
    companyName,
    companyAddress,
    accountType,
    invited,
  ) {
    const response = await authAxios.post('/auth/sign-up', {
      email,
      password,
      fullName,
      companyName,
      companyAddress,
      accountType,
      invited,
    });

    return response.data;
  }

  static async registerPartner(user) {
    const response = await authAxios.post('/auth/sign-up', user);

    return response.data;
  }

  static async registerHost(
    fullName,
    email,
    password,
    companyName,
    companyAddress,
    accountType,
    locationName,
    locationAddress,
    tosAgreed,
    invited,
  ) {
    const response = await authAxios.post('/auth/sign-up', {
      email,
      password,
      fullName,
      companyName,
      companyAddress,
      accountType,
      locationName,
      locationAddress,
      tosAgreed,
      invited,
    });

    return response.data;
  }

  static async signinWithEmailAndPassword(email, password, rememberMe) {
    const response = await authAxios.post('/auth/sign-in', {
      email,
      password,
      rememberMe,
    });

    return response.data;
  }

  static async doSigninWithUserId(userId) {
    const response = await authAxios.post('/auth/multiple-sign-in', {
      userId,
    });

    return response.data;
  }

  static async fetchMe() {
    const response = await authAxios.get('/auth/me');
    return response.data;
  }

  static async isEmailConfigured() {
    const response = await authAxios.get('/auth/email-configured');
    return response.data;
  }

  static async signout() {
    try {
      AuthToken.set(null, true);
    } catch (e) {
      console.error(e);
    }
  }

  static async updateProfile(firstName, lastName, phoneNumber, avatars, email) {
    const body = {
      profile: {
        firstName,
        lastName,
        phoneNumber,
        avatars,
        email,
      },
    };
    const response = await authAxios.put('/auth/profile', body);

    return response.data;
  }

  static async passwordReset(token, password) {
    const response = await authAxios.put('/auth/password-reset', {
      token,
      password,
    });

    return response.data;
  }

  static async verifyEmail(token) {
    const response = await authAxios.put('/auth/verify-email', {
      token,
    });

    return response.data;
  }

  static async startRefreshTokenInterval() {
    console.log('Initializing refresh token interval');

    // in case this method is run multiple times it does not result
    // in multiple intervals being spun up doing the same job
    window.refreshTokenInvertalId && clearInterval(window.refreshTokenInvertalId);
    window.refreshTokenInvertalId = setInterval(async () => {
      const token = AuthToken.get();

      if (token) {
        const now = new Date().getTime();

        // safe to 'decode' on frontend but not 'verify'
        const decodedToken = jwt_decode(token);
        const tokenExpiration = decodedToken?.exp;
        const timeLeftBeforeExpiration = secondsToMilliseconds(tokenExpiration) - now;

        const slidingWindowSession = hoursToMilliseconds(
          parseInt(process.env.SLIDING_WINDOW_SESSION) || 4,
        );

        if (timeLeftBeforeExpiration <= slidingWindowSession && timeLeftBeforeExpiration > 0) {
          const response = await authAxios.post('/auth/refreshToken');
          const newToken = response.data;
          AuthToken.set(newToken, true);
          console.log('Successfully refreshed token');
        }
      }
    }, minutesToMilliseconds(5));
  }
}
