import { ThemeProvider } from '@mui/material/styles';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { ConnectedRouter } from 'connected-react-router';
import { SnackbarProvider, withSnackbar } from 'notistack';
import React, { Component } from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

import { LicenseInfo } from '@mui/x-license-pro';

import { configureStoreWithPersistor, getHistory } from '~/modules/store';
import { theme } from '~/theme';
import RoutesComponent from '~/view/shared/routes/RoutesComponent';
import Message from './view/shared/message';
import '~/theme/app.css';
import { QueryClient, QueryClientProvider } from 'react-query';

const sentryEnvironments = new Set(['staging', 'production']);

if (sentryEnvironments.has(process.env.REACT_APP_ENVIRONMENT)) {
  Sentry.init({
    dsn: 'https://130100314c3a4006a0c8119534199a33@o1102868.ingest.sentry.io/6129411',
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: process.env.REACT_APP_ENVIRONMENT === 'production' ? 0.1 : 1.0,
    environment: process.env.REACT_APP_ENVIRONMENT,
  });
}

LicenseInfo.setLicenseKey(process.env.REACT_APP_LICENSE_KEY_DATA_GRID_PRO);

const { store, persistor } = configureStoreWithPersistor();

class AppCore extends Component {
  componentDidMount() {
    /*
     * This is a little hack to not have to import notistack
     * on all the components that emit messages
     */
    // TODO fix eslint
    Message.registerNotistakEnqueueSnackbar(this.props.enqueueSnackbar, this.props.closeSnackbar); // eslint-disable-line
  }

  render() {
    if (window) {
      window.prerenderReady = false;
    }

    return (
      <React.Fragment>
        <Provider store={store}>
          <PersistGate persistor={persistor}>
            <ConnectedRouter history={getHistory()}>
              <RoutesComponent />
            </ConnectedRouter>
          </PersistGate>
        </Provider>
      </React.Fragment>
    );
  }
}

const queryClient = new QueryClient();

export default class App extends Component {
  render() {
    const AppWithSnackbar = withSnackbar(AppCore);
    return (
      <QueryClientProvider client={queryClient}>
        <ThemeProvider theme={theme}>
          <SnackbarProvider
            anchorOrigin={{
              horizontal: 'right',
              vertical: 'top',
            }}
          >
            <AppWithSnackbar {...this.props} />
          </SnackbarProvider>
        </ThemeProvider>
      </QueryClientProvider>
    );
  }
}
