import { createSelector } from 'reselect';

const selectRaw = (state) => state.device.list,
  selectLoading = createSelector([selectRaw], (raw) => raw.loading),
  selectExportLoading = createSelector([selectRaw], (raw) => raw.exportLoading),
  selectRows = createSelector([selectRaw], (raw) => raw.rows),
  selectCount = createSelector([selectRaw], (raw) => raw.count),
  selectHasRows = createSelector([selectCount], (count) => count > 0),
  selectSorter = createSelector([selectRaw], (raw) => raw.sorter || {}),
  selectOrderBy = createSelector([selectRaw], (raw) => {
    const { sorter } = raw;

    if (!sorter) {
      return null;
    }

    if (!sorter.columnKey) {
      return null;
    }

    const direction = sorter.order === 'desc' ? 'DESC' : 'ASC';

    return `${sorter.columnKey}_${direction}`;
  }),
  selectFilter = createSelector([selectRaw], (raw) => raw.filter),
  selectLimit = createSelector([selectRaw], (raw) => {
    const { pagination } = raw;
    return pagination.pageSize;
  }),
  selectOffset = createSelector([selectRaw], (raw) => {
    const { pagination } = raw;

    if (!pagination || !pagination.pageSize) {
      return 0;
    }

    const current = pagination.current || 1;

    return (current - 1) * pagination.pageSize;
  }),
  selectPagination = createSelector([selectRaw, selectCount], (raw, count) => ({
    ...raw.pagination,
    total: count,
  })),
  selectSelectedKeys = createSelector([selectRaw], (raw) => raw.selectedKeys),
  selectSelectedRows = createSelector([selectRaw, selectRows], (raw, rows) =>
    rows.filter((row) => raw.selectedKeys.includes(row.id)),
  ),
  selectIsAllSelected = createSelector(
    [selectRows, selectSelectedKeys],
    (rows, selectedKeys) => rows.length === selectedKeys.length,
  );

export default {
  selectLoading,
  selectRows,
  selectCount,
  selectOrderBy,
  selectLimit,
  selectFilter,
  selectOffset,
  selectPagination,
  selectSelectedKeys,
  selectSelectedRows,
  selectHasRows,
  selectExportLoading,
  selectIsAllSelected,
  selectSorter,
};
