import authAxios from 'modules/shared/axios/authAxios';

export default class CustomerProfileService {
  static async update(id, data) {
    const body = {
        id,
        data,
      },
      response = await authAxios.put(`/customer-profile/${id}`, body);

    return response.data;
  }

  static async destroyAll(ids) {
    const params = {
        ids,
      },
      response = await authAxios.delete('/customer-profile', {
        params,
      });

    return response.data;
  }

  static async create(data) {
    const body = {
        data,
      },
      response = await authAxios.post('/customer-profile', body);

    return response.data;
  }

  static async import(values, importHash) {
    const body = {
        data: values,
        importHash,
      },
      response = await authAxios.post('/customer-profile/import', body);

    return response.data;
  }

  static async find(id) {
    const response = await authAxios.get(`/customer-profile/${id}`);
    return response.data;
  }

  static async list(filter, orderBy, limit, offset) {
    const params = {
        filter,
        orderBy,
        limit,
        offset,
      },
      response = await authAxios.get('/customer-profile', {
        params,
      });

    return response.data;
  }

  static async listAutocomplete(query, limit) {
    const params = {
        query,
        limit,
      },
      response = await authAxios.get('/customer-profile/autocomplete', {
        params,
      });

    return response.data;
  }
}
