import { DEFAULT_PAGE_SIZE } from 'constants/data';

const getListReducer = (actions, pageSize = DEFAULT_PAGE_SIZE) => {
  const initialData = {
      rows: [],
      count: 0,
      loading: false,
      exportLoading: false,
      filter: {},
      pagination: {
        current: 1,
        pageSize,
      },
      sorter: {},
      selectedKeys: [],
      error: null,
    },
    reducer = (state = initialData, { type, payload }) => {
      if (type === actions.RESETED) {
        return {
          ...initialData,
        };
      }

      if (type === actions.PAGINATION_CHANGED) {
        return {
          ...state,
          pagination: payload || {
            current: 1,
            pageSize: DEFAULT_PAGE_SIZE,
          },
        };
      }

      if (type === actions.SORTER_CHANGED) {
        return {
          ...state,
          sorter: payload || {},
        };
      }

      if (type === actions.FETCH_STARTED) {
        const pagination =
          payload && payload.keepPagination
            ? state.pagination
            : payload.options.offset && payload.options.limit
            ? {
                current: payload.options.offset / payload.options.limit + 1,
                pageSize: payload.options.limit,
              }
            : {
                current: 1,
                pageSize: DEFAULT_PAGE_SIZE,
              };
        return {
          ...state,
          loading: true,
          selectedKeys: [],
          filter: payload ? payload.filter : {},
          pagination,
          error: null,
        };
      }

      if (type === actions.FETCH_SUCCESS) {
        return {
          ...state,
          loading: false,
          rows: payload.rows,
          count: payload.count,
        };
      }

      if (type === actions.EXPORT_ALL_PARTNER_STARTED) {
        return {
          ...state,
          exportLoading: true,
          loading: true,
        };
      }

      if (type === actions.EXPORT_ALL_PARTNER_SUCCESS) {
        return {
          ...state,
          exportLoading: false,
          loading: false,
        };
      }

      if (type === actions.EXPORT_ALL_PARTNER_ERROR) {
        return {
          ...state,
          exportLoading: false,
          error: payload,
        };
      }

      if (type === actions.FETCH_ERROR) {
        return {
          ...state,
          loading: false,
          rows: [],
          count: 0,
          error: payload,
        };
      }

      return state;
    };

  return reducer;
};

export default getListReducer;
