import { i18n } from 'i18n';
import DateField from 'modules/shared/fields/dateField.ts';
import DateRangeField from 'modules/shared/fields/dateRangeField.ts';
import DateTimeField from 'modules/shared/fields/dateTimeField.ts';
import DateTimeRangeField from 'modules/shared/fields/dateTimeRangeField.ts';
import EnumeratorField from 'modules/shared/fields/enumeratorField.ts';
import IdField from 'modules/shared/fields/idField.ts';
import RelationToManyField from 'modules/shared/fields/relationToManyField.ts';
import StringField from 'modules/shared/fields/stringField.ts';

function label(name) {
  return i18n(`entities.customerProfile.fields.${name}`);
}

function enumeratorLabel(name, value) {
  return i18n(`entities.customerProfile.enumerators.${name}.${value}`);
}

const fields = {
  id: new IdField('id', label('id')),
  name: new StringField('name', label('name'), {
    required: true,
    min: 2,
    max: 255,
  }),
  birthdate: new DateField('birthdate', label('birthdate'), {}),
  gender: new EnumeratorField(
    'gender',
    label('gender'),
    [
      { id: 'male', label: enumeratorLabel('gender', 'male') },
      { id: 'female', label: enumeratorLabel('gender', 'female') },
    ],
    {},
  ),
  tags: new RelationToManyField('tags', label('tags'), {}),
  audienceProfile: new RelationToManyField('audienceProfile', label('audienceProfile'), {}),
  //  AnalyticsEvents: new RelationToManyField('analyticsEvents', label('analyticsEvents'), {}),
  createdAt: new DateTimeField('createdAt', label('createdAt')),
  updatedAt: new DateTimeField('updatedAt', label('updatedAt')),
  createdAtRange: new DateTimeRangeField('createdAtRange', label('createdAtRange')),
  birthdateRange: new DateRangeField('birthdateRange', label('birthdateRange')),
};

export default {
  fields,
};
