import actions from 'modules/permission/form/permissionFormActions';

const initialData = {
  findLoading: false,
  saveLoading: false,
  record: null,
};

export default (state = initialData, { type, payload }) => {
  if (type === actions.RESET) {
    return {
      ...initialData,
    };
  }

  if (type === actions.FIND_GROUP_STARTED) {
    return {
      ...state,
      record: null,
      findLoading: true,
    };
  }

  if (type === actions.FIND_ROLE_STARTED) {
    return {
      ...state,
      record: null,
      findLoading: true,
    };
  }

  if (type === actions.FIND_ROLE_SUCCESS) {
    return {
      ...state,
      record: payload,
      findLoading: false,
    };
  }

  if (type === actions.FIND_GROUP_SUCCESS) {
    return {
      ...state,
      record: payload,
      findLoading: false,
    };
  }

  if (type === actions.FIND_ROLE_ERROR) {
    return {
      ...state,
      record: null,
      findLoading: false,
    };
  }

  if (type === actions.FIND_GROUP_ERROR) {
    return {
      ...state,
      record: null,
      findLoading: false,
    };
  }

  if (type === actions.CREATE_ROLE_STARTED) {
    return {
      ...state,
      saveLoading: true,
    };
  }

  if (type === actions.CREATE_GROUP_STARTED) {
    return {
      ...state,
      saveLoading: true,
    };
  }

  if (type === actions.CREATE_GROUP_SUCCESS) {
    return {
      ...state,
      saveLoading: false,
    };
  }

  if (type === actions.CREATE_ROLE_SUCCESS) {
    return {
      ...state,
      saveLoading: false,
    };
  }

  if (type === actions.CREATE_ROLE_SUCCESS) {
    return {
      ...state,
      saveLoading: false,
    };
  }

  if (type === actions.CREATE_GROUP_ERROR) {
    return {
      ...state,
      saveLoading: false,
    };
  }

  if (type === actions.UPDATE_ROLE_STARTED) {
    return {
      ...state,
      saveLoading: true,
    };
  }

  if (type === actions.UPDATE_GROUP_STARTED) {
    return {
      ...state,
      saveLoading: true,
    };
  }

  if (type === actions.UPDATE_GROUP_SUCCESS) {
    return {
      ...state,
      saveLoading: false,
    };
  }

  if (type === actions.UPDATE_ROLE_SUCCESS) {
    return {
      ...state,
      saveLoading: false,
    };
  }

  if (type === actions.UPDATE_GROUP_ERROR) {
    return {
      ...state,
      saveLoading: false,
    };
  }

  if (type === actions.UPDATE_ROLE_SUCCESS) {
    return {
      ...state,
      saveLoading: false,
    };
  }

  return state;
};
