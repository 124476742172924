import destroy from 'modules/organizationType/destroy/organizationTypeDestroyReducers';
import form from 'modules/organizationType/form/organizationTypeFormReducers';
import importerReducer from 'modules/organizationType/importer/organizationTypeImporterReducers';
import list from 'modules/organizationType/list/organizationTypeListReducers';
import view from 'modules/organizationType/view/organizationTypeViewReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
});
