import model from 'modules/customerAudience/customerAudienceModel';

const { fields } = model;

export default [
  fields.id,
  fields.slug,
  fields.name,
  fields.demographic,
  fields.income,
  fields.active,
  fields.tags,
  fields.sector,
  fields.createdAt,
  fields.updatedAt,
];
