import { i18n } from 'i18n';
import fields from 'modules/ooh/importer/oohImporterFields';
import selectors from 'modules/ooh/importer/oohImporterSelectors';
import importerActions from 'modules/shared/importer/importerActions';

import OOHService from '../oohService';

export default importerActions(
  'OOH_IMPORTER',
  selectors,
  OOHService.import,
  fields,
  i18n('entities.ooh.importer.fileName'),
);
