import { QuickbookReducerState } from '~/types/modules/quickbook/reducers';
import actions from './actions';

const initialData: QuickbookReducerState = {
  isConnected: false,
  loading: false,
  error: null,
};

const reducer = (state = initialData, { type, payload }): QuickbookReducerState => {
  switch (type) {
    case actions.QBO_ACCOUNT_CHECK_STARTED:
    case actions.QBO_TOKEN_CONFIRM_STARTED:
    case actions.QBO_DISCONNECT_STARTED:
    case actions.QBO_CONNECT_STARTED: {
      return {
        ...state,
        error: null,
        loading: true,
      };
    }

    case actions.QBO_ACCOUNT_CHECK_SUCCESS: {
      return {
        ...state,
        loading: false,
        isConnected: payload,
      };
    }
    case actions.QBO_TOKEN_CONFIRM_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }
    case actions.QBO_CONNECT_SUCCESS: {
      return {
        ...state,
        loading: false,
        isConnected: true,
      };
    }
    case actions.QBO_DISCONNECT_SUCCESS: {
      return {
        ...state,
        loading: false,
        isConnected: false,
      };
    }

    case actions.QBO_ACCOUNT_CHECK_ERROR:
    case actions.QBO_DISCONNECT_ERROR:
    case actions.QBO_TOKEN_CONFIRM_ERROR:
    case actions.QBO_CONNECT_ERROR: {
      return {
        ...state,
        error: payload,
        loading: false,
      };
    }

    default: {
      return { ...state };
    }
  }
};

export default reducer;
