import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import { Avatar, Button, Menu, MenuItem } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { i18n } from 'i18n';
import authActions from 'modules/auth/authActions';
import authSelectors from 'modules/auth/authSelectors';
import { getHistory } from 'modules/store';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Cart from '../cart/cart';
import Notification from '../notification/notification';

const styles = (theme) => ({
  button: {
    color: 'black',
  },
  text: {
    margin: theme.spacing(0, 0.5, 0, 1),
    display: 'none',
    color: 'black',
    [theme.breakpoints.up('md')]: {
      display: 'block',
    },
  },
  optionText: {
    margin: theme.spacing(0, 0.5, 0, 1),
  },
});

class UserMenu extends Component {
  state = {
    anchorEl: null,
  };

  handleClick = (event) => {
    this.setState({
      anchorEl: event.currentTarget,
    });
  };

  handleClose = () => {
    this.setState({
      anchorEl: null,
    });
  };

  doSignout = () => {
    const { dispatch } = this.props;
    dispatch(authActions.doSignout());
  };

  doNavigateToProfile = () => {
    getHistory().push('/profile');
  };

  doNavigateToSettings = () => {
    getHistory().push('/settings');
  };

  render() {
    const { classes, userText, userAvatar, currentUser } = this.props;
    if (!currentUser) return null;

    return (
      <React.Fragment>
        <Notification />
        <Button
          className={classes.button}
          onClick={this.handleClick}
          data-testid="top-nav-selfManage"
        >
          {userAvatar && <Avatar src={userAvatar} alt="avatar" />}
          {!userAvatar && <AccountCircleIcon />}
          <span className={classes.text}>{userText}</span>
        </Button>
        <Cart data={currentUser.cart} />
        <Menu
          anchorEl={this.state.anchorEl}
          keepMounted
          open={Boolean(this.state.anchorEl)}
          onClose={this.handleClose}
        >
          <MenuItem onClick={this.doNavigateToProfile} data-testid="top-nav-title">
            <PersonOutlineIcon />
            <span className={classes.optionText}>{i18n('auth.profile.title')}</span>
          </MenuItem>
          <MenuItem onClick={this.doSignout}>
            <ExitToAppIcon />
            <span className={classes.optionText} data-testid="top-nav-signout">
              {i18n('auth.signout')}
            </span>
          </MenuItem>
        </Menu>
      </React.Fragment>
    );
  }
}

function select(state) {
  return {
    userText: authSelectors.selectCurrentUserNameOrEmailPrefix(state),
    userAvatar: authSelectors.selectCurrentUserAvatar(state),
    currentUser: authSelectors.selectCurrentUser(state),
  };
}

export default connect(select)(withStyles(styles)(UserMenu));
