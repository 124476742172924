import Errors from '~/modules/shared/error/errors';
import selectors from '~/modules/storefrontAvailability/list/storefrontAvailabilityListSelectors';
import StorefrontService from '~/modules/storefront/storefrontService';
import StorefrontAvailabilityService from '~/modules/storefrontAvailability/storefrontAvailabilityService';
import { getListActions } from '~/modules/shared/actions';

const prefix = 'STOREFRONT_AVAILABILITY_LIST';
const defaultActions = getListActions({
  service: StorefrontAvailabilityService,
  selectors,
  prefix,
});
const actions = {
  GET_ATHENA_STARTED: `${prefix}_GET_ATHENA_STARTED`,
  GET_ATHENA_SUCCESS: `${prefix}_GET_ATHENA_SUCCESS`,
  GET_ATHENA_ERROR: `${prefix}_GET_ATHENA_ERROR`,
  GET_FUTURE_STARTED: `${prefix}_GET_FUTURE_STARTED`,
  GET_FUTURE_SUCCESS: `${prefix}_GET_FUTURE_SUCCESS`,
  GET_FUTURE_ERROR: `${prefix}_GET_FUTURE_ERROR`,
  ...defaultActions,
  getAnalyticsAthena:
    ({ storefrontId }) =>
    async (dispatch) => {
      try {
        dispatch({
          type: actions.GET_ATHENA_STARTED,
        });

        const response = await StorefrontService.getAnalyticsAthena({
          storefrontId,
        });

        dispatch({
          type: actions.GET_ATHENA_SUCCESS,
          payload: response,
        });
      } catch (error) {
        const errorData = Errors.getErrorData(error);
        dispatch({
          type: actions.GET_ATHENA_ERROR,
          payload: errorData,
        });
      }
    },
  doFetch:
    ({ storefrontId, filter = {}, keepPagination = false, options = {} }) =>
    async (dispatch, getState) => {
      const { orderBy, limit, offset } = options;

      try {
        dispatch({
          type: actions.FETCH_STARTED,
          payload: { filter, keepPagination, options },
        });

        const response = await StorefrontAvailabilityService.list({
          storefrontId,
          filter,
          orderBy: orderBy || selectors.selectOrderBy(getState()),
          limit: limit || selectors.selectLimit(getState()),
          offset: offset || selectors.selectOffset(getState()),
        });

        dispatch({
          type: actions.FETCH_SUCCESS,
          payload: response,
        });
      } catch (error) {
        const errorData = Errors.getErrorData(error);
        dispatch({
          type: actions.FETCH_ERROR,
          payload: errorData,
        });
      }
    },
  getFutureFlights:
    ({ storefrontId }) =>
    async (dispatch) => {
      try {
        dispatch({
          type: actions.GET_FUTURE_STARTED,
        });

        const response = await StorefrontAvailabilityService.getFutureList({
          storefrontId,
        });

        dispatch({
          type: actions.GET_FUTURE_SUCCESS,
          payload: response,
        });
      } catch (error) {
        const errorData = Errors.getErrorData(error);
        dispatch({
          type: actions.GET_FUTURE_ERROR,
          payload: errorData,
        });
      }
    },
};

export default actions;
