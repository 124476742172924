import destroy from 'modules/permission/destroy/permissionDestroyReducers';
import form from 'modules/permission/form/permissionFormReducers';
import list from 'modules/permission/list/permissionListReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  destroy,
});
