import { mapKeys } from 'lodash';
import { Excel } from 'modules/shared/excel/excel';
import ExporterSchema from 'modules/shared/exporter/exporterSchema';

export default class Exporter {
  constructor(fields, excelFileName) {
    this.schema = new ExporterSchema(fields);
    this.excelFileName = excelFileName;
  }

  transformAndExportAsExcelFile(rows) {
    const exportableData = rows.map((row) => {
      const rowCasted = this.schema.cast(row);
      return this._makeNameHeadersIntoLabels(rowCasted);
    });

    return Excel.exportAsExcelFile(
      exportableData,
      this.schema.labels,
      `${this.excelFileName}_${new Date().getTime()}`,
    );
  }

  transformAndExportAsCSV(rows) {
    const exportableData = rows.map((row) => {
      const rowCasted = this.schema.cast(row);
      return this._makeNameHeadersIntoLabels(rowCasted);
    });

    return Excel.exportAsCSV(
      exportableData,
      this.schema.labels,
      `${this.excelFileName}_${new Date().getTime()}`,
    );
  }

  _makeNameHeadersIntoLabels(row) {
    return mapKeys(row, (value, key) => this.schema.labelOf(key));
  }
}
