import React, { MouseEventHandler } from 'react';
import { Link, styled, SxProps, Theme } from '@mui/material';

export const OSAIButton = styled('button')<{ active?: boolean }>(
  ({ active, theme }) => `
  padding: 8px 14px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: ${active ? '#fff' : theme.palette.primary.main};
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  cursor: pointer;
  background-color: ${active ? theme.palette.primary.main : '#fff'};
  border: 1px solid ${theme.palette.primary.main};
  border-radius: 4px;
  min-width: 4.5em;
  &:hover {
    background-color: ${
      active ? theme.palette.button.hover.primary : theme.palette.button.hover.secondary
    };
  }
  &:disabled {
    color: ${theme.palette.text.disabled};
    background-color: ${theme.palette.button.disabled};
    cursor: default;
    border: none;
  }
`,
);

const linkStyle = {
  fontWeight: '600',
  fontSize: '16px',
  [`&:hover`]: {
    color: 'button.hover.primary',
  },
  [`&:disabled`]: {
    color: 'text.disabled',
    cursor: 'default',
  },
};
interface OSAIButtonTertiaryProps {
  sx?: SxProps<Theme>;
  className?: string;
  disabled?: boolean;
  onClick?: MouseEventHandler<HTMLAnchorElement | HTMLButtonElement> | undefined;
}

export const OSAIButtonTertiary: React.FC<OSAIButtonTertiaryProps> = ({
  className,
  sx,
  disabled,
  children,
  onClick,
}) => {
  return (
    <Link
      className={className}
      sx={Array.isArray(sx) ? sx.concat([linkStyle]) : { ...sx, ...linkStyle }}
      component="button"
      variant="body2"
      disabled={disabled}
      onClick={onClick}
    >
      {children}
    </Link>
  );
};
