import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import HistoryIcon from '@mui/icons-material/History';
import ListIcon from '@mui/icons-material/List';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import SettingsIcon from '@mui/icons-material/Settings';
import SpeedIcon from '@mui/icons-material/Speed';
import { i18n } from 'i18n';
import { ApiRoutes } from 'modules/apiRoutes';

import { ACCOUNT_TYPE } from '~/constants/data';
import Permissions from '../security/permissions';

const permissions = Permissions.values;

export const baseRouts = {
  admin: 'admin',
  advertiser: 'advertiser',
  ooh: 'directory',
  companies: 'companies',
  campaign: 'campaign',
  company: 'company',
  playlist: 'playlist',
  profile: 'profile',
  iam: 'iam',
  crm: 'crm',
  plan: 'plan',
  leadgen: 'leadgen',
  slidegen: 'slidegen',
  content: 'content',
  location: 'location',
  myInventory: 'my-inventory',
  locationGroup: 'location-group',
};

export const BASE_URL = `${window.location.protocol}//${window.location.host}`;

function getPageToDefaultLoad(currentUser) {
  if (currentUser && !currentUser.tosAgreed) {
    return import('view/home/HomePage');
  }
  if (
    Boolean(currentUser) &&
    (currentUser.accountType === ACCOUNT_TYPE.OWNER ||
      currentUser.accountType === ACCOUNT_TYPE.ADMIN)
  ) {
    return import('view/home/HomePage');
  } else if (currentUser.accountType === ACCOUNT_TYPE.PARTNER) {
    return import('view/ooh/list/OohListPage.tsx');
  }
  return import('view/ooh/list/OohListPage.tsx');
}

const privateRoutes = [
  {
    path: '/',
    icon: <SpeedIcon />,
    label: i18n('dashboard.menu'),
    menu: {
      exact: true,
    },
    loader: (currentUser) => getPageToDefaultLoad(currentUser),
    permissionRequired: null,
    exact: true,
    parent: true,
    dropdown: false,
  },
  //TODO ask correct permission for campaign
  {
    path: `/${baseRouts.campaign}/list`,
    loader: () => import('~/view/campaign/list/CampaignList'),
    menu: false,
    permissionRequired: permissions.campaignList,
    exact: true,
  },
  {
    path: `/${baseRouts.campaign}/agency`,
    loader: () => import('~/view/campaign/agency/CampaignList'),
    menu: false,
    permissionRequired: permissions.campaignDemand,
    exact: true,
  },
  {
    path: `/${baseRouts.campaign}/agency/:id/edit`,
    loader: () => import('~/view/campaign/agency/wizard/AgencyWizard'),
    menu: false,
    permissionRequired: permissions.campaignDemand,
    exact: true,
  },
  {
    path: `/${baseRouts.campaign}/agency/new`,
    loader: () => import('~/view/campaign/agency/wizard/AgencyWizard'),
    menu: false,
    permissionRequired: permissions.campaignDemand,
    exact: true,
  },
  {
    path: `/${baseRouts.campaign}/agency/:id/responses`,
    loader: () => import('~/view/campaign/RFPManager/RFPManager'),
    menu: true,
    permissionRequired: permissions.campaignDemand,
  },
  {
    path: `/${baseRouts.campaign}/agency/portal/:id`,
    loader: () => import('~/view/campaign/agency/rfpPortal/RFPPortal'),
    menu: false,
    exact: true,
  },
  {
    path: `/${baseRouts.campaign}/agency/portal/:id/inventory`,
    loader: () => import('view/campaign/agency/rfpPortal/Inventory'),
    menu: false,
    exact: true,
  },
  {
    path: `/${baseRouts.campaign}/agency/portal/holds-request/:id`,
    loader: () => import('~/view/campaign/agency/rfpPortal/HoldsRequest'),
    menu: false,
    exact: true,
  },
  {
    path: `/${baseRouts.campaign}/:id/edit`,
    loader: () => import('~/view/campaign/form/Page'),
    menu: false,
    permissionRequired: permissions.campaignEdit,
    exact: true,
  },
  {
    path: `/${baseRouts.campaign}/new`,
    loader: () => import('~/view/campaign/form/Page'),
    menu: false,
    permissionRequired: permissions.campaignEdit,
    exact: true,
  },

  {
    path: `/${baseRouts.playlist}`,
    icon: <ListIcon />,
    label: i18n('playlist.menu'),
    menu: {
      exact: true,
    },
    loader: () => import('view/playlist/list/PlaylistListPage'),
    permissionRequired: null,
    exact: true,
    parent: true,
    dropdown: false,
  },
  {
    path: `/${baseRouts.playlist}/new`,
    loader: () => import('view/playlist/form/PlaylistFormPage'),
    menu: false,
    permissionRequired: permissions.sectorCreate,
    exact: true,
  },
  {
    path: `/${baseRouts.playlist}/:id/edit`,
    loader: () => import('view/playlist/form/PlaylistFormPage'),
    menu: false,
    permissionRequired: permissions.sectorEdit,
    exact: true,
  },
  {
    path: `/${baseRouts.playlist}/:id`,
    loader: () => import('view/playlist/view/PlaylistViewPage'),
    menu: false,
    permissionRequired: permissions.sectorRead,
    exact: true,
  },

  {
    path: `/${baseRouts.ooh}/:id/edit`,
    loader: () => import('view/ooh/form/OohFormPage'),
    menu: false,
    permissionRequired: permissions.iamSecurityReviewer,
    exact: true,
    parent: false,
    dropdown: false,
  },
  {
    path: `/${baseRouts.ooh}`,
    icon: <ListIcon />,
    label: i18n('ooh.menu'),
    menu: {
      exact: true,
    },
    loader: () => import('view/ooh/list/OohListPage.tsx'),
    permissionRequired: null,
    exact: true,
    parent: true,
    dropdown: false,
  },
  {
    path: `/${baseRouts.ooh}/new`,
    loader: () => import('view/ooh/form/OohFormPage'),
    menu: false,
    permissionRequired: permissions.storefrontCreate,
    exact: true,
  },

  {
    path: `/${baseRouts.ooh}/company/:id`,
    loader: () => import('view/ooh/view/OohViewPage'),
    menu: false,
    permissionRequired: permissions.iamSecurityReviewer,
    exact: true,
    parent: false,
    dropdown: false,
  },

  {
    path: `/${baseRouts.ooh}/company/:id/edit`,
    loader: () => import('view/ooh/form/OohFormPage'),
    menu: false,
    permissionRequired: permissions.iamSecurityReviewer,
    exact: true,
  },

  /*
   * {
   *   Path: `/${baseRouts.ooh}/company/:id/inventory/preview`,
   *   Loader: () => import('view/location/importer/StorefrontFieldMapperPage'),
   *   Menu: false,
   *   PermissionRequired: null,
   *   Exact: true,
   * },
   */
  {
    path: `/${baseRouts.ooh}/company/:id/inventory/import`,
    loader: () => import('view/location/importer/StorefrontImporterPage.tsx'),
    menu: false,
    permissionRequired: null,
    exact: true,
  },

  {
    path: `/${baseRouts.ooh}/state/:state/`,
    icon: <ListIcon />,
    label: i18n('ooh.menu'),
    menu: {
      exact: true,
    },
    loader: () => import('view/ooh/list/OohListPage.tsx'),
    permissionRequired: null,
    exact: true,
    parent: true,
    dropdown: false,
  },

  {
    path: `/${baseRouts.ooh}/dma/:dma/`,
    icon: <ListIcon />,
    label: i18n('ooh.menu'),
    menu: {
      exact: true,
    },
    loader: () => import('view/ooh/list/OohListPage.tsx'),
    permissionRequired: null,
    exact: true,
    parent: true,
    dropdown: false,
  },

  {
    path: `/${baseRouts.ooh}/listing/:serviceType/`,
    icon: <ListIcon />,
    label: i18n('ooh.menu'),
    menu: {
      exact: true,
    },
    loader: () => import('view/ooh/list/OohListPage.tsx'),
    permissionRequired: null,
    exact: true,
    parent: true,
    dropdown: false,
  },

  {
    path: `/${baseRouts.ooh}/listing/inventory-type/:inventoryType`,
    icon: <ListIcon />,
    label: i18n('ooh.menu'),
    menu: {
      exact: true,
    },
    loader: () => import('view/ooh/list/OohListPage.tsx'),
    permissionRequired: null,
    exact: true,
    parent: true,
    dropdown: false,
  },

  // TODO migrating all /directory to /companies
  {
    path: `/${baseRouts.companies}/:id`,
    loader: () => import('../view/ooh/view/OohViewPage'),
    menu: false,
    permissionRequired: permissions.companiesRead,
    exact: true,
  },
  {
    path: `/${baseRouts.ooh}/company/:id/embed/:embedPage`,
    loader: () => import('../view/ooh/view/OohViewPage'),
    permissionRequired: null,
    exact: true,
    menu: false,
    parent: false,
    dropdown: false,
  },
  {
    path: `/${baseRouts.profile}`,
    loader: () => import('view/auth/ProfileFormPage'),
    permissionRequired: null,
    exact: true,
    menu: false,
    parent: false,
    dropdown: false,
  },

  {
    path: '/permission',
    loader: () => import('view/settings/permission/list/permissionPage'),
    permissionRequired: null,
    exact: true,
    menu: false,
    parent: false,
    dropdown: false,
  },

  /*
   * {
   *   Path: '/change-password',
   *   Loader: () => import('view/settings/ChangePasswordPage'),
   *   PermissionRequired: null, // todo add
   *   Exact: true,
   *   Menu: false,
   *   Parent: false,
   *   Dropdown: false,
   * },
   * {
   *   Exact: true,
   *   Path: '/scheduler',
   *   PermissionRequired: null,
   *   Loader: () => import('view/calendar'),
   * },
   */

  {
    path: '/payment',
    loader: () => import('view/auth/ProfileFormPage'),
    permissionRequired: null,
    exact: true,
    menu: false,
    parent: false,
    dropdown: false,
  },

  {
    path: `/${baseRouts.iam}`,
    loader: () => import('view/iam/list/IamPage'),
    permissionRequired: permissions.iamSecurityReviewer,
    exact: true,
    icon: <PersonAddIcon />,
    label: i18n('iam.menu'),
    menu: true,
    parent: true,
    dropdown: false,
  },
  {
    path: `/${baseRouts.iam}/new`,
    loader: () => import('view/iam/new/IamNewPage'),
    menu: false,
    permissionRequired: permissions.iamSecurityReviewer,
    exact: true,
    parent: false,
    dropdown: false,
  },
  {
    path: `/${baseRouts.iam}/:id/edit`,
    loader: () => import('view/iam/edit/IamEditPage'),
    menu: false,
    permissionRequired: permissions.iamSecurityReviewer,
    exact: true,
    parent: false,
    dropdown: false,
  },
  {
    path: `/${baseRouts.iam}/:id`,
    loader: () => import('view/iam/view/IamViewPage'),
    menu: false,
    permissionRequired: permissions.iamSecurityReviewer,
    exact: true,
    parent: false,
    dropdown: false,
  },

  {
    path: '/audit-logs',
    icon: <HistoryIcon />,
    label: i18n('auditLog.menu'),
    loader: () => import('view/auditLog/AuditLogPage'),
    menu: true,
    permissionRequired: permissions.auditLogRead,
    parent: true,
    dropdown: false,
  },

  {
    path: '/settings',
    icon: <SettingsIcon />,
    label: i18n('settings.menu'),
    loader: () => import('view/settings/SettingsFormPage'),
    permissionRequired: permissions.settingsEdit,
    menu: false,
  },

  /*
   * {
   *   Path: '/customer-profile',
   *   Loader: () => import('view/customerProfile/list/CustomerProfileListPage'),
   *   PermissionRequired: permissions.customerProfileRead,
   *   Exact: true,
   *   Icon: <ChevronRightIcon />,
   *   Label: i18n('entities.customerProfile.menu'),
   *   Menu: true,
   * },
   * {
   *   Path: '/customer-profile/new',
   *   Loader: () => import('view/customerProfile/form/CustomerProfileFormPage'),
   *   Menu: false,
   *   PermissionRequired: permissions.customerProfileCreate,
   *   Exact: true,
   * },
   * {
   *   Path: '/customer-profile/:id/edit',
   *   Loader: () => import('view/customerProfile/form/CustomerProfileFormPage'),
   *   Menu: false,
   *   PermissionRequired: permissions.customerProfileEdit,
   *   Exact: true,
   * },
   * {
   *   Path: '/customer-profile/:id',
   *   Loader: () => import('view/customerProfile/view/CustomerProfileViewPage'),
   *   Menu: false,
   *   PermissionRequired: permissions.customerProfileRead,
   *   Exact: true,
   * },
   */

  /*
   * {
   *   Path: '/customer-audience',
   *   Loader: () => import('view/customerAudience/list/CustomerAudienceListPage'),
   *   PermissionRequired: permissions.customerAudienceRead,
   *   Exact: true,
   *   Icon: <ChevronRightIcon />,
   *   Label: i18n('entities.customerAudience.menu'),
   *   Menu: true,
   * },
   * {
   *   Path: '/customer-audience/new',
   *   Loader: () => import('view/customerAudience/form/CustomerAudienceFormPage'),
   *   Menu: false,
   *   PermissionRequired: permissions.customerAudienceCreate,
   *   Exact: true,
   * },
   * {
   *   Path: '/customer-audience/:id/edit',
   *   Loader: () => import('view/customerAudience/form/CustomerAudienceFormPage'),
   *   Menu: false,
   *   PermissionRequired: permissions.customerAudienceEdit,
   *   Exact: true,
   * },
   * {
   *   Path: '/customer-audience/:id',
   *   Loader: () => import('view/customerAudience/view/CustomerAudienceViewPage'),
   *   Menu: false,
   *   PermissionRequired: permissions.customerAudienceRead,
   *   Exact: true,
   * },
   */
  {
    path: `/${baseRouts.content}`,
    loader: () => import('view/content/list/ContentListPage'),
    permissionRequired: permissions.contentRead,
    exact: true,
    icon: <ChevronRightIcon />,
    label: i18n('entities.content.menu'),
    menu: true,
  },
  {
    path: `/${baseRouts.content}/new`,
    loader: () => import('view/content/form/ContentFormPage'),
    menu: false,
    permissionRequired: permissions.contentCreate,
    exact: true,
  },
  {
    path: `/${baseRouts.content}/:id/edit`,
    loader: () => import('view/content/form/ContentFormPage'),
    menu: false,
    permissionRequired: permissions.contentEdit,
    exact: true,
  },
  {
    path: `/${baseRouts.content}/:id`,
    loader: () => import('view/content/view/ContentViewPage'),
    menu: false,
    permissionRequired: permissions.contentRead,
    exact: true,
  },

  /*
   * {
   *   Path: '/call-to-action',
   *   Loader: () => import('view/callToAction/list/CallToActionListPage'),
   *   PermissionRequired: permissions.callToActionRead,
   *   Exact: true,
   *   Icon: <ChevronRightIcon />,
   *   Label: i18n('entities.callToAction.menu'),
   *   Menu: true,
   * },
   * {
   *   Path: '/call-to-action/new',
   *   Loader: () => import('view/callToAction/form/CallToActionFormPage'),
   *   Menu: false,
   *   PermissionRequired: permissions.callToActionCreate,
   *   Exact: true,
   * },
   * {
   *   Path: '/call-to-action/:id/edit',
   *   Loader: () => import('view/callToAction/form/CallToActionFormPage'),
   *   Menu: false,
   *   PermissionRequired: permissions.callToActionEdit,
   *   Exact: true,
   * },
   * {
   *   Path: '/call-to-action/:id',
   *   Loader: () => import('view/callToAction/view/CallToActionViewPage'),
   *   Menu: false,
   *   PermissionRequired: permissions.callToActionRead,
   *   Exact: true,
   * },
   */

  /*
   * {
   *   Path: '/channel',
   *   Loader: () => import('view/channel/list/ChannelListPage'),
   *   PermissionRequired: permissions.channelRead,
   *   Exact: true,
   *   Icon: <ChevronRightIcon />,
   *   Label: i18n('entities.channel.menu'),
   *   Menu: true,
   * },
   * {
   *   Path: '/channel/new',
   *   Loader: () => import('view/channel/form/ChannelFormPage'),
   *   Menu: false,
   *   PermissionRequired: permissions.channelCreate,
   *   Exact: true,
   * },
   * {
   *   Path: '/channel/:id/edit',
   *   Loader: () => import('view/channel/form/ChannelFormPage'),
   *   Menu: false,
   *   PermissionRequired: permissions.channelEdit,
   *   Exact: true,
   * },
   * {
   *   Path: '/channel/:id',
   *   Loader: () => import('view/channel/view/ChannelViewPage'),
   *   Menu: false,
   *   PermissionRequired: permissions.channelRead,
   *   Exact: true,
   * },
   */

  {
    path: `/${baseRouts.location}`,
    loader: () => import('view/location/list/StorefrontListPage'),
    permissionRequired: permissions.storefrontRead,
    exact: true,
    icon: <ChevronRightIcon />,
    label: i18n('entities.storefront.menu'),
    menu: true,
  },
  {
    path: `/${baseRouts.myInventory}`,
    loader: () => import('view/myInventory/MyInventory'),
    permissionRequired: permissions.storefrontRead,
    exact: true,
    icon: <ChevronRightIcon />,
    label: i18n('entities.storefront.menu'),
    menu: true,
  },

  {
    path: `/${baseRouts.location}/new`,
    loader: () => import('view/location/form/StorefrontFormPage'),
    menu: false,
    permissionRequired: permissions.storefrontCreate,
    exact: true,
  },
  {
    path: `/${baseRouts.location}/import`,
    loader: () => import('view/location/importer/StorefrontImporterPage.tsx'),
    menu: false,
    permissionRequired: permissions.storefrontCreate,
    exact: true,
  },
  {
    path: `/${baseRouts.location}/priceRequest`,
    loader: () => import('./priceRequest/form/PriceRequestFormPage'),
    menu: false,
    permissionRequired: permissions.storefrontEdit,
    exact: true,
  },
  {
    path: `/${baseRouts.location}/:id/edit/:focusedComponent?`,
    loader: () => import('view/location/form/StorefrontFormPage'),
    menu: false,
    permissionRequired: permissions.storefrontEdit,
    exact: false,
  },
  {
    path: `/${baseRouts.location}/:id`,
    loader: () => import('view/location/view/StorefrontViewPage'),
    menu: false,
    permissionRequired: permissions.storefrontRead,
    exact: true,
  },
  {
    path: `/${baseRouts.locationGroup}`,
    loader: () => import('view/locationGroup/list/LocationGroupListPage'),
    permissionRequired: permissions.locationGroupRead,
    exact: true,
    icon: <ChevronRightIcon />,
    label: i18n('entities.locationGroup.menu'),
    menu: true,
  },
  {
    path: `/${baseRouts.locationGroup}/new`,
    loader: () => import('view/locationGroup/form/LocationGroupFormPage'),
    menu: false,
    permissionRequired: permissions.locationGroupCreate,
    exact: true,
  },
  {
    path: `/${baseRouts.locationGroup}/:id/edit`,
    loader: () => import('view/locationGroup/form/LocationGroupFormPage'),
    menu: false,
    permissionRequired: permissions.locationGroupEdit,
    exact: true,
  },
  {
    path: `/${baseRouts.locationGroup}/:id`,
    loader: () => import('view/locationGroup/view/LocationGroupViewPage'),
    menu: false,
    permissionRequired: permissions.locationGroupRead,
    exact: true,
  },

  {
    path: `/${baseRouts.advertiser}`,
    loader: () => import(`view/${baseRouts.advertiser}/list/OrganizationListPage`),
    permissionRequired: permissions.organizationRead,
    exact: true,
    icon: <ChevronRightIcon />,
    label: i18n('entities.organization.menu'),
    menu: true,
  },
  {
    path: `/${baseRouts.advertiser}/new`,
    loader: () => import(`view/${baseRouts.advertiser}/form/OrganizationFormPage`),
    menu: false,
    permissionRequired: permissions.organizationCreate,
    exact: true,
  },
  {
    path: `/${baseRouts.advertiser}/:id/edit`,
    loader: () => import(`view/${baseRouts.advertiser}/form/OrganizationFormPage`),
    menu: false,
    permissionRequired: permissions.organizationEdit,
    exact: true,
  },
  {
    path: `/${baseRouts.advertiser}/:id`,
    loader: () => import(`view/${baseRouts.advertiser}/view/OrganizationViewPage`),
    menu: false,
    permissionRequired: permissions.organizationRead,
    exact: true,
  },

  {
    path: '/budget-management',
    loader: () => import('view/budgetManagement/list/BudgetManagementListPage'),
    permissionRequired: permissions.organizationRead,
    exact: true,
    icon: <ChevronRightIcon />,
    label: i18n('entities.organization.menu'),
    menu: true,
  },
  {
    path: '/budget-management/new',
    loader: () => import('view/budgetManagement/form/BudgetManagementFormPage'),
    menu: false,
    permissionRequired: permissions.organizationCreate,
    exact: true,
  },
  {
    path: '/budget-management/load-budget',
    loader: () => import('view/budgetManagement/form/BudgetManagementFormPage'),
    menu: false,
    permissionRequired: permissions.organizationCreate,
    exact: true,
  },
  {
    path: '/budget-management/:id/edit',
    loader: () => import('view/budgetManagement/form/BudgetManagementFormPage'),
    menu: false,
    permissionRequired: permissions.organizationEdit,
    exact: true,
  },
  {
    path: '/budget-management/:id',
    loader: () => import('view/budgetManagement/view/BudgetManagementViewPage'),
    menu: false,
    permissionRequired: permissions.organizationRead,
    exact: true,
  },

  {
    path: '/ledger/:id',
    loader: () => import('view/budgetManagement/ledger/LedgerViewPage'),
    menu: false,
    permissionRequired: permissions.organizationRead,
    exact: true,
  },
  {
    path: '/ledger',
    loader: () => import('view/ledger/list/OrganizationListPage'),
    permissionRequired: permissions.organizationRead,
    exact: true,
    icon: <ChevronRightIcon />,
    label: i18n('entities.organization.menu'),
    menu: true,
  },
  {
    path: '/ledger/new',
    loader: () => import('view/ledger/form/OrganizationFormPage'),
    menu: false,
    permissionRequired: permissions.organizationCreate,
    exact: true,
  },
  {
    path: '/ledger/:id/edit',
    loader: () => import('view/ledger/form/OrganizationFormPage'),
    menu: false,
    permissionRequired: permissions.organizationEdit,
    exact: true,
  },
  {
    path: '/ledger/:id',
    loader: () => import('view/ledger/view/OrganizationViewPage'),
    menu: false,
    permissionRequired: permissions.organizationRead,
    exact: true,
  },

  {
    path: '/organization-type',
    loader: () => import('view/organizationType/list/OrganizationTypeListPage'),
    permissionRequired: permissions.organizationTypeRead,
    exact: true,
    icon: <ChevronRightIcon />,
    label: i18n('entities.organizationType.menu'),
    menu: true,
  },
  {
    path: '/organization-type/new',
    loader: () => import('view/organizationType/form/OrganizationTypeFormPage'),
    menu: false,
    permissionRequired: permissions.organizationTypeCreate,
    exact: true,
  },
  {
    path: '/organization-type/:id/edit',
    loader: () => import('view/organizationType/form/OrganizationTypeFormPage'),
    menu: false,
    permissionRequired: permissions.organizationTypeEdit,
    exact: true,
  },
  {
    path: '/organization-type/:id',
    loader: () => import('view/organizationType/view/OrganizationTypeViewPage'),
    menu: false,
    permissionRequired: permissions.organizationTypeRead,
    exact: true,
  },

  {
    path: '/sector',
    loader: () => import('view/sector/list/SectorListPage'),
    permissionRequired: permissions.sectorRead,
    exact: true,
    icon: <ChevronRightIcon />,
    label: i18n('entities.sector.menu'),
    menu: true,
  },
  {
    path: '/sector/new',
    loader: () => import('view/sector/form/SectorFormPage'),
    menu: false,
    permissionRequired: permissions.sectorCreate,
    exact: true,
  },
  {
    path: '/sector/:id/edit',
    loader: () => import('view/sector/form/SectorFormPage'),
    menu: false,
    permissionRequired: permissions.sectorEdit,
    exact: true,
  },
  {
    path: '/sector/:id',
    loader: () => import('view/sector/view/SectorViewPage'),
    menu: false,
    permissionRequired: permissions.sectorRead,
    exact: true,
  },
  {
    path: '/category',
    loader: () => import('view/category/list/CategoryListPage'),
    permissionRequired: permissions.categoryRead,
    exact: true,
    icon: <ChevronRightIcon />,
    label: i18n('entities.category.menu'),
    menu: true,
  },
  {
    path: '/category/new',
    loader: () => import('view/category/form/CategoryFormPage'),
    menu: false,
    permissionRequired: permissions.categoryCreate,
    exact: true,
  },
  {
    path: '/category/:id/edit',
    loader: () => import('view/category/form/CategoryFormPage'),
    menu: false,
    permissionRequired: permissions.categoryEdit,
    exact: true,
  },
  {
    path: '/category/:id',
    loader: () => import('view/category/view/CategoryViewPage'),
    menu: false,
    permissionRequired: permissions.categoryRead,
    exact: true,
  },
  {
    path: '/tag',
    loader: () => import('view/tag/list/TagListPage'),
    permissionRequired: permissions.tagRead,
    exact: true,
    icon: <ChevronRightIcon />,
    label: i18n('entities.tag.menu'),
    menu: true,
  },
  {
    path: '/tag/new',
    loader: () => import('view/tag/form/TagFormPage'),
    menu: false,
    permissionRequired: permissions.tagCreate,
    exact: true,
  },
  {
    path: '/tag/:id/edit',
    loader: () => import('view/tag/form/TagFormPage'),
    menu: false,
    permissionRequired: permissions.tagEdit,
    exact: true,
  },
  {
    path: '/tag/:id',
    loader: () => import('view/tag/view/TagViewPage'),
    menu: false,
    permissionRequired: permissions.tagRead,
    exact: true,
  },

  {
    path: '/market',
    loader: () => import('view/market/list/MarketListPage'),
    permissionRequired: permissions.marketRead,
    exact: true,
    icon: <ChevronRightIcon />,
    label: i18n('entities.market.menu'),
    menu: true,
  },
  {
    path: '/market/new',
    loader: () => import('view/market/form/MarketFormPage'),
    menu: false,
    permissionRequired: permissions.marketCreate,
    exact: true,
  },
  {
    path: '/market/:id/edit',
    loader: () => import('view/market/form/MarketFormPage'),
    menu: false,
    permissionRequired: permissions.marketEdit,
    exact: true,
  },
  {
    path: '/market/:id',
    loader: () => import('view/market/view/MarketViewPage'),
    menu: false,
    permissionRequired: permissions.marketRead,
    exact: true,
  },

  {
    path: '/transaction',
    loader: () => import('view/transaction/list/TransactionListPage'),
    permissionRequired: permissions.transactionRead,
    exact: true,
    icon: <ChevronRightIcon />,
    label: i18n('entities.transaction.menu'),
    menu: true,
  },
  {
    path: '/transaction/new',
    loader: () => import('view/transaction/form/TransactionFormPage'),
    menu: false,
    permissionRequired: permissions.transactionCreate,
    exact: true,
  },
  {
    path: '/transaction/:id/edit',
    loader: () => import('view/transaction/form/TransactionFormPage'),
    menu: false,
    permissionRequired: permissions.transactionEdit,
    exact: true,
  },
  {
    path: '/transaction/:id',
    loader: () => import('view/transaction/view/TransactionViewPage'),
    menu: false,
    permissionRequired: permissions.transactionRead,
    exact: true,
  },
  {
    path: '/analytics-event',
    loader: () => import('view/analyticsEvent/list/AnalyticsEventListPage'),
    permissionRequired: permissions.analyticsEventRead,
    exact: true,
    icon: <ChevronRightIcon />,
    label: i18n('entities.analyticsEvent.menu'),
    menu: true,
  },
  {
    path: '/analytics-event/new',
    loader: () => import('view/analyticsEvent/form/AnalyticsEventFormPage'),
    menu: false,
    permissionRequired: permissions.analyticsEventCreate,
    exact: true,
  },
  {
    path: '/analytics-event/:id/edit',
    loader: () => import('view/analyticsEvent/form/AnalyticsEventFormPage'),
    menu: false,
    permissionRequired: permissions.analyticsEventEdit,
    exact: true,
  },
  {
    path: '/analytics-event/:id',
    loader: () => import('view/analyticsEvent/view/AnalyticsEventViewPage'),
    menu: false,
    permissionRequired: permissions.analyticsEventRead,
    exact: true,
  },

  {
    path: '/status',
    loader: () => import('view/status/list/StatusListPage'),
    permissionRequired: permissions.statusRead,
    exact: true,
    icon: <ChevronRightIcon />,
    label: i18n('entities.status.menu'),
    menu: true,
  },
  {
    path: '/status/new',
    loader: () => import('view/status/form/StatusFormPage'),
    menu: false,
    permissionRequired: permissions.statusCreate,
    exact: true,
  },
  {
    path: '/status/:id/edit',
    loader: () => import('view/status/form/StatusFormPage'),
    menu: false,
    permissionRequired: permissions.statusEdit,
    exact: true,
  },
  {
    path: '/status/:id',
    loader: () => import('view/status/view/StatusViewPage'),
    menu: false,
    permissionRequired: permissions.statusRead,
    exact: true,
  },

  /*
   *{
   *  path: '/checkout',
   *  loader: () => import('view/checkout/CheckoutViewPage'),
   *  menu: false,
   *  exact: true,
   *},
   */
  {
    path: `/cart/campaign`,
    loader: () => import('view/cart/CampaignCart'),
    menu: false,
    exact: true,
  },
  {
    path: `/cart/campaign/:id/edit`,
    loader: () => import('view/cart/CampaignCart'),
    menu: false,
    permissionRequired: permissions.campaignDemand,
    exact: true,
  },
  {
    path: '/cart',
    loader: () => import('view/cart/cartPage'),
    menu: false,
    exact: true,
  },
  {
    path: '/invoice/:id/:mode',
    loader: () => import('view/invoice/InvoicePage'),
    menu: false,
    exact: true,
  },
  {
    path: '/invoices',
    loader: () => import('view/invoices/list'),
    menu: true,
    icon: <ChevronRightIcon />,
    exact: true,
  },

  {
    path: '/crm',
    loader: () => import('view/invoices/list'),
    menu: true,
    icon: <ChevronRightIcon />,
    exact: true,
  },
  {
    path: '/crm/leads',
    loader: () => import('~/view/crm/lead/list'),
    menu: true,
    icon: <ChevronRightIcon />,
    exact: true,
  },
  {
    path: '/crm/contacts/list',
    permissionRequired: permissions.crmView,
    loader: () => import('~/view/crm/contact/ContactList'),
    menu: true,
    icon: <ChevronRightIcon />,
    exact: true,
  },
  {
    path: '/crm/contacts/add',
    permissionRequired: permissions.crmView,
    loader: () => import('~/view/crm/contact/ContactAddEdit'),
    menu: true,
    icon: <ChevronRightIcon />,
    exact: true,
  },
  {
    path: '/crm/contacts/edit/:id',
    permissionRequired: permissions.crmView,
    loader: () => import('~/view/crm/contact/ContactAddEdit'),
    menu: true,
    icon: <ChevronRightIcon />,
    exact: true,
  },
  {
    path: '/crm/company/list',
    permissionRequired: permissions.crmView,
    loader: () => import('~/view/crm/company/CompanyList'),
    menu: true,
    icon: <ChevronRightIcon />,
    exact: true,
  },
  {
    path: '/crm/company/add',
    permissionRequired: permissions.crmView,
    loader: () => import('~/view/crm/company/CompanyAddEdit'),
    menu: true,
    icon: <ChevronRightIcon />,
    exact: true,
  },
  {
    path: '/crm/company/edit/:id',
    permissionRequired: permissions.crmView,
    loader: () => import('~/view/crm/company/CompanyAddEdit'),
    menu: true,
    icon: <ChevronRightIcon />,
    exact: true,
  },
  {
    path: '/crm/vendor/list',
    permissionRequired: permissions.vendorContactViewer,
    loader: () => import('~/view/crm/preferredVendorContacts/components/VendorList'),
    menu: true,
    icon: <ChevronRightIcon />,
    exact: true,
  },
  {
    path: '/crm/vendor/add',
    permissionRequired: permissions.vendorContactViewer,
    loader: () => import('~/view/crm/preferredVendorContacts/components/VendorAddEdit'),
    menu: true,
    icon: <ChevronRightIcon />,
    exact: true,
  },
  {
    path: '/crm/vendor/edit/:id',
    permissionRequired: permissions.vendorContactViewer,
    loader: () => import('~/view/crm/preferredVendorContacts/components/VendorAddEdit'),
    menu: true,
    icon: <ChevronRightIcon />,
    exact: true,
  },
  {
    path: '/recipes',
    loader: () => import('view/recipes/list'),
    menu: true,
    icon: <ChevronRightIcon />,
    permissionRequired: permissions.toolsRecipes,
    exact: true,
  },
  {
    path: '/recipes/:slug',
    loader: () => import('view/recipes/details'),
    menu: true,
    icon: <ChevronRightIcon />,
    permissionRequired: permissions.toolsRecipes,
    exact: true,
  },
  {
    path: '/recipes/:slug/:clientId',
    loader: () => import('view/recipes/details'),
    menu: true,
    icon: <ChevronRightIcon />,
    permissionRequired: permissions.toolsRecipes,
    exact: true,
  },
  {
    path: '/threads',
    loader: () => import('view/threads/list'),
    menu: true,
    icon: <ChevronRightIcon />,
    permissionRequired: permissions.toolsThreads,
    exact: true,
  },
  {
    path: '/threads/tracker/new',
    loader: () => import('view/threads/tracker'),
    menu: true,
    icon: <ChevronRightIcon />,
    permissionRequired: permissions.toolsThreads,
    exact: true,
  },
  {
    path: '/placerank',
    loader: () => import('view/placerank/list'),
    menu: true,
    icon: <ChevronRightIcon />,
    permissionRequired: permissions.toolsPlaceRank,
    exact: true,
  },
  {
    path: '/plan/persona',
    loader: () => import('view/plan/persona/list'),
    menu: true,
    icon: <ChevronRightIcon />,
    permissionRequired: permissions.toolsPersonaBuilder,
    exact: true,
  },
  {
    path: '/plan/persona/:docId',
    loader: () => import('view/plan/persona/list'),
    menu: true,
    icon: <ChevronRightIcon />,
    permissionRequired: permissions.toolsPersonaBuilder,
    exact: true,
  },
  {
    path: '/plan/persona/:docId/html',
    loader: () => import('view/plan/persona/list'),
    menu: true,
    icon: <ChevronRightIcon />,
    exact: true,
  },
  {
    path: '/plan/demographics',
    loader: () => import('view/plan/demographics/list'),
    menu: true,
    icon: <ChevronRightIcon />,
    permissionRequired: permissions.toolsPersonaBuilder,
    exact: true,
  },
  {
    path: '/plan/inventory',
    loader: () => import('view/plan/inventory/list'),
    menu: true,
    icon: <ChevronRightIcon />,
    permissionRequired: permissions.toolsPersonaBuilder,
    exact: true,
  },
  {
    path: '/plan/proposal',
    loader: () => import('view/plan/proposal/list'),
    menu: true,
    icon: <ChevronRightIcon />,
    permissionRequired: permissions.toolsPersonaBuilder,
    exact: true,
  },
  {
    path: '/plan/explore',
    loader: () => import('view/plan/explore/list'),
    menu: true,
    icon: <ChevronRightIcon />,
    permissionRequired: permissions.toolsPersonaBuilder,
    exact: true,
  },
  {
    path: '/sales/leadgen',
    loader: () => import('view/sales/leadgen/list'),
    menu: true,
    icon: <ChevronRightIcon />,
    permissionRequired: permissions.leadGenerator,
    exact: true,
  },
  {
    path: '/tools/overview',
    loader: () => import('view/tools/overview'),
    menu: true,
    icon: <ChevronRightIcon />,
    permissionRequired: permissions.toolsOverviewPage,
    exact: true,
  },
  {
    path: '/sales/slidegen',
    loader: () => import('view/sales/plans/PlanList'),
    menu: true,
    icon: <ChevronRightIcon />,
    permissionRequired: permissions.toolsSlideBuilder,
    exact: true,
  },
  {
    path: '/sales/slidegen/create',
    loader: () => import('view/sales/slidegen/list'),
    menu: true,
    icon: <ChevronRightIcon />,
    permissionRequired: permissions.toolsSlideBuilder,
    exact: true,
  },
  {
    path: '/sales/slidegen/:documentId',
    loader: () => import('view/sales/slidegen/list'),
    menu: true,
    icon: <ChevronRightIcon />,
    permissionRequired: permissions.toolsSlideBuilder,
    exact: true,
  },
  {
    path: '/sales/slidegen/:documentId/:versionId',
    loader: () => import('view/sales/slidegen/list'),
    menu: true,
    icon: <ChevronRightIcon />,
    permissionRequired: permissions.toolsSlideBuilder,
    exact: true,
  },
  {
    path: '/inventory-v2',
    loader: () => import('view/ooh/inventory'),
    menu: true,
    icon: <ChevronRightIcon />,
    exact: true,
  },
  /*
   * {
   *   Path: '/device',
   *   Loader: () => import('view/device/list/DeviceListPage'),
   *   PermissionRequired: permissions.deviceRead,
   *   Exact: true,
   *   Icon: <ChevronRightIcon />,
   *   Label: i18n('entities.device.menu'),
   *   Menu: true,
   * },
   * {
   *   Path: '/device/new',
   *   Loader: () => import('view/device/form/DeviceFormPage'),
   *   Menu: false,
   *   PermissionRequired: permissions.deviceCreate,
   *   Exact: true,
   * },
   * {
   *   Path: '/device/:id/edit',
   *   Loader: () => import('view/device/form/DeviceFormPage'),
   *   Menu: false,
   *   PermissionRequired: permissions.deviceEdit,
   *   Exact: true,
   * },
   * {
   *   Path: '/device/:id',
   *   Loader: () => import('view/device/view/DeviceViewPage'),
   *   Menu: false,
   *   PermissionRequired: permissions.deviceRead,
   *   Exact: true,
   * },
   */

  /*
   * {
   *   Path: '/device-profile',
   *   Loader: () => import('view/deviceProfile/list/DeviceProfileListPage'),
   *   PermissionRequired: permissions.deviceProfileRead,
   *   Exact: true,
   *   Icon: <ChevronRightIcon />,
   *   Label: i18n('entities.deviceProfile.menu'),
   *   Menu: true,
   * },
   * {
   *   Path: '/device-profile/new',
   *   Loader: () => import('view/deviceProfile/form/DeviceProfileFormPage'),
   *   Menu: false,
   *   PermissionRequired: permissions.deviceProfileCreate,
   *   Exact: true,
   * },
   * {
   *   Path: '/device-profile/:id/edit',
   *   Loader: () => import('view/deviceProfile/form/DeviceProfileFormPage'),
   *   Menu: false,
   *   PermissionRequired: permissions.deviceProfileEdit,
   *   Exact: true,
   * },
   * {
   *   Path: '/device-profile/:id',
   *   Loader: () => import('view/deviceProfile/view/DeviceProfileViewPage'),
   *   Menu: false,
   *   PermissionRequired: permissions.deviceProfileRead,
   *   Exact: true,
   * },
   */

  /*
   * {
   *   Path: '/device-capability',
   *   Loader: () => import('view/deviceCapability/list/DeviceCapabilityListPage'),
   *   PermissionRequired: permissions.deviceCapabilityRead,
   *   Exact: true,
   *   Icon: <ChevronRightIcon />,
   *   Label: i18n('entities.deviceCapability.menu'),
   *   Menu: true,
   * },
   * {
   *   Path: '/device-capability/new',
   *   Loader: () => import('view/deviceCapability/form/DeviceCapabilityFormPage'),
   *   Menu: false,
   *   PermissionRequired: permissions.deviceCapabilityCreate,
   *   Exact: true,
   * },
   * {
   *   Path: '/device-capability/:id/edit',
   *   Loader: () => import('view/deviceCapability/form/DeviceCapabilityFormPage'),
   *   Menu: false,
   *   PermissionRequired: permissions.deviceCapabilityEdit,
   *   Exact: true,
   * },
   * {
   *   Path: '/device-capability/:id',
   *   Loader: () => import('view/deviceCapability/view/DeviceCapabilityViewPage'),
   *   Menu: false,
   *   PermissionRequired: permissions.deviceCapabilityRead,
   *   Exact: true,
   * },
   *
   * {
   *   Path: '/device-network',
   *   Loader: () => import('view/deviceNetwork/list/DeviceNetworkListPage'),
   *   PermissionRequired: permissions.deviceNetworkRead,
   *   Exact: true,
   *   Icon: <ChevronRightIcon />,
   *   Label: i18n('entities.deviceNetwork.menu'),
   *   Menu: true,
   * },
   * {
   *   Path: '/device-network/new',
   *   Loader: () => import('view/deviceNetwork/form/DeviceNetworkFormPage'),
   *   Menu: false,
   *   PermissionRequired: permissions.deviceNetworkCreate,
   *   Exact: true,
   * },
   * {
   *   Path: '/device-network/:id/edit',
   *   Loader: () => import('view/deviceNetwork/form/DeviceNetworkFormPage'),
   *   Menu: false,
   *   PermissionRequired: permissions.deviceNetworkEdit,
   *   Exact: true,
   * },
   * {
   *   Path: '/device-network/:id',
   *   Loader: () => import('view/deviceNetwork/view/DeviceNetworkViewPage'),
   *   Menu: false,
   *   PermissionRequired: permissions.deviceNetworkRead,
   *   Exact: true,
   * },
   */
  {
    path: '/legal/tos',
    loader: () => import('view/legal/TermsOfService'),
  },
];
const publicRoutes = [
  {
    path: '/legal/tos',
    loader: () => import('view/legal/TermsOfService'),
  },
  {
    path: '/auth/signin',
    loader: () => import('view/auth/SigninPage'),
  },
  {
    path: `${ApiRoutes.SIGN_UP}/:id`,
    loader: () => import('view/auth/AuthSteps/AuthStepContent'),
  },
  {
    path: '/auth/forgot-password',
    loader: () => import('view/auth/ForgotPasswordPage'),
  },
];
const emptyPermissionsRoutes = [
  {
    path: '/auth/empty-permissions',
    loader: () => import('view/auth/EmptyPermissionsPage'),
  },
];
const emailUnverifiedRoutes = [
  {
    path: '/auth/email-unverified',
    loader: () => import('view/auth/EmailUnverifiedPage'),
  },
];
const simpleRoutes = [
  {
    path: '/auth/password-reset',
    loader: () => import('view/auth/PasswordResetPage'),
  },
  {
    path: '/auth/verify-email',
    loader: () => import('view/auth/VerifyEmailPage'),
  },
  {
    path: '/auth/quickbook/success',
    loader: () => import('view/auth/QuickbookSuccessPage'),
  },
  {
    path: '/403',
    loader: () => import('view/shared/errors/Error403Page'),
  },
  {
    path: '/terms',
    loader: () => import('view/home/Terms'),
  },
  {
    path: '/500',
    loader: () => import('view/shared/errors/Error500Page'),
  },
  {
    path: '**',
    loader: () => import('view/shared/errors/Error404Page'),
  },
];

export default {
  privateRoutes,
  publicRoutes,
  emptyPermissionsRoutes,
  emailUnverifiedRoutes,
  simpleRoutes,
};
